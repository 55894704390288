.top-heading {
  background-color: #141414;
  color: #ffffff;
  display: flex;
  flex-direction: row;
  padding-top: 3.5%;
  padding-left: 9%;
  gap: 5%;
}
.beat-section-image {
  /* margin-bottom: 6%; */
  position: relative;
}
.beat-section-description {
  display: flex;
  flex-direction: column;
  width: 30%;
}
.beat-section-artwork {
  width: 30rem;
  height: 30rem;
  border-radius: 7px;
  object-fit: cover;
}
.beat-section-name {
  color: #f5f5f5;
  font-size: 4rem;
  font-weight: 500;
}
.beat-section-artist {
  color: #f5f5f5;
  font-size: 3.5rem;
  font-weight: normal;
  cursor: pointer;
}
.beat-section-album {
  color: #cbcbcb;
  font-size: 2.2rem;
  font-weight: normal;
}
.beat-section-album span {
  color: #ff4d2f;
  text-decoration: underline;
  cursor: pointer;
  font-size: 2.2rem;
  font-weight: normal;
}
.beat-section-date {
  color: #cbcbcb;
  font-size: 2.2rem;
  font-weight: normal;
}
.beat-section-extras {
  margin-top: 5%;
  display: flex;
  flex-direction: row;
  gap: 5%;
  margin-bottom: 6%;
}
.extras-like,
.extras-share,
.extras-comment,
.extras-playlist {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
}
.extras-like div,
.extras-share div,
.extras-comment div,
.extras-playlist div {
  display: flex;
  flex-direction: row;
}
.like-quantity,
.share-quantity,
.comment-quantity {
  font-size: 2.5rem;
}
.extras-like img,
.extras-share img,
.extras-comment img,
.extras-playlist img {
  width: 80%;
}
.like-button,
.share-button,
.comment-button,
.playlist-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: none;
  cursor: pointer;
}
.playlist-button {
  height: 4rem;
}
.extra-like-name,
.extra-share-name,
.extra-comment-name,
.extra-playlist-name {
  color: #cbcbcb;
  font-size: 1.6rem;
  font-weight: normal;
}
.beat-section-buy-button {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background-color: #fe2b0d;
  border-radius: 3px;
  padding: 2% 4%;
  color: #ffffff;
  font-size: 2.4rem;
  gap: 10%;
  margin-bottom: 5%;
  cursor: pointer;
}
.beat-section-buy-button img {
  width: 2.5rem;
}
.beat-section-options {
  display: flex;
  gap: 5%;
  color: #ffffff;
}
.beat-section-options button {
  background-color: transparent;
  border: none;
  color: #ffffff;
  font-size: 2.7rem;
  padding-bottom: 1.5%;
  cursor: pointer;
}
.beat-section-description-div {
  padding-top: 5%;
  padding-left: 10%;
  padding-right: 10%;
  padding-bottom: 15%;
  color: #333333;
  font-size: 2rem;
  text-align: left;
}
.album-section-description-div {
  width: 50%;
  padding-left: 5%;
  padding-right: 2%;
  color: #333333;
  font-size: 2rem;
  text-align: left;
}
.album__description {
  padding-bottom: 15%;
  padding-top: 5%;
  display: flex;
}
.album-section-description-div h3 {
  font-size: 2.2rem;
  font-weight: 700;
  margin-bottom: 2%;
}
.album-section-description-p {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  word-wrap: break-word; /* Break long words */
  word-break: break-all; /* Break all words */
  white-space: normal;
}
.all__songs__main {
  border: 1px solid #d4d4d4;
  width: 90%;
  padding: 2%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
}

.album-section-all-music {
  width: 50%;
  display: flex;
  justify-content: center;
}
.all__songs__main--heading {
  width: 100%;
  margin-bottom: 2%;
  font-size: 2rem;
  font-weight: 500;
  color: #333333;
}
.all__songs__list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.all__songs__list__item {
  display: flex;
  background-color: #f5f5f5;
  width: 100%;
  border-radius: 7px;
  padding: 1% 3%;
  /* justify-content: center; */
  align-items: center;
  margin-bottom: 2%;
  gap: 2rem;
}
/* .all_songs__list__item 1{
  display: flex;
  align-items: center;
  width: 70%;
  gap: 5%;
} */
.all__songs__list__item h3 {
  font-size: 2.2rem;
  font-weight: 400;
  color: #333333;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 40%;
  /* width: 100%; */
}

.all__songs__list__item .duration {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.duration-bar-container {
  width: 90%;
  position: relative;
}

.duration-bar-container .duration-bar-back {
  width: 100%;
  height: 3px;
  border-radius: 2px;
  background-color: #ddd;
  position: absolute;
}

.duration-bar-front {
  height: 3px;
  border-radius: 2px;
  background-color: #ddd;
  position: absolute;
  background-color: #fe2b0d;
}

.all__songs__list__item img {
  width: 2.5rem;
  aspect-ratio: 1;
}
.all__songs__list__item button {
  cursor: pointer;
  /* margin-right: 2%; */
  /* margin-left: 2%; */
  border: none;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
}
/* .all__songs__list__item 2{
  display: flex;
  align-items: center;
  gap: 3rem;
  margin-left: auto;
} */
.all__songs__list__item p {
  font-size: 2rem;
  font-weight: 300;
  color: #333333;
}
.all__songs__list__item button {
  color: #ff4d2f;
  border: none;
  background-color: transparent;
  display: flex;
  align-items: center;
  font-size: 1.8rem;
  gap: 4%;
  cursor: pointer;
}
/* .all__songs__list__item button img {
  width: 2rem;
  margin-right: 5%;
} */
.beat-section-comments-div {
  padding-top: 5%;
  padding-left: 10%;
  padding-right: 10%;
  padding-bottom: 15%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.comments-textbox {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  width: 60%;
}

.comments-login-error {
  width: 100%;
  text-align: left;
  text-align: center;
  background-color: #ff4d2f;
  border-radius: 5px;
  color: white;
  font-size: 1.6rem;
  padding: 1.5%;
  margin-bottom: 3%;
}

.comments-textarea {
  border: 1px solid #666666;
  resize: none;
  width: 100%;
  padding: 1.5%;
  border-radius: 5px;
  height: 12rem;
  margin-bottom: 2%;
  font-size: 1.8rem;
}
.comments-textarea:focus {
  outline: none;
}
.comments-textarea::placeholder {
  color: #636466;
}
.comments-submit-button {
  background-color: #fe2b0d;
  border: none;
  border-radius: 3px;
  font-size: 2rem;
  color: #ffffff;
  padding: 1% 2.5%;
  align-self: flex-end;
  cursor: pointer;
}
.comments-display-section {
  width: 60%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.comments-heading {
  font-size: 2.7rem;
  color: #666666;
  font-weight: 500;
}
.comments-sections {
  display: flex;
  flex-direction: column;
}
.comments-item {
  margin-top: 6%;
  display: flex;
  align-items: center;
  width: 100%;
  padding-bottom: 7%;
  border-bottom: 1px solid #d4d4d4;
}
.comments-item-image {
  width: 8%;
  height: 7rem;
  /* background-color: #414141; */
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 2%;
}
.comments-item-image img {
  width: 100%;
  border-radius: 50%;
  object-fit: contain;
}
.comments-item-detail {
  display: flex;
  /* align-items: center; */
  flex-direction: column;
  width: 80%;
}
.item-detail-name {
  color: #666666;
  font-size: 2.3rem;
  font-weight: normal;
}
.item-detail-date {
  color: #707070;
  font-size: 1.9rem;
  font-weight: normal;
  margin-bottom: 3%;
}
.item-detail-comment {
  color: #666666;
  font-size: 2rem;
  font-weight: normal;
}
.comments-item-like {
  display: flex;
  align-self: flex-end;
  justify-self: flex-end;
  justify-content: center;
  align-items: flex-end;
  gap: 20%;
}
.item-detail-like-quantity {
  color: #333333;
  font-size: 1.7rem;
  font-weight: normal;
}
.item-like-button {
  cursor: pointer;
  background-color: transparent;
  border: none;
}
.item-like-button img {
  width: 100%;
}

.remove-beat-confirm-message {
  /* text-align: left !important; */
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.remove-beat-confirm-message img {
  margin-right: 2%;
}

.remove-beat-confirm {
  background-color: #fe2b0d !important;
}

.remove-beat-confirm-container {
  margin-bottom: 0 !important;
}

@media only screen and (max-width: 68.75em) {
  .album__description {
    flex-direction: column;
    gap: 2rem;
  }

  .album-section-description-div,
  .album-section-all-music {
    width: 100%;
  }
}

@media only screen and (max-width: 37.5em) {
  .top-heading {
    padding-left: 0;
    padding-top: 3.5%;
    gap: 5%;
    flex-direction: column;
    width: auto;
    justify-content: center;
    align-items: center;
  }
  .beat-section-description {
    width: auto;
    align-items: center;
  }
  .extra-like-name,
  .extra-share-name,
  .extra-comment-name,
  .extra-playlist-name {
    white-space: nowrap;
  }
  .beat-section-buy-button {
    padding: 11% 30%;
  }
  .comments-textbox {
    margin-bottom: 5%;
    width: 100%;
  }
  .comments-submit-button {
    padding: 2% 5.5%;
  }
  .comments-display-section {
    width: 100%;
  }
  .comments-item-image {
    display: none;
  }
  .beat-section-name {
    text-align: center;
    width: 90%;
  }
}
@media only screen and (max-width: 68.75em) {
  .comments-item-image {
    width: 15%;
  }
}
