.hero-div {
  background-image: url("./../../images/width.jpg");
  width: 100vw;
  height: 100vh;
  background-size: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center top;
  overflow: hidden;
  position: relative;
  margin-top: -8rem;
  top: 0;
}
.hero-div::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #0d0d0d;
  mix-blend-mode: multiply;
  opacity: 0.71; /* Adjust the color and opacity as needed */
}
.background-overlay {
  background-color: #0d0d0d;
  mix-blend-mode: multiply;
  opacity: 0.71;
  width: 100%;
  height: 100%;
}
.overlay-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  /* max-width: 50.6%; */
  /* height: 42%; */
}

.overlay-heading {
  font-family: "Lato", sans-serif;
  font-size: 9rem;
  font-weight: 700;
  color: #fff;
  text-align: center;
  line-height: 1.6;
  /* display: flex; */
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.overlay-heading-beats {
  color: #fe2b0d;
  display: inline-block;
  background-image: url("./../../images/beats-underline.png");
  background-repeat: no-repeat;
  flex-direction: column;
  align-items: center;
  background-size: contain;
  background-position: bottom;
  /* width: 27%; */
  /* margin: 0 -1%; */
  max-width: 90%;
}
/* .overlay-heading-beats img {
  margin: -7%;
  width: 100%;
} */

.overlay-search--container {
  display: flex;
  align-items: center;
  background-color: #fff;
  border-radius: 4px;
  overflow: hidden;
  border: 1px solid #cdcccc;
  max-width: 100%;
  width: 100%;
  /* height: 16.7%; */
  aspect-ratio: 15;
  /* position: relative; */
}

.overlay-search-container--right {
  padding-right: 1%;
  cursor: pointer;
  background-color: #fff;
  border: none;

  border-left: 1px solid #707070;
  font-family: "Poppins", sans-serif;
  font-size: 1.4rem;
  font-weight: 300;
  color: #555555;
  width: 17%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin-left: auto;
}

.overlay-search-container--left {
  padding-left: 2.3%;
  color: #444;
  padding-right: 2.3%;
  cursor: pointer;
  /* height: 24%; */
  width: 7.1%;
}

.overlay-search {
  width: 100%;
  width: 72.4%;
  height: 64%;
  outline: none;
  border: none;
  background-color: #fff;
  padding-left: 1.3%;
  /* color: #cbcbcb; */
  font-family: "Poppins", sans-serif;
  font-size: 1.6rem;
  font-weight: 300;
}

.overlay-search-error {
  width: 3%;
  aspect-ratio: 1;
  margin-right: 1.5%;
  cursor: pointer;
}

.main-search-category-popup-content {
  width: 9%;
  background-color: #ffffff;
  box-shadow: 0px 3px 6px #00000029;
  padding: 1%;
  border-radius: 10px;
  margin-top: 1.5%;
  font-size: 1.5rem;
  color: #0d0d0d;
}

.main-search-category-popup-content .search-category__option {
  margin: 5% 0;
  text-align: center;
  padding: 5%;
  border-radius: 7px;
  transition: 0.2s ease-in-out;
}

.main-search-category-popup-content .search-category__option:hover {
  /* background-color: #f6f7f9; */
  background-color: #e6e7e9;
  cursor: pointer;
}

.main-search-category-popup-content .search-category__option-selected {
  /* background-color: #f6f7f9; */
  /* background-color: #efefef; */
  background-color: #e6e7e9;
}

/* @media only screen and (max-width: 103.75em) {
  .hero-div {
    background-size: 105%;
  }
}

@media only screen and (max-width: 93.75em) {
  .hero-div {
    background-size: 111%;
  } */

  /* .overlay-heading-beats {
    margin: 0 -2%;
  } */
/* } */

@media only screen and (max-width: 75.75em) {
  /* .overlay-heading-beats {
    margin: 0 -3%;
  } */
}

@media only screen and (max-width: 68.75em) {
  /* .hero-div {
    background-size: 180%;
  } */
  /* .overlay-heading-beats img {
    width: 80%;
    margin: -6%;
  }
  .overlay-heading-beats {
    margin: 0 -3%;
  } */
  .overlay-search--container{
    aspect-ratio: 12;
  }
  .overlay-search-container--left{
    width: 7.1%;
  }
}
@media only screen and (max-width: 56.25em) {
  /* .hero-div {
    background-size: 246%;
    height: 90rem;
  } */
  .overlay-container {
    width: 60%;
  }
  .overlay-heading {
    font-size: 8rem;
  }
  /* .overlay-heading-beats {
    margin: 0 -7%;
  }
  .overlay-heading-beats img {
    width: 60%;
    margin: -4%;
  } */
  .overlay-search {
    width: 60%;
  }
  .overlay-search-container--right {
    width: 27%;
  }
  .main-search-category-popup-content {
    width: 26%;
  }
  .overlay-search--container{
    aspect-ratio: 12;
  }
  .overlay-search-container--left{
    width: 7.1%;
  }
}

@media only screen and (max-width: 43.75em) {
  .overlay-container {
    width: 65%;
  }
  .overlay-heading {
    font-size: 7rem;
  }
  /* .overlay-heading-beats {
    margin: 0 -10%;
  } */
}

@media only screen and (max-width: 31.25em) {
  .overlay-container {
    width: 70%;
  }
  /* .hero-div {
    background-size: 260%;
    height: 95rem;
  } */
  .overlay-heading {
    font-size: 6rem;
  }
  /* .overlay-heading-beats {
    margin: 0 -21%;
  }
  .overlay-heading-beats img {
    width: 45%;
    margin: -3%;
  } */
}
@media only screen and (max-width: 37.5em) {
  .overlay-search-container--left{
    display: none;
  }
  .overlay-search {
    width: 65%;
    padding-left: 2.3%;
  }
  .overlay-search--container {
    
    aspect-ratio: 9;
    
  }
}
@media only screen and (max-width: 26.5em) {
  .overlay-container {
    width: 75%;
  }
  /* .hero-div {
    background-size: 345%;
    height: 105rem;
  } */
  /* .overlay-heading-beats {
    margin: 0 -19%;
  } */
  .overlay-heading-beats img {
    width: 45%;
    margin: -3%;
  }
}

@media only screen and (max-width: 21.875em) {
  .overlay-container {
    width: 87%;
  }
  /* .hero-div {
    background-size: 410%;
    height: 70rem;
  } */
  .overlay-heading {
    font-size: 6rem;
  }
  /* .overlay-heading-beats {
    margin: 0 -14%;
  } */
  .overlay-heading-beats img {
    width: 45%;
    margin: -3%;
  }
  .overlay-search-container--left{
    display: none ;
  }
  .overlay-search{
    padding-left: 3.3%;
    width: 66%;
  }
  .overlay-search--container{
    aspect-ratio: 9;
  }
}

@media only screen and (max-width: 18.75em) {
  /* .hero-div {
    background-size: 300%;
    height: 100rem;
  } */
}
