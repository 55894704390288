.messages-section {
  scroll-behavior: smooth;
  background-color: #f6f7f9;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2.5rem 7%;
}
.messages-box-whole {
  width: 100%;
  /* margin-top: 3%; */
  display: flex;
  flex-direction: row;
  border: 1px solid #d4d4d4;
  border-radius: 8px;
  background-color: #ffffff;
  margin-bottom: 5%;
  height: 81vh;
}
.messages-sidebar {
  width: 24%;
  border-right: 1px solid #d4d4d4;
  display: flex;
  flex-direction: column;
  padding: 2% 0%;
  padding-bottom: 0;
}
.messages-sidebar-heading {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5%;
  padding-bottom: 10%;
  border-bottom: 1px solid #d4d4d4;
}
.messages-sidebar-heading h3 {
  color: #333333;
  font-size: 2.2rem;
  font-weight: 500;
}
.messages-items {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
}
.messages-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5%;
  padding: 5% 5%;
  border-top: 1px solid #d4d4d4;
  cursor: pointer;
}

.messages-item:first-of-type {
  border-top: none;
}

.messages-item:last-child {
  border-bottom: 1px solid #d4d4d4;
}
.messages-item:hover {
  background-color: #f5f5f5;
}
.messages-item img {
  width: 15%;
  border-radius: 50%;
  aspect-ratio: 1;
}
.messages-item-detail {
  width: 80%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.messages-item-detail-time {
  position: absolute;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 1rem;
  justify-content: center;
}

.message-item-detail-unread {
  font-size: 1.3rem;
  border-radius: 50%;
  aspect-ratio: 1;
  background-color: #fe2b0d;
  color: white;
  padding: 0.5%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.55rem;
}

.messages-item-detail-div {
  display: flex;
  flex-direction: column;
}
.messages-item-detail-name {
  color: #333333;
  font-size: 1.6rem;
  font-weight: normal;
}
.messages-item-detail-description {
  color: #707070;
  font-size: 1.3rem;
  font-weight: 300;
  white-space: nowrap;
}

.last_message_time {
  white-space: nowrap;
  color: #707070;
  font-weight: normal;
  font-size: 1.3rem;
}
.messages-chatbox {
  width: 51%;
  display: flex;
  flex-direction: column;
}
.chatbox-top-heading {
  border-bottom: 1px solid #d4d4d4;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 2%;
  height: 10rem;
  z-index: 997;
}
.chatbox-top-heading-details {
  display: flex;
  width: 80%;
  align-items: center;
  gap: 2%;
}
.chatbox-top-heading-details img {
  width: 7%;
  border-radius: 50%;
  aspect-ratio: 1;
  object-fit: cover;
}
.chatbox-top-heading-detail {
  display: flex;
  flex-direction: column;
}
.chatbox-top-heading-detail h3 {
  color: #333333;
  font-size: 1.7rem;
  font-weight: normal;
}
.chatbox-top-heading-detail p {
  color: #229e04;
  font-size: 1.4rem;
  font-weight: normal;
}
.messages-workstream {
  width: 25%;
  border-left: 1px solid #d4d4d4;
  padding: 2% 1%;
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
}
.workstream-top {
  display: flex;
  flex-direction: column;
}
.workstream-top h3 {
  color: #333333;
  font-size: 1.9rem;
  font-weight: normal;
  margin-bottom: 4%;
}
.workstream-top div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2%;
}
.workstream-top div p {
  color: #707070;
  font-size: 1.7rem;
  font-weight: normal;
}
.workstream-navigation {
  display: flex;
  width: 97%;
  align-self: center;
  justify-content: center;
  align-items: center;
  border: 1px solid #d4d4d4;
  background-color: #ffffff;
  border-radius: 6px;
  box-shadow: inset 0px 3px 6px #00000029;
  margin: 5% 0%;
}

.workstream-feed {
  background-color: transparent;
  border: none;
  padding: 5% 0%;
  color: #666666;
  font-size: 1.5rem;
  display: flex;
  justify-content: center;
  width: 50%;
  white-space: nowrap;
  font-weight: 400;
  cursor: pointer;
  border-right: 1px solid #d4d4d4;
}

.workstream-feed::after {
  content: "\00a0 Feed";
}

.workstream-attachements {
  background-color: transparent;
  border: none;
  /* padding: 5%; */
  width: 50%;
  font-weight: 400;
  cursor: pointer;
  color: #666666;
  font-size: 1.5rem;
  white-space: nowrap;
  text-align: center;
}
.feed-details {
  display: flex;
  flex-direction: column;
  margin-top: 3%;
  height: 100%;
  overflow-y: auto;
}
.feed-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  background-color: #ffffff;
  margin-bottom: 3%;
}
.feed-item-color {
  width: 2%;
  /* background-color: #229e04; */
  /* margin-right: 4%; */
  height: 100%;
}
.feed-item-type-image {
  width: 10%;
  margin-left: 4%;
  margin-right: 5%;
}
.feed-item-detail {
  margin: 5% 0%;
}
.feed-item-detail h3 {
  color: #333333;
  font-size: 1.5rem;
  font-weight: 500;
}
.feed-item-detail p {
  color: #707070;
  font-size: 1.4rem;
  font-weight: normal;
}

.chatbox-messages {
  padding: 4% 4% 0 3%;
  height: 100%;
  overflow-y: auto;
}

.message-row {
  margin: 2% 0;
}

.message-commission {
  display: flex;
  background-color: white;
  border: 1px solid #d4d4d4;
  margin-bottom: 5%;
}

.commission-status-bar {
  background-color: #229e04;
  width: 1.5%;
}

.commission-details {
  width: 100%;
  padding: 2.7% 3.5%;
  padding-bottom: 0;
}

.commission-details-top {
  display: flex;
  align-items: center;
  padding: 1.8% 0.9%;
  padding-top: 0;
  /* padding-bottom: 1.8%; */

  border-bottom: 1px solid #d4d4d4;
}

.commission-details-top__title h3 {
  font-size: 1.5rem;
  font-weight: 500;
  color: #666666;
}

.commission-details-top__title h4 {
  margin-top: 1%;
  font-size: 1.5rem;
  font-weight: 400;
  color: #505050;
}

.commission-details-top__status {
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.commission-details-top__status img {
  width: 2.5rem;
  background-color: #229e04;
  border-radius: 50%;
  padding: 2%;
  aspect-ratio: 1;
  margin-right: 6%;
}

.commission-details-top__status p {
  font-size: 1.4rem;
  white-space: nowrap;
}

.commission-producer-details {
  display: flex;
  align-items: center;
  padding: 1.8% 0.9%;
  width: 100%;
}

.commission-producer-details img {
  width: 7.5%;
  aspect-ratio: 1;
  border-radius: 50%;
  object-fit: cover;
}

.commission-producer-details > div:first-of-type {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 2%;
  font-size: 1.25rem;
}

.commission-producer-details > div h4 {
  color: #f55446;
  font-weight: 400;
  white-space: nowrap;
}

.commission-button-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}

.commission-producer-details button {
  margin: 0 1%;
  white-space: nowrap;

  /* margin-left: auto; */
  /* white-space: nowrap; */
  appearance: none;
  background: none;
  border: none;
  outline: none;
  padding: 1% 3%;
  font-family: inherit;
  cursor: pointer;
  color: white;
  background-color: #fe2b0d;
  font-size: 1.5rem;
  border-radius: 5px;
}

.commission-producer-details button:last-of-type {
  margin-right: 0;
}

.chatbox-date-divider {
  width: 95%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  font-size: 1.5rem;
  color: #505050;
  font-weight: 500;
}

.chatbox-date-divider span {
  width: 100%;
  border: 1px solid #d4d4d4;
}

.chatbox-date-divider span:first-of-type {
  margin-right: 1.5%;
}

.chatbox-date-divider span:last-of-type {
  margin-left: 1.5%;
}

.message-text {
  display: flex;
  align-items: flex-start;
  width: 100%;
}

.message-text > img {
  width: 7%;
  aspect-ratio: 1;
  border-radius: 50%;
  object-fit: cover;
}

.message-content {
  max-width: 62%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 2.5%;
  font-size: 1.4rem;
  background-color: #f5f5f5;
  padding: 2.8% 3.8%;
  color: #333333;
}

.message-content_time {
  margin-left: auto;
}

.message-content-attachment {
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 2.5%;
  font-size: 1.4rem;
  background-color: #f5f5f5;
  padding: 2.8% 3.8%;
  color: #333333;
}

.message-content_attachment {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: fit-content;
  white-space: nowrap;
  width: 100%;
}

.message-content_attachment > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
}

.message-content_attachment > div > h5 {
  font-size: 1.45rem;
  color: #333333;
  font-weight: 500;
}

.message-content_attachment > img {
  width: 12%;
}

.chatbox-bottom {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: auto;
}

.chatbox-bottom-attachments {
  display: flex;
  width: 100%;
  align-items: center;
  padding: 1.5%;
  border-top: 1px solid hsl(0, 0%, 83%);
  border-bottom: 1px solid #d4d4d4;
}

.message-add-attachment {
  appearance: none;
  background: none;
  border: none;
  outline: none;
  margin: 0;
  padding: 0;
  font-family: inherit;
  font-size: inherit;
  cursor: pointer;
  display: flex;
  align-items: center;
  /* color: #666666; */
  font-size: 1.4rem;
  white-space: nowrap;
}

.message-add-attachment img {
  width: 3rem;
  /* margin-right: 1%; */
}

.chatbox-bottom-new-message {
  display: flex;
  align-items: center;
}

.chatbox-bottom-new-message textarea {
  width: 100%;
  height: 8rem;
  font-size: 1.3rem;
  border: none;
  outline: none;
  resize: none;
  outline: none;
  padding: 1% 4%;
  font-family: inherit;
  line-height: inherit;
  box-sizing: border-box;
}

.chatbox-bottom-new-message button {
  margin: 0;
  margin-right: 3.5%;
  margin-left: 1%;
  appearance: none;
  background: none;
  border: none;
  outline: none;
  padding: 1% 4%;
  font-family: inherit;
  cursor: pointer;
  color: white;
  background-color: #fe2b0d;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
}

.chatbox-bottom-new-message button:disabled {
  opacity: 0.6;
}

.select-chat-placeholder {
  width: 76%;
  height: 81vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10%;
  font-size: 2.5rem;
  font-weight: 400;
  letter-spacing: 0.4px;
  text-align: center;
}

.commission-details-popup-overlay {
  background-color: rgba(0, 0, 0, 0.8);
}

.commission-details-popup-content {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border: 1px solid #707070;
  color: #333333;
  border-radius: 6px;
  width: 43%;
}

.commission-details-popup__close {
  position: absolute;
  top: -2.5%;
  right: -8%;
  width: 5%;
  aspect-ratio: 1;
  object-fit: cover;
  cursor: pointer;
}

.commission-details-popup-content h3 {
  font-size: 2rem;
  font-weight: 400;
  padding: 4.5% 0% 2.5% 4%;
  border-bottom: 1px solid #d4d4d4;
  width: 100%;
}

.commission-details-popup-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding: 2.5% 2%;
  padding-bottom: 5%;
}

.commission-details-popup-container__row {
  display: flex;
  flex-direction: row;
  font-size: 1.5rem;
  margin: 1%;
  align-items: center;
}

.commission-details-popup-container__row:first-of-type {
  align-items: flex-start;
}

.commission-details-popup-container__row label {
  color: #333333;
  width: 35%;
  letter-spacing: 0.3px;
}

.commission-details-participants-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  color: #505050;
  width: 100%;
}
.commission-details-participant {
  display: flex;
  align-items: center;
}
.commission-details-participant img {
  width: 6.5%;
  object-fit: cover;
  aspect-ratio: 1;
  border-radius: 50%;
  margin-right: 2.5%;
}

.contract-details-popup__detail-box {
  background-color: #eeeeee;
  display: flex;
  align-items: center;
  border-radius: 10px;
  width: 100%;
  padding: 2.5% 3%;
}

.contract-details-popup__detail-box .bullet {
  width: 2.5%;
  background-color: #fe2b0d;
  border-radius: 50%;
}

.commission-details-popup-container__row-description {
  width: 100%;
  padding: 2% 1.5%;
  border-left: 2px solid #d4d4d4;
  overflow-wrap: anywhere;
  margin-top: -2%;
}

.cancel-commission {
  margin-top: 5%;
  color: #fe2b0d;
  font-size: 1.4rem;
  font-weight: 500;
  cursor: pointer;
}

.cancel-commission:last-of-type {
  margin-top: 1.5%;
}

.place-bid-popup-container textarea {
  font-size: 1.4rem;
  border: 1px solid #707070;
  border-radius: 5px;
  height: 15rem;
  padding: 3% 4%;
  outline: none;
  resize: none;
  margin: 0 4%;
  margin-top: 5%;
}

.button-container__dispute {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  /* aspect-ratio: 2.87; */
  /* width: 31.5%; */
  padding: 3% !important;
  color: white;
  background-color: #fe2b0d;
  outline: none;
  border: none;
  cursor: pointer;
}

.dispute-popup-container .button-container {
  margin-bottom: 4%;
}

.commission-payment-popup-container {
  padding-top: 6%;
}

.commission-payment-detail-container {
  display: flex;
  align-items: center;
  font-size: 1.5rem;
}

.commission-payment-detail-container p:first-of-type {
  width: 50%;
  margin: 0.5%;
  font-weight: 500;
}

.commission-payment-popup-container > p {
  margin-top: 9%;
  font-weight: 500;
  margin-bottom: 3%;
  font-size: 1.5rem;
}

.counter-offer-popup-container p {
  font-size: 1.4rem;
  margin-top: 2%;
}

.chatbox-top-heading-more,
.chatbox-top-heading-chats {
  display: none;
}

.messages-sidebar-popup-content {
  width: 40%;
  height: calc(81vh - 7rem);
  border: 1px solid #d4d4d4;
  border-radius: 0 0 8px 8px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 996 !important;
  margin-top: 1.5rem;
  border-top: none;
}

.messages-sidebar-popup-content > img {
  width: 12%;
  margin-bottom: 7%;
}

.workstream-sidebar-popup-content {
  left: calc(100% - 5% - 40% - 1%) !important;
  padding: 2%;
  margin-left: 2%;
}

.messages-chatlist-popup-content {
  left: calc(4%) !important;
  margin-top: 5.5rem;
}
.messages-chatlist-popup-content .messages-items {
  padding-right: 1rem;
}

.workstream-sidebar-popup-content > img {
  margin-left: auto;
}

.message-items-mobile {
  display: none;
  height: 100%;
  overflow-y: auto;
}

.select-chat-placeholder-mobile {
  display: none;
  width: 100%;
  height: 81vh;
  /* display: flex; */
  align-items: center;
  justify-content: center;
  padding: 10%;
  font-size: 2.5rem;
  font-weight: 400;
  letter-spacing: 0.4px;
  text-align: center;
}

.attachment-feed-container {
  width: 100%;
  padding: 0 2%;
}

.attachment-feed-container > button {
  white-space: nowrap;
  appearance: none;
  background: none;
  border: none;
  outline: none;
  padding: 3% 5%;
  font-family: inherit;
  cursor: pointer;
  color: white;
  background-color: #333333;
  font-size: 1.5rem;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 46%;
}

.attachment-feed-container > button > img {
  margin-left: 8%;
  width: 23%;
  aspect-ratio: 1;
}

.attachment-file-popup-content {
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 6px;
  box-shadow: 0px 3px 6px #00000029;
  padding: 1%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 10%;
  margin-top: 1rem;
}

.attachment-file-popup-content > div {
  margin-bottom: 12%;
  color: #707070;
  font-size: 1.45rem;
  cursor: pointer;
}

.attachment-file-popup-content > div:last-of-type {
  margin-bottom: 0;
}

.attachment-feed {
  height: 100%;
  overflow-y: auto;
  margin-top: 9%;
  padding-left: 2%;
  padding-right: 3%;
}

.attachment-feed-row {
  display: flex;
  align-items: center;
  margin-bottom: 14%;
}

.attachment-feed-row:last-of-type {
  margin-bottom: 0;
}

.attachment-feed-row > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.attachment-feed-row > div > h5 {
  font-size: 1.45rem;
  color: #333333;
  font-weight: 500;
}

.attachment-feed-row > div > p {
  font-size: 1.25rem;
  color: #707070;
  font-weight: 400;
}

.attachment-feed-row > img:first-of-type {
  width: 13%;
  margin-right: 5%;
}

.attachment-feed-row > img:last-of-type {
  width: 11%;
  margin-left: auto;
  cursor: pointer;
}

.attachment-load-placeholder {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.messages-mobile-section {
  display: none;
  flex-direction: column;
  justify-content: center;
}

.currency-symbol {
  background-color: transparent;
  color: #c8c4c4;
  border: 2px solid #c8c4c4;
  border-radius: 50%;
  aspect-ratio: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.7rem;
  font-weight: 700;
}

@media only screen and (max-width: 106.25em) {
  .workstream-feed::after {
    content: "";
  }

  .messages-sidebar {
    width: 30%;
  }

  .select-chat-placeholder {
    width: 70%;
  }

  .messages-chatbox {
    width: 45%;
  }
}

@media only screen and (max-width: 68.75em) {
  .messages-section {
    padding: 2.5rem 4%;
  }

  .workstream-top h3 {
    text-align: center;
  }
}

@media only screen and (max-width: 62.5em) {
  .messages-workstream {
    display: none;
  }

  .chatbox-top-heading-more {
    display: initial;
  }

  .messages-sidebar {
    width: 35%;
  }

  .messages-chatbox,
  .select-chat-placeholder {
    width: 65%;
  }

  .commission-details-popup-content {
    width: 55%;
  }

  .commission-details-participant img {
    width: 10%;
  }
}

@media only screen and (max-width: 56.25em) {
  .messages-sidebar {
    width: 40%;
  }

  .messages-chatbox,
  .select-chat-placeholder {
    width: 60%;
  }
}

@media only screen and (max-width: 43.75em) {
  .messages-section {
    padding: 2.5rem 4%;
  }

  .messages-mobile-section {
    display: flex;
  }

  .messages-sidebar {
    display: none;
  }

  .workstream-sidebar-popup-content {
    left: calc(100% - 4% - 50% - 1%) !important;
    margin-left: 1%;
  }

  .messages-chatbox {
    width: 100%;
  }

  .messages-sidebar-popup-content,
  .chatbox-top-heading-details {
    width: 50%;
  }

  .chatbox-top-heading-details {
    flex-direction: column;
    text-align: center;
  }

  .select-chat-placeholder,
  .chatbox-top-heading-details img {
    display: none;
  }

  .message-items-mobile,
  .chatbox-top-heading-chats {
    display: initial;
  }

  .select-chat-placeholder-mobile {
    display: flex;
  }

  .messages-item {
    padding: 3%;
  }

  .messages-sidebar-heading {
    padding: 4%;
  }

  .commission-details-popup-content {
    width: 85%;
  }

  .commission-details-popup-container__row label {
    width: 60%;
  }
}

@media only screen and (max-width: 31.25em) {
  .messages-sidebar-popup-content {
    width: 92%;
  }

  .workstream-sidebar-popup-content {
    left: calc(100% - 4% - 92% - 1%) !important;
  }

  .messages-sidebar-popup-content {
    margin-top: 1.5rem;
  }

  .commission-details-popup-container__row label {
    width: 90%;
  }

  .commission-details-participant img {
    width: 14%;
  }
  .attachment-file-popup-content {
    width: 25%;
  }
}

.commission-payment-popup-container input {
  font-size: 1.5rem;
}
