.checkout-section {
  display: flex;
  min-height: 100vh;
}

.checkout-summary {
  padding-top: 3%;
  padding-left: 12%;
  padding-bottom: 11%;
  width: 50%;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  /* align-items: center; */
  background-color: #ffffff;
}
.checkout-form {
  padding: 3% 8.5%;
  padding-right: 13%;
  padding-bottom: 11%;
  width: 50%;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  background-color: #f6f7f9;
}
.checkout-heading {
  color: #333333;
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 7%;
}
.checkout-summary-items {
  width: 80%;
  display: flex;
  flex-direction: column;
  padding-bottom: 5%;
  border-bottom: 1px solid #d4d4d4;
  /* padding: 2%; */
  /* width: 100%; */
  max-height: 60rem;
  overflow-y: auto;
  /* padding-right: 1rem; */
}
.checkout-summary-item {
  display: flex;
  align-items: center;
  margin-top: 8%;
  /* gap: 10%; */
}
.checkout-summary-item:first-of-type {
  margin-top: 0;
}

.warning {
  color: red;
}
.checkout-summary-item-div {
  display: flex;
  align-items: center;
  /* margin-top: 8%; */
  width: 80%;
}
.card-email {
  position: relative;
}

.card-email img {
  padding: 0.8rem;
  width: 4.25rem;
  position: absolute;
  right: 0;
  cursor: pointer;
  top: 3.5rem;
}
.checkout-summary-item-image {
  width: 20%;
  aspect-ratio: 1;
  object-fit: cover;
  border-radius: 7px;
  margin-right: 13%;
}
.checkout-summary-item-details {
  display: flex;
  /* align-items: center; */
  justify-content: center;
  flex-direction: column;
  /* margin-right: 10%; */
  width: 77%;
}
.checkout-summary-item-name {
  font-size: 1.6rem;
  color: #131313;
  font-weight: normal;
  letter-spacing: 0.3px;
}
.checkout-summary-item-total-heading {
  width: 80%;

}
.checkout-summary-item-total-price{
  width: 20%;
  text-align: right;

}
.checkout-summary-item-artist {
  font-size: 1.3rem;
  color: #666666;
  font-weight: normal;
  letter-spacing: 0.2px;
}
.checkout-summary-item-price {
  color: #666666;
  font-size: 1.4rem;
  font-weight: normal;
  width: 20%;
  text-align: right;
}
.checkout-summary-item-total-div {
  margin-top: 5%;
  display: flex;
  width: 80%;
  justify-content: space-between;
  align-items: center;
}
.checkout-summary-item-total-heading,
.checkout-summary-item-total-price {
  font-size: 1.9rem;
  color: #131313;
  font-weight: 600;
  /* margin-right: 5%; */
}

.checkout-form-complete p {
  font-size: 1.4rem;
  color: #666666;
  letter-spacing: 0.4px;
}

.checkout-summary-item-total-div img {
  width: 3rem;
  aspect-ratio: 1;
  margin-right: 1rem;
}

.checkout-summary-item-total-div img:last-of-type {
  cursor: pointer;
}

.checkout-form-complete input {
  font-size: 1.25rem;
  letter-spacing: 0.3px;
  border-radius: 4px;
  outline: 1px solid #d4d4d4;
  border: none;

  width: 100%;
  padding: 3% 4%;
  margin-top: 1.6%;
}

.checkout-form-paypal p {
  margin-bottom: 1%;
}

.paypal-button {
  background: none;

  padding: 0;
  margin: 0;
  color: inherit;
  cursor: pointer;
  outline: none;
  border-radius: 6px;
  aspect-ratio: 8.45;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 3rem;

  background-color: transparent;
  border: 1px solid #aaaaaa;
}

.paypal-button:first-of-type {
  border: none;
  background-color: #f7c039;
}

.paypal-button img {
  width: 19%;
}

.other-option-line {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 4%;
}

.other-option-line__line {
  width: 100%;
  border: 0.5px solid #d4d4d4;
}

.other-option-line span {
  width: 100%;
  text-align: center;
  margin: 0 5.5%;
  font-size: 1.25rem;
  color: #666666;
}

.card-email {
  margin-top: 5%;
}

.card-details,
.card-name,
.card-country {
  margin-top: 4%;
}

.card-details-extras {
  display: flex;
  align-items: center;
  justify-content: center;
}

.card-details-extras input {
  margin-top: 3%;
}

.card-details-extras input:first-of-type {
  margin-right: 2%;
}

.card-details-submit {
  margin-top: 8%;
}

.card-details-submit button {
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
  outline: none;
  color: white;
  background-color: #262626;
  border-radius: 6px;
  font-size: 1.75rem;
  width: 100%;
  aspect-ratio: 7.43;
}

@media only screen and (max-width: 62.5em) {
  .checkout-summary {
    padding-left: 9%;
  }
  .checkout-form {
    padding-right: 9%;
  }
  .checkout-summary-item-details h3 {
    white-space: nowrap;
    overflow: hidden;
    max-width: 95%;
    text-overflow: ellipsis;
  }
}

@media only screen and (max-width: 50em) {
  .checkout-section {
    flex-direction: column;
    padding: 2.5% 8%;
  }
  .checkout-summary,
  .checkout-form,
  .checkout-summary-items,
  .checkout-summary-item-total-div {
    width: 100%;
    padding: 0;
  }
  .checkout-summary-items {
    padding-right: 3%;
    max-height: 100rem;
    padding-bottom: 5%;
  }
  .checkout-summary-item-details h3 {
    white-space: nowrap;
    overflow: hidden;
    max-width: 85%;
    text-overflow: ellipsis;
  }
  .checkout-form {
    background-color: transparent;
  }
  .checkout-form .checkout-heading {
    display: none;
  }
  .checkout-summary {
    margin-bottom: 5%;
  }
  .paypal-button {
    aspect-ratio: 9;
  }
  .checkout-summary-item-total-price {
    margin-right: 3%;
  }
}

@media only screen and (max-width: 37.5em) {
}

@media only screen and (max-width: 31.25em) {
}

@media only screen and (max-width: 21.875em) {
}

.paystack-email-input {
  margin-top: 10rem;
  position: relative;
  display: flex;
  align-items: center;
  height: 2.5rem;
}

.paystack-email-input img {
  position: absolute;
  height: 2.5rem;
  width: 2.5rem;
  right: 0;
  cursor: pointer;
}

.paystack-continue {
  margin-top: 2rem;
  font-size: 1.5rem;
}
