.popular-producer {
  font-family: "Poppins";
  color: black;
  width: 100%;
  height: 54%;
  margin-bottom: 14.3rem;
  padding: 0 7.2%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* margin-left: auto; */
  /* margin-right: auto; */
}

.popular-producer h3 {
  font-size: 3.5rem;
  font-weight: 600;
  margin-bottom: 4rem;
}

.popular-producer-artist {
  display: flex;
  font-size: 1.9rem;
  font-weight: 400;
  text-align: center;
  /* align-items: center; */
  overflow: hidden;
  width: 100%;
  height: 67%;
}
.producer-item {
  /* width: 20%; */
  margin: 0 12.5%;
  font-size: 1.6rem;
  cursor: pointer;
}
/* .producer-item:last-child {
  margin-right: 0;
} */

.artist-img-holder {
  /* background-color: #fff; */

  width: 100%;
  aspect-ratio: 1;
  margin-bottom: 0.8rem;
  /* height: 77%; */
  /* height: 26.5rem; */
}

.popular-producer-artist p {
  color: #666666;
  font-size: 1.5rem;
}

.artist-img {
  /* height: 100%; */
  aspect-ratio: 1;
  width: 100%;
  object-fit: cover;
  border-radius: 50%;
}
.arrow-keys1 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 4rem;
}
.arrow-keys1 button {
  width: 5rem;
  height: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 1px solid #333333;
  background-color: transparent;
  cursor: pointer;
  margin-right: 1.6rem;
}
.arrow-keys1 img {
  width: 3rem;
  height: 3rem;
}
.arrow-keys1 a {
  margin-right: 3rem;
  font-size: 2.1rem;
  text-decoration: none;
  color: #333333;
}
.arrow {
  display: flex;
}
@media only screen and (max-width: 68.75em) {
}
@media only screen and (max-width: 56.25em) {
  .popular-producer {
    padding: 7% 5%;
    margin-bottom: 0;
    padding-bottom: 5%;
  }
}
@media only screen and (max-width: 31.25em) {
  .heading-name {
    font-size: 3rem !important;
  }

  .popular-producer {
    padding: 6% 4%;
    padding-bottom: 3%;
  }
}
@media only screen and (max-width: 24.875em) {
  .heading {
    align-items: center;
  }
  .arrow-keys1 {
    flex-direction: column;
  }
  .keys {
    margin-top: 1rem;
  }
}
