.share-popup-overlay {
  background-color: rgba(0, 0, 0, 0.8);
}

.share-popup-content {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border: 1px solid #707070;
  border-radius: 6px;
  /* width: 37%; */
}

.share-popup-heading {
  display: flex;
  align-items: center;
  padding: 5% 5% 2.5% 6%;
  border-bottom: 1px solid #d4d4d4;
}

.share-popup-heading h3 {
  font-size: 2rem;
  font-weight: 500;
}

.share-popup-heading img {
  margin-left: auto;
  width: 5%;
  aspect-ratio: 1;
  object-fit: contain;
  cursor: pointer;
}

.share-popup-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4% 5%;
  flex-wrap: wrap;
}

.share-popup-container__option {
  width: 18%;
  /* width: 20%; */
  font-size: 1.6rem;
  font-weight: 400;
  letter-spacing: 0.4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  aspect-ratio: 1;
  border-radius: 10px;
  transition: 0.3s ease-out;
  /* margin: 0 5%; */
}

.share-popup-container__option:hover {
  cursor: pointer;
  background-color: #d4d4d4;
}

.share-popup-container__option img {
  width: 65%;
  aspect-ratio: 1;
  margin-bottom: 3%;
}

.share-popup-container__option svg {
  width: 65%;
  aspect-ratio: 1;
  margin-bottom: 3%;
  width: 100%;
}

@media only screen and (max-width: 56.25em) {
  .share-popup-content {
    width: 60%;
  }
  .share-popup-container__option {
    width: 23%;
    margin-top: 3%;
  }
}
@media only screen and (max-width: 43.75em) {
  .share-popup-content {
    width: 70%;
  }
}
@media only screen and (max-width: 37.5em) {
  .share-popup-content {
    width: 80%;
  }
  .share-popup-container__option {
    width: 28%;
    margin-top: 3%;
  }
}
@media only screen and (max-width: 31.25em) {
  .share-popup-content {
    width: 90%;
  }
  .share-popup-container__option {
    width: 33%;
  }
}