.crop-popup-trigger {
  background-color: #fe2b0d;
  border: none;
  cursor: pointer;
  color: #fff;
  padding: 1.5rem 2.5rem;
  border-radius: 10px;
  margin-bottom: 1rem;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  width: fit-content;
  font-size: 1.5rem;
  margin-top: -4rem;
}

@media only screen and (min-width: 56.3em) {
  .crop-popup-content {
    width: 45vw;
  }
}

.crop-main-container {
  margin: 2% 5%;
  width: 90%;
  aspect-ratio: 1;
  max-height: 64vh;
}

.crop-sub-container {
  position: relative;
  width: 100%;
  height: 100%;
  /* max-height: 70vh; */
}

.crop-popup-done-button {
  margin: 2rem 5%;
  margin-top: 1rem;
  margin-left: auto;
}
