* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.upload-profile {
  background-color: #f6f7f9;
  font-family: "Poppins";
  font-weight: 400;
  color: black;
  padding-right: 45.6rem;
  padding-left: 33.9rem;
  padding-top: 5.6rem;
  padding-bottom: 16.4rem;
}

.upload-profile_input {
  background-color: #fff;
  border-radius: 8px;
  /* height: 180rem; */
  padding-top: 7.1rem;
  padding-left: 5.8rem;
  padding-right: 9.6rem;
  padding-bottom: 12.5rem;
}

.email-notification {
  font-size: 1.9rem;
  color: #2c7919;
  border-radius: 12px;
  height: 7.6rem;
  background-color: #eff2ee;
  padding: 2.5rem 3.4rem 2.4rem;
  margin-bottom: 5.8rem;
}

.welcome-user {
  font-size: 4rem;
  font-weight: 600;
  color: #131313;
  margin-bottom: 9px;
}

.complete-notification {
  color: #646363;
  font-size: 1.8rem;
  font-weight: 400;
  margin-bottom: 3.4rem;
}

.heading-primary {
  font-size: 1.9rem;
  font-weight: 400;
  color: #666666;
  margin-bottom: 3.4rem;
}

.upload-beats_label {
  font-size: 1.9rem;
  font-weight: 400;
  color: #636466;
  margin-bottom: 1.3rem;
  display: flex;
  align-items: center;
  width: 100%;
}

.upload-beats_label img {
  margin-left: auto;
  width: 3.5%;
  cursor: pointer;
}

.upload-error-popup-content {
  padding: 0.7rem 1.4rem;
  background-color: #fff;
  border: 1px solid #d4d4d4;
  box-shadow: 2px 2px #0a0a0a29;
  /* margin-top: 0.6rem; */
  border-radius: 6px;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 1.3rem;
  font-weight: 400;
}



.api-error {
  margin-left: auto;
}

.form-upload-option {
  padding-right: 2.95rem;
  appearance: none;
  -webkit-appearance: none;
  background: url(/src/images/caret-down.svg) no-repeat right center / 20px auto;
  background-position-x: calc(100% - 20px);
  color: #333333;
  font-size: 1.9rem;
}

.upload-beats-input {
  border-radius: 4px;
  outline: 1px solid #636466;
  border: none;
  height: 6rem;
  width: 100%;
  padding: 1.7rem 3rem 1.6rem;
  font-size: 1.9rem;
  color: #333333;
}

.upload-custom-textarea {
  height: 14rem; /* Adjust the height to fit three lines */
  /* overflow-y: scroll; */
  padding: 1.8rem 2.3rem 2.1rem 1.5rem; /* Add 10px padding */
  font-size: 1.8rem;
  width: 100%;
  resize: none;
  line-height: 2.15;
  box-sizing: border-box;
  font-family: "Poppins";
  font-weight: 400;
  margin-bottom: 4.15rem;
}

.upload-custom-textarea:placeholder-shown {
  font-style: italic;
  font-weight: 200;
}

.job-title,
.location {
  margin-bottom: 4.6rem;
}

.skills {
  margin-bottom: 4.85rem;
  cursor: pointer;
}

.opt-1 {
  margin-bottom: 2.3rem;
}

.opt-2 {
  margin-bottom: 4.1rem;
}

.add-portfolio {
  display: flex;
  align-items: center;
  gap: 1.3rem;
  margin-bottom: 7.1rem;
}

.add-portfolio span {
  font-size: 1.7rem;
  color: #ea6d62;
}

.save-profile {
  border-radius: 4px;
  border: none;
  cursor: pointer;
  height: 6rem;
  color: #fff;
  background-color: #f55446;
  padding: 1.6rem 4.7rem;
  font-size: 2.1rem;
}

.upload-picture {
  color: #fff;
  font-size: 1.9rem;
  font-weight: 500;
  padding: 1.55rem 5.87rem;
  background-color: #fe2b0d;
  border-radius: 4px;
  margin-top: 1rem;
}

.user-file-upload-label {
  display: flex;
  flex-direction: column;
  /* justify-content: space-evenly; */
  align-items: center;
  width: 100%;
  max-width: 37.7rem;
  background-color: #ededed;
  font-size: 1.9rem;
  font-weight: 200;
  height: 37.7rem;
  border-radius: 15px;
  border: 2px dashed #404040;
  border-spacing: 7px;
  margin-bottom: 7.85rem;
  text-align: center;
  cursor: pointer;
}

.user-file-upload-label img {
  margin-top: 6.91rem;
  margin-bottom: 3.18rem;
}

.profile-picture {
  margin-bottom: 3.4rem;
}

.profile-selected-file {
  display: flex;
  flex-direction: column;
  /* justify-content: space-evenly; */
  align-items: center;
  width: 100%;
  max-width: 33rem;
  background-color: #ededed;
  font-size: 1.9rem;
  font-weight: 200;
  /* height: 37.7rem; */
  border-radius: 15px;
  border: 2px dashed #404040;
  border-spacing: 7px;
  text-align: center;
  overflow: hidden;
}

.artwork-thumbnail {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.user-delete {
  margin-top: 2rem;
  margin-bottom: 7.85rem;
  max-width: 33rem;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.upload-animation {
  animation: fade-in 0.8s ease-in;
}

.skill-dropdown-content {
  margin: auto;
  background-color: #ffffff;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 7px;
  color: black;
  font-size: 1.9rem;
  margin-top: 1rem;
  height: fit-content;
  padding: 0.9rem 3rem;
  display: flex;
  flex-direction: column;
  width: calc(100% - 33.9rem - 45.6rem - 5.8rem - 9.6rem);
}
.service-item {
  font-size: 1.8rem;
  margin: 1rem 0;
  font-weight: 400;
}

.checkbox-container input {
  margin-right: 1.7rem;
  cursor: pointer;
}

@media only screen and (max-width: 106.25em) {
  .upload-profile {
    padding: 5.6rem 10.2rem;
  }

  .skill-dropdown-content {
    width: calc(100% - 20.4rem - 5.8rem - 9.6rem);
  }
}

@media only screen and (max-width: 84.375em) {
  .email-notification {
    font-size: 1.6rem;
    margin-bottom: 4.8rem;
  }

  .welcome-user {
    font-size: 3.2rem;
  }

  .complete-notification {
    font-size: 1.6rem;
  }

  .upload-beats_label {
    font-size: 1.7em;
  }

  .upload-beats-input {
    height: 5rem;
    font-size: 1.7rem;
    padding: 1.4rem 2.4rem;
  }

  /* .skill-dropdown-content {
    width: calc(100% - 10.2rem - 2.4rem);
  } */

  .upload-custom-textarea {
    height: 10rem;
  }

  .save-profile {
    height: 4.8rem;
    font-size: 1.6rem;
    padding: 1.6rem 3.2rem;
  }

  .add-portfolio span {
    font-size: 1.5rem;
  }

  .user-file-upload-label {
    height: 30rem;
    font-size: 1.6rem;
  }

  .user-file-upload-label img {
    height: 6rem;
    width: auto;
    margin-top: 5rem;
    margin-bottom: 2.4rem;
  }

  .upload-picture {
    font-size: 1.6rem;
    padding: 1.3rem 3.6rem;
  }
}

@media only screen and (max-width: 56.25em) {
  .upload-profile {
    padding: 5.6rem 7.2rem;
  }

  .skill-dropdown-content {
    width: calc(100% - 7.2rem - 7.2rem - 4.6rem - 7.2rem);
  }

  .upload-profile_input {
    padding-top: 5.2rem;
    padding-left: 4.6rem;
    padding-right: 7.2rem;
    padding-bottom: 10rem;
  }

  /* .skill-dropdown-content {
    width: calc(100% - 10.2rem - 4.6rem);
  } */
}

@media only screen and (max-width: 43.75em) {
  .email-notification {
    height: 9.6rem;
  }

  .welcome-user {
    font-size: 2.8rem;
  }

  .user-file-upload-label {
    height: 24rem;
    font-size: 1.5rem;
  }

  .user-file-upload-label img {
    height: 4rem;
    width: auto;
    margin-top: 3.2rem;
    margin-bottom: 2rem;
  }

  .upload-picture {
    font-size: 1.5rem;
    padding: 1.3rem 3.6rem;
  }
}

@media only screen and (max-width: 40.5em) {
  .upload-profile {
    padding: 4.6rem 2.3rem;
  }

  .upload-profile_input {
    padding-bottom: 6.2rem;
    padding-right: 2.4rem;
    padding-left: 2.4rem;
    padding-top: 3.2rem;
  }

  .skill-dropdown-content {
    width: calc(100% - 2.3rem - 2.3rem - 2.4rem - 2.4rem);
  }
  .profile-selected-file{
    max-width: max-content;
    min-width: 100%;
  }
  .user-delete{
    max-width: max-content;
  }
}

@media only screen and (max-width: 23.5em) {
  .email-notification {
    padding: 1.8rem 2.4rem;
  }

  .welcome-user {
    font-size: 2.2rem;
  }

  .complete-notification {
    font-size: 1.4rem;
  }

  .upload-beats_label {
    font-size: 1.5rem;
  }

  .upload-beats-input {
    padding: 1rem 2rem;
    font-size: 1.4rem;
  }
}
