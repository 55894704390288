.not-found {
  display: flex;
  align-items: center;
  margin-left: 5rem;
  height: 70vh;
}
.not-found h2 {
  font-size: 3rem;
  font-weight: 700;
}
.not-found h3 {
  font-size: 2.5rem;
  font-weight: 600;
}
.not-found p {
  font-size: 2rem;
  font-weight: 500;
}
.buttons__failure {
  display: flex;
  justify-content: center;
  gap: 3rem;
}
@media only screen and (max-width: 37.5em) {
  .not-found{
    flex-direction: column;
    margin-left: 0;
  }
  .not-found-image {
    width: -webkit-fill-available;
  }
  .not-found-image img {
    width: -webkit-fill-available;
  }
}
