.contract {
  padding: 5.1% 10.1%;
  background-color: #f6f7f9;
}

.contract-heading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 2.8%;
}

.contract-heading h2 {
  color: #131313;
  font-size: 2.167rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  width: 67%;
}

.contract-heading h2 img {
  margin-left: auto;
}

.contract-heading h3 {
  color: #333333;
  font-size: 1.5rem;
  font-weight: 400;
}

.contract-container {
  display: flex;
}

.contract-form {
  background-color: #ffffff;
  width: 67%;
  margin-right: 2.5%;
  padding: 3.7% 4.8% 4.1% 4%;
  border-radius: 8px;
}

.contract-form-input-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 7%;
}

.contract-form-input-div label {
  font-size: 1.6rem;
  font-weight: 400;
  color: #636466;
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 1.3rem;
}

.contract-form-input-div label img {
  margin-left: auto;
  width: 3%;
  cursor: pointer;
}

.contract-form-input-div input {
  border-radius: 4px;
  outline: 1px solid #636466;
  border: none;
  height: 5.5rem;
  width: 100%;
  padding: 1.3rem 2rem;
  font-size: 1.5rem;
  color: #262626;
}

.contract-form-input-div textarea {
  border-radius: 4px;
  outline: 1px solid #636466;
  border: none;
  height: 12.8rem;
  width: 100%;
  padding: 1.3rem 2rem;
  font-size: 1.5rem;
  color: #262626;
}

.contract-form-input-div > div {
  padding-right: 2.95rem;
  appearance: none;
  -webkit-appearance: none;
  color: #333333;
  font-size: 1.9rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 4px;
  outline: 1px solid #707070;
  border: none;
  height: 6rem;
  width: 100%;
  padding: 1.3rem 2rem;
  font-size: 1.5rem;
  color: #333333;
}

.contract-form-input-div > div > img {
  height: 100%;
  aspect-ratio: 1.3;
  margin-left: auto;
}

.checkbox-container a {
  color: #f55446;
}

.contract-hire-button {
  width: 17%;
  background-color: #f55446;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.75rem;
  aspect-ratio: 4.1;
  margin-left: calc(67% - 17%);
  margin-top: 3.3%;
  cursor: pointer;
  border-radius: 4px;
  white-space: nowrap;
}

.contract-producer-details {
  background-color: white;
  width: 30.5%;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  padding: 1.9%;
  border-radius: 6px;
  height: fit-content;
}

.contract-producer-details__img {
  width: 45%;
  aspect-ratio: 1;
  object-fit: contain;
  border-radius: 50%;
  margin-bottom: 4%;
}

.contract-producer-details__img img {
  width: 100%;
  aspect-ratio: 1;
  border-radius: 50%;
  object-fit: cover;
}

.contract-producer-details__details {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contract-producer-details h2 {
  font-size: 1.92rem;
  font-weight: 500;
  margin-bottom: 3%;
}

.contract-producer-details p {
  font-size: 1.5rem;
  text-align: center;
}

.term-error {
  margin-left: auto;
  width: 3%;
  cursor: pointer;
}

.genre-contract-dropdown-content {
  margin: auto;
  background-color: #ffffff;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 7px;
  color: black;
  font-size: 1.9rem;
  margin-top: 1rem;
  height: fit-content;
  padding: 0.9rem 3rem;
  display: flex;
  flex-direction: column;
  width: 46.5%;
  z-index: 997 !important;
}

@media only screen and (max-width: 68.75em) {
  .contract {
    padding: 5%;
  }

  .contract-hire-button {
    width: 20%;
    margin-left: calc(67% - 20%);
  }

  .contract-form-input-div label img {
    width: 4%;
  }
}

@media only screen and (max-width: 56.25em) {
  .contract-hire-button {
    width: 25%;
    margin-left: calc(67% - 25%);
  }

  .contract-form-input-div label img {
    width: 5%;
  }
}

@media only screen and (max-width: 43.75em) {
  .contract-container {
    flex-direction: column;
  }

  .contract-form {
    order: 2;
  }

  .contract-form,
  .contract-producer-details {
    width: 100%;
  }

  .contract-producer-details {
    flex-direction: row;
    padding: 4%;
    padding-bottom: 0;
  }

  .contract-producer-details__details {
    align-items: flex-start;
    margin-left: 4%;
  }

  .contract-producer-details__img {
    width: 17%;
    margin-bottom: 0;
  }

  .contract-producer-details p {
    text-align: left;
  }

  .contract-hire-button {
    margin-left: auto;
    width: fit-content;
    padding: 1.5% 3%;
  }

  .contract-form-input-div label img {
    width: 6%;
  }
}

@media only screen and (max-width: 31.25em) {
  .contract-producer-details__img {
    width: 20%;
  }

  .contract-form-input-div label img {
    width: 7%;
  }
}

@media only screen and (max-width: 21.875em) {
  .contract-producer-details__img {
    width: 35%;
  }

  .contract-form-input-div label img {
    width: 8%;
  }
}
