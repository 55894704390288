.accounts-section {
  background-color: #f6f7f9;
  display: flex;
  flex-direction: column;
  padding: 3%;
  justify-content: flex-start;
  align-items: center;
  min-height: 70vh;
}
.accounts-heading {
  color: #131313;
  font-size: 3rem;
  font-weight: 600;
  margin-bottom: 2%;
  align-self: flex-start;
  margin-left: 5%;
}
.accounts-div {
  width: 90%;
  background-color: #ffffff;
  border: 1px solid #d4d4d4;
  border-radius: 8px;
  padding: 2% 3%;
}
.accounts-top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5%;
}
.accounts-top h3 {
  color: #333333;
  font-size: 2.2rem;
  font-weight: normal;
}
.accounts-items {
  display: flex;
  flex-direction: column;
}
.accounts-item {
  display: flex;
  align-items: center;
  margin-bottom: 3%;
  justify-content: space-between;
  padding: 2% 1%;
  /* cursor: pointer; */
}
/* .accounts-item:hover {
  background-color: #f5f5f5;
} */
.account-add {
  border: none;
  border-radius: 8px;
  background-color: #fe2b0d;
  color: #ffffff;
  padding: 1% 3%;
  font-size: 1.8rem;
  display: flex;
  align-items: center;
  gap: 5%;
  white-space: nowrap;
  cursor: pointer;
}
.accounts-item div {
  display: flex;
}
.accounts-item p {
  color: #333333;
  font-size: 1.9rem;
  margin-right: 1%;
  width: 3%;
}
.accounts-item img {
  width: 12%;
  margin-right: 3%;
}
.accounts-item h3 {
  color: #333333;
  font-size: 1.7rem;
  font-weight: 500;
}
.account-options {
  align-self: flex-end;
  width: 40%;
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: flex-end;
}
/* .bank_image{
    width: 25% !important;
} */

.account-delete,
.account-withdraw {
  background-color: #262626;
  border: none;
  border-radius: 5px;
  color: #fff;
  font-size: 1.8rem;
  cursor: pointer;
  padding: 2% 4%;
  white-space: nowrap;
  align-self: flex-end;
  display: flex;
}
.account-withdraw {
  background-color: #fe2b0d;
}

.accounts-item-details {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 60%;
}
@media only screen and (max-width: 37.5em) {
  .account-add img {
    display: none;
  }
  .account-add {
    justify-content: center;
    padding: 3% 6%;
  }
  .accounts-item {
    flex-direction: column;
  }
  .accounts-item-details {
    flex-direction: column;
    width: 100%;
  }
  .accounts-item p {
    display: none;
  }
  .accounts-item img {
    width: 32%;
    margin-right: 0%;
    margin-bottom: 2%;
  }
  .account-options {
    align-self: auto;
    width: auto;
    margin-top: 3%;
  }
  .account-options {
    width: 70%;
    justify-content: center;
  }
  /* .account-options button {
    width: 50%;
  } */
  .accounts-item h3 {
    width: 100%;
    text-align: center;
  }
}
