.withdraw-section {
  background-color: #f6f7f9;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 8rem);
}
.withdraw-top-heading {
  display: flex;
  flex-direction: row;
  width: 60%;
  margin-top: 3%;
  margin-bottom: 2%;
}
.withdraw-top-heading h3 {
  color: #262626;
  font-size: 2.1rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  width: 100%;
}

.withdraw-top-heading h3 img {
  margin-left: auto;
}

.withdraw-main {
  display: flex;
  flex-direction: column;
  width: 60%;
  background-color: #ffffff;
  border-radius: 10px;
  /* margin-bottom: 5%; */
  margin-bottom: auto;
}
.withdraw-main-heading {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 3% 5%;
  border-bottom: 1px solid #d4d4d4;
}
.withdraw-main-heading h3 {
  font-size: 2.1rem;
  font-weight: 600;
  color: #262626;
}
.withdraw-main-heading img {
  width: 10%;
}
.withdraw-form {
  display: flex;
  flex-direction: column;
  padding: 3% 5%;
  border-bottom: 1px solid #d4d4d4;
}
.withdraw-form input,
.withdraw-form .withdrawal-bank > div {
  font-size: 1.7rem;
  letter-spacing: 0.3px;
  border-radius: 4px;
  outline: 1px solid #d4d4d4;
  border: none;

  width: 100%;
  padding: 2% 3%;
  margin-top: 1%;
}
.withdraw-form > div {
  margin-bottom: 4%;
  display: flex;
  flex-direction: column;
}
.withdraw-form > div {
  position: relative;
}
.withdraw-form img {
  padding: 1rem;
  width: 4.25rem;
  position: absolute;
  right: 1rem;
  cursor: pointer;
  top: 5.2rem;
}
.withdraw-form p {
  color: #646363;
  font-size: 1.9rem;
  text-transform: capitalize;
}
.withdraw-button button {
  background-color: #131313;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 1.7rem;
  color: #ffffff;
  padding: 2% 3%;
  display: flex;
  justify-content: center;
  opacity: 1;
}
.withdraw-button button:disabled {
  background-color: #131313;
  cursor: not-allowed;
  opacity: 0.5;
}
.button-disabled {
  background-color: #131313 !important;
  cursor: not-allowed !important;
  opacity: 0.5 !important;
}

.withdraw-button button div {
  margin-bottom: 0;
}
.withdraw-guidelines {
  display: flex;
  flex-direction: row;
  margin: 3% 5%;
}
.withdraw-guidelines h3 {
  font-size: 1.9rem;
  color: #262626;
  font-weight: 600;
  margin-bottom: 2%;
}
.withdraw-guidelines span {
  font-size: 1.9rem;
  color: #707070;
  font-weight: 500;
  margin-left: 1%;
}
.withdraw-guidelines p {
  font-size: 1.9rem;
  color: #707070;
  font-weight: 500;
  margin-top: -2%;
}
.border-div {
  background-color: #d35644;
  width: 5px;
  margin-right: 1%;
  border-radius: 8px;
}

@media only screen and (max-width: 56.25em) {
  .withdraw-main {
    width: 90%;
  }
  .withdraw-top-heading {
    width: 90%;
  }
}

.bank-dropdown-content {
  margin: auto;
  background-color: #ffffff;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 7px;
  color: black;
  font-size: 1.9rem;
  margin-top: 1rem;
  height: fit-content;
  padding: 0.9rem 3rem;
  display: flex;
  flex-direction: column;
  width: calc(60% - 6%);
  z-index: 997 !important;
  max-height: 20rem;
  overflow-y: auto;
}

.bank-dropdown-content .service-item label {
  cursor: pointer;
}

.withdrawal-bank .withdrawal-name-input {
  cursor: pointer;
}

.withdrawal-bank p img {
  top: 0;
  right: 0;
}

.withdraw-main .loading {
  margin: 10rem auto;
}
