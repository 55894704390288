.terms {
  padding: 4.5% 14.8% 16% 14.5%;
}

.terms h2 {
  font-size: 3.33rem;
  width: 100%;
  font-weight: 500;
  padding-bottom: 0.5%;
  border-bottom: 3px solid #333333;
  margin-bottom: 7%;
}

.term-container {
  display: flex;
  font-size: 1.58rem;
  line-height: 3rem;
  color: #707070;
}

.terms > .term-container {
  margin-bottom: 1%;
}

.term-container p {
  margin-left: 1%;
  width: 97%;
}

.term-bold {
  font-weight: 500;
  color: #606060;
}

.term-roman {
  width: 3%;
  text-align: center;
}

.terms h3 {
  font-size: 2rem;
  color: #707070;
  font-weight: 600;
  margin-top: 5%;
  margin-bottom: 2%;
}

.terms h4 {
  font-size: 2rem;
  color: #707070;
  font-weight: 600;
  margin-top: 3%;
  margin-bottom: 0.7%;
}

.terms-placeholder {
  margin-top: 10%;
}

.terms-email {
  color: #fe2b0d;
  text-decoration: none;
}
.term-container p a{
  color: blue;
}
@media only screen and (max-width: 68.75em) {
  .terms {
    padding-left: 10.5%;
    padding-right: 10.5%;
  }
}

@media only screen and (max-width: 56.25em) {
  .terms {
    padding-left: 9.5%;
    padding-right: 9.5%;
  }
}

@media only screen and (max-width: 43.75em) {
  .terms {
    padding-left: 8.5%;
    padding-right: 8.5%;
  }
}

@media only screen and (max-width: 31.25em) {
  .terms {
    padding-left: 7.5%;
    padding-right: 7.5%;
  }
}
