.producer-tracks-section {
  display: flex;
  width: 90%;
  margin-top: 3.5%;
  margin-left: 7%;
  margin-right: 7%;
  margin-bottom: 11.5%;
  flex-direction: column;
}
.producer-tracks-heading{
    color: #131313;
    font-size: 2.5rem;
    margin-bottom:4.5%;
    font-weight: normal;
}