.beatsBid {
  background-color: #f6f7f9;
}

.bids-topbar {
  background-color: #ffffff;
  padding: 1% 6.8% 0 7.5%;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #d4d4d4;
}

.bids-display-options {
  display: flex;
  align-items: center;
}

.bids-display-options button {
  padding: 1% 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.75rem;
  background-color: transparent;
  border: 1px solid #ffffff;
  margin-right: 2.5%;

  color: black;
  cursor: pointer;
}

.bids-button-selected {
  border: none !important;
  border-bottom: 2px solid #f55446 !important;
  color: #f55446 !important;
}

.beatsBid-seller,
.beatsBid-buyer {
  padding: 2.5% 6.8% 26% 7.5%;
  min-height: 50vh;
}

/* .beatsBid-buyer {
  padding: 2.5% 10.6% 16% 13.5%;
} */

.beatsBid-seller h2,
.beatsBid-buyer h2 {
  font-size: 2.7rem;
  font-weight: 500;
  letter-spacing: 0.7px;
}

.bids-container-seller,
.bids-container-buyer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: white;
  border: 1px solid #d4d4d4;
  border-radius: 12px;
  padding: 2% 3.5%;
  margin-top: 1.5%;
}

.bids-container-row {
  display: flex;
  width: 100%;
  font-size: 1.58rem;
  border-bottom: 1px solid #d4d4d4;
  padding: 1.5% 0.7%;
  text-align: left;
}

.bids-container-row-header {
  padding-bottom: 1%;
  font-size: 1.75rem;
  font-weight: 500;
}

.bids-container-row:last-of-type {
  border-bottom: none;
}

.bids-container-seller .bids-container-row__date {
  width: 13.97%;
}

.bids-container-seller .bids-container-row__name {
  width: 21.86%;
}

.bids-container-seller .bids-container-row__amount {
  width: 14.67%;
}

.bids-container-seller .bids-container-row__status {
  width: 11.54%;
}

.bids-container-seller .bids-container-row__action {
  width: 37.96%;
}

.bids-container-row div {
  display: flex;
  align-items: center;
  /* justify-content: center; */
}

.bids-container-row__action button {
  border: none;
  padding: 0;
  margin: 0;
  font-size: 1.2rem;
  letter-spacing: 0.4px;
  color: white;
  cursor: pointer;
  outline: none;
  width: 100%;
  margin-right: 3%;
  aspect-ratio: 3.28;
  border-radius: 5px;
  white-space: nowrap;
}

.bids-container-row__action button:last-of-type {
  margin-right: 0;
}

.bids-container-row__action-counter {
  background-color: #0064fe;
}

.bids-container-row__action-accept {
  background-color: #2c7919;
}

.bids-container-row__action-reject {
  background-color: #262626;
}

.bids-container-row__action-disabled button {
  opacity: 30%;
  cursor: default;
}

.counter-offer-popup-overlay {
  background-color: rgba(0, 0, 0, 0.8);
}

.counter-offer-popup-content {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border: 1px solid #707070;
  color: #333333;
  border-radius: 6px;
  width: 33%;
}

.counter-offer-popup__close {
  position: absolute;
  top: -2.5%;
  right: -8%;
  width: 5%;
  aspect-ratio: 1;
  object-fit: contain;
  cursor: pointer;
}

.counter-offer-popup-content h3 {
  font-size: 2rem;
  font-weight: 400;
  padding: 4.5% 0% 2.5% 4%;
  border-bottom: 1px solid #d4d4d4;
  width: 100%;
}

.counter-offer-popup-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 9.5% 4%;
}

.counter-offer-popup-container input {
  border: 1px solid #636466 !important;
  padding: 3.7%;
  border-radius: 8px;
  width: 100%;
  font-size: 2.17rem;
  color: #636466;
  outline: none;
}

.counter-offer-popup-container .button-container {
  width: 100%;
  margin-top: 4.6%;
  margin-bottom: 7%;
  display: flex;
  align-items: center;
}

.counter-offer-popup-container .button-container button {
  padding: 0;
  font-size: 1.5rem;
  cursor: pointer;
  outline: none;
  border: none;
}

.button-container__cancel {
  margin-left: auto;
  margin-right: 5.5%;
  color: #333333;
  background-color: transparent;
}

.button-container__submit {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  aspect-ratio: 2.87;
  width: 31.5%;
  color: white;
  background-color: #0064fe;
  outline: none;
  border: none;
  cursor: pointer;
}

.button-container__submit img {
  width: 14%;
  margin-right: 6%;
  aspect-ratio: 1;
  object-fit: contain;
}

.offer-confirmation {
  width: 34%;
  aspect-ratio: 1;
  margin-top: 5%;
  margin-bottom: 8%;
}

.counter-offer-popup-container h4 {
  font-size: 1.83rem;
  font-weight: 400;
  text-align: left;
}

.place-bid-popup-overlay {
  background-color: rgba(0, 0, 0, 0.8);
}

.place-bid-popup-content {
  width: 33.5%;
}

.place-bid-popup-content > div {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border: 1px solid #707070;
  color: #333333;
  border-radius: 6px;
  /* width: 33%; */
}

.place-bid-popup-container h3 {
  font-size: 1.75rem;
  font-weight: 400;
  padding: 4.5% 0% 2.5% 4%;
  border-bottom: 1px solid #d4d4d4;
  color: #333333;
  width: 100%;
}

.place-bid-popup-heading-container {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
}

.place-bid-popup-heading-container img {
  position: absolute;
  width: 5%;
  aspect-ratio: 1;
  right: 3%;
  margin: 4.5% 0% 2.5% 0%;
  cursor: pointer;
}

.place-bid-popup-container input {
  margin: 0 4%;
  margin-top: 9%;
  border: 1px solid #636466 !important;
  padding: 3.7%;
  padding-left: 4.5%;
  border-radius: 8px;
  font-size: 2.17rem;
  color: #636466;
  outline: none;
}

.place-bid-popup-container .button-container {
  margin: 0 4%;
  margin-top: 4.6%;
  margin-bottom: 16%;
  display: flex;
  align-items: center;
}

.place-bid-popup-container .button-container button {
  padding: 0;
  font-size: 1.5rem;
  cursor: pointer;
  outline: none;
  border: none;
}

.place-bid-popup-info {
  margin-top: 4%;
  padding: 4%;
}

.place-bid-popup-info h5 {
  display: flex;
  align-items: center;
  font-size: 1.6rem;
  font-weight: 500;
  letter-spacing: 0.4px;
  color: #333333;
  margin-bottom: 2.6%;
}

.place-bid-popup-info h5 img {
  width: 4.1%;
  aspect-ratio: 1;
  margin-right: 1%;
}

.place-bid-popup-info p {
  font-size: 1.5rem;
  color: #666666;
  letter-spacing: 0.5px;
}

.place-bid-popup-info p:first-of-type {
  margin-bottom: 2.6%;
}

.bids-container-buyer .bids-container-row__date {
  width: 15.3%;
}

.bids-container-buyer .bids-container-row__name {
  width: 21.7%;
}

.bids-container-buyer .bids-container-row__amount {
  width: 13.6%;
}

.bids-container-buyer .bids-container-row__status {
  width: 11.2%;
}

.bids-container-buyer .bids-container-row__action {
  width: 38.2%;
  /* margin: -10% 0; */
}

.bids-container-buyer .bids-container-row {
  padding: 2.5% 0.7%;
}

.bids-container-buyer .bids-container-row-header {
  padding: 1.5% 0.7%;
  padding-bottom: 1%;
}

.bids-container-row__action-addToCart {
  background-color: #fe2b0d;
  font-size: 1.5rem !important;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50% !important;
  aspect-ratio: 4 !important;
}

.bids-container-buyer .bids-container-row__action:last-of-type {
  justify-content: center;
}

.bids-container-row__action-counters,
.bids-container-row__action-addToCart {
  margin-top: -9% !important;
  margin-bottom: -9% !important;
}

.bids-container-row__action-addToCart img {
  width: 9%;
  aspect-ratio: 1;
  margin-right: 5%;
}

.empty-bids {
  font-size: 2.6rem;
  font-weight: 400;
  margin: 0 auto;
  text-align: center;
}
@media only screen and (max-width: 37.5em) {
  .place-bid-popup-content {
    width: 80.5%;
  }
  .button-container__submit {
    width: 39.5%;
  }
  .counter-offer-popup-content {
    width: 80%;
  }
}
@media only screen and (max-width: 37.5em) {
  .bids-container-row-header {
    display: none;
  }
  .bids-container-row {
    flex-direction: column;
    align-items: center;
  }
  .bids-container-seller .bids-container-row__date {
    width: auto;
    margin-bottom: 2%;
    text-align: center;
    white-space: nowrap;
  }
  .bids-container-seller .bids-container-row__name {
    width: auto;
    margin-bottom: 2%;
    text-align: center;
    white-space: nowrap;
  }
  .bids-container-seller .bids-container-row__amount {
    width: auto;
    margin-bottom: 2%;
    text-align: center;
    white-space: nowrap;
  }
  .bids-container-seller .bids-container-row__status {
    width: auto;
    margin-bottom: 2%;
    text-align: center;
    white-space: nowrap;
  }
  .bids-container-seller .bids-container-row__action {
    width: 100%;
    margin-bottom: 2%;
  }
  .bids-container-buyer .bids-container-row__status {
    width: auto;
    margin-bottom: 2%;
    text-align: center;
    white-space: nowrap;
  }
  .bids-container-buyer .bids-container-row__date {
    width: auto;
    margin-bottom: 2%;
    text-align: center;
    white-space: nowrap;
  }
  .bids-container-buyer .bids-container-row__name {
    width: auto;
    margin-bottom: 2%;
    text-align: center;
    white-space: nowrap;
  }
  .bids-container-buyer .bids-container-row__amount {
    width: auto;
    margin-bottom: 2%;
    text-align: center;
    white-space: nowrap;
  }
  .bids-container-buyer .bids-container-row__action {
    width: auto;
  }
  .bids-container-row__action button {
    margin-top: 2% !important;
    margin-bottom: 2% !important;
  }
}
@media only screen and (max-width: 56.25em) {
  .bids-container-row-header {
    display: none;
  }
  .bids-container-row {
    flex-direction: column;
    align-items: center;
  }
  .bids-container-seller .bids-container-row__date {
    width: auto;
    margin-bottom: 2%;
    text-align: center;
    white-space: nowrap;
  }
  .bids-container-seller .bids-container-row__name {
    width: auto;
    margin-bottom: 2%;
    text-align: center;
    white-space: nowrap;
  }
  .bids-container-seller .bids-container-row__amount {
    width: auto;
    margin-bottom: 2%;
    text-align: center;
    white-space: nowrap;
  }
  .bids-container-seller .bids-container-row__status {
    width: auto;
    margin-bottom: 2%;
    text-align: center;
    white-space: nowrap;
  }
  .bids-container-seller .bids-container-row__action {
    width: 100%;
    margin-bottom: 2%;
  }
  .bids-container-buyer .bids-container-row__status {
    width: auto;
    margin-bottom: 2%;
    text-align: center;
    white-space: nowrap;
  }
  .bids-container-buyer .bids-container-row__date {
    width: auto;
    margin-bottom: 2%;
    text-align: center;
    white-space: nowrap;
  }
  .bids-container-buyer .bids-container-row__name {
    width: auto;
    margin-bottom: 2%;
    text-align: center;
    white-space: nowrap;
  }
  .bids-container-buyer .bids-container-row__amount {
    width: auto;
    margin-bottom: 2%;
    text-align: center;
    white-space: nowrap;
  }
  .bids-container-buyer .bids-container-row__action {
    width: 48%;
  }
  .bids-container-row__action button {
    margin-top: 2% !important;
    margin-bottom: 2% !important;
  }
}

@media only screen and (max-width: 43.75em) {
  .bids-container-buyer .bids-container-row__action {
    width: 56%;
  }
}
@media only screen and (max-width: 37.5em) {
  .bids-container-buyer .bids-container-row__action {
    width: 66%;
  }
}
@media only screen and (max-width: 31.25em) {
  .bids-container-buyer .bids-container-row__action {
    flex-direction: column;
    width: 41%;
  }

  .bids-container-buyer .bids-container-row__action button {
    margin-right: 0;
    width: 90% !important;
    aspect-ratio: 4 !important;
  }

  .bids-container-row__action-addToCart {
    aspect-ratio: 3.5 !important;
  }
}
@media only screen and (max-width: 21.875em) {
  .bids-container-buyer .bids-container-row__action {
    width: 55%;
  }
}
