.loading-section {
  background-color: #f6f7f9;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loading-div {
  /* width: 40%; */
  max-width: 85%;
  display: flex;
  flex-direction: column;
  margin-top: 3%;
  align-items: center;
  border: 1px solid #d4d4d4;
  border-radius: 8px;
  background-color: #ffffff;
  margin-bottom: 5%;
  padding: 2% 5%;
}
.loading-image-div {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5%;
}
.loading-image-div img {
  width: 20%;
}
.loading-section .loading {
  margin-top: 5%;
  margin-bottom: 10%;
}
.loading-description h3 {
  color: #333333;
  font-size: 2rem;
  font-weight: 600;
  white-space: nowrap;
  margin-bottom: 5%;
}

.too-many-requests-description {
  text-align: center;
}
.too-many-requests-description h4 {
  color: #333333;
  font-size: 1.5rem;
  font-weight: 600;
  white-space: nowrap;
  margin-bottom: 5%;
  margin-top: -2%;
}

@media only screen and (max-width: 62.5em) {
  .loading-description h3 {
    white-space: normal;
    text-align: center;
  }
}

@media only screen and (max-width: 50em) {
  .loading-div {
    padding: 2% 4%;
  }
}

@media only screen and (max-width: 37.5em) {
  .loading-div {
    max-width: 90%;
  }
}

@media only screen and (max-width: 31.25em) {
}

@media only screen and (max-width: 21.875em) {
}
