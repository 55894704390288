.navbar {
  background-color: #0d0d0d;
  width: 100%;
  /* height: 12.1%; */
  height: 8rem;
  display: flex;
  align-items: center;
  /* justify-content: space-around; */
  padding: 0 7.5%;
  z-index: 998;
  color: var(--color-white-1);
  position: sticky;
  top: 0;
  justify-content: space-between;
  gap: 5rem;
}

a {
  color: #fff;
  text-decoration: none;
}
.navbar-logo {
  cursor: pointer;
}
.nav_left {
  display: flex;
  align-items: center;
  height: 100%;
  gap: 2rem;
  /* width: 50%; */
  /* margin-left: 7.5%; */
}
.nav_right {
  display: flex;
  align-items: center;
  height: 100%;
  margin-left: auto;
  font-size: 1.7rem;
  gap: 2rem;
}

.nav_right a {
  color: inherit;
  text-decoration: none;
}

.vertical-divider {
  border-right: 2px solid #b4b4b4;
  height: 50%;
  margin: 0 2rem;
}

.navbar-bg {
  position: absolute;
  height: 10rem;
  background-color: rgb(13, 13, 13, 0.5);
  width: 100%;
  z-index: -1;
}

.logo-navbar {
  display: flex;
}
/* 
.navbar_btn {
  width: 9.3rem;
  height: 3.6rem;
  font-size: 1.4rem;
  color: #fff;
  display: inline-flex;
  font-weight: 400;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  font-family: "Poppins", sans-serif;
} */

.navbar_upload-btn {
  background-color: #fe2b0d;
  border-radius: 4px;
  border: none;
  font-size: inherit;
  font-family: inherit;
  color: var(--color-white-1);
  cursor: pointer;
  /* padding: 12.6% 46%; */
  /* padding: 16.6% 60.66%; */
  /* width: 45.3%; */
  /* height: 4.1rem; */
  /* aspect-ratio: 3.2; */
  padding: 1.5% 3rem;
}

.nav-search--container {
  display: flex;
  align-items: center;
  /* margin-left: 4.2%; */
  background-color: hwb(0 25% 75%);
  border-radius: 4px;
  overflow: hidden;
  border: 1px solid #cdcccc;
  /* width: 57%; */
  height: 53%;
}

.nav-search {
  /* width: 100%; */
  /* width: 50.7%; */
  /* padding-left: 2.8%; */
  width: calc(100% - 4.5rem - 11rem);
  height: 58%;
  outline: none;
  border: none;
  background-color: #403f3f;
  font-size: 1.4rem;
  color: #cbcbcb;
}

.nav-search::placeholder {
  color: #cbcbcb;
}

.navbar-search-category-popup-arrow {
  color: #403f3f;
}
.country-popup-content {
  width: 22rem;
  margin-left: 0 !important;
}

.country__selector {
  background-color: transparent;
  height: 31%;
  max-height: 31%;
  display: flex;
  align-items: center;
  outline: transparent;
  border: none;
  cursor: pointer;
}
.country__selector img {
  height: 100%;
  width: 3rem;
}
.country__options {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 2%;
  padding-bottom: 2%;
  padding-top: 5%;
  align-items: center;
}
.country__options button {
  width: 92%;
  padding: 2%;
  border: none;
  margin-bottom: 3%;
  border-radius: 6px;
  background-color: #403f3f;
  color: #cbcbcb;
  font-size: 2rem;
  cursor: pointer;
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.currency_selected {
  background-color: #f55446 !important;
}
.country__options button img {
  width: 2.5rem;
  height: 2.5rem;
  margin-right: 3%;
}

.navbar-search-category-popup-content {
  top: 7.5rem !important;
  position: fixed !important;
  background-color: #403f3f;
  box-shadow: 0px 3px 6px #00000029;
  padding: 0.5%;
  border-radius: 10px;
  font-size: 1.3rem;
  color: #cbcbcb;
  width: 9%;
}

.navbar-search-category-popup-content .search-category__option {
  margin: 5% 0;
  text-align: center;
  padding: 10%;
  border-radius: 7px;
  transition: 0.2s ease-in-out;
}

.navbar-search-category-popup-content .search-category__option:hover {
  background-color: rgba(13, 13, 13, 0.5);
  cursor: pointer;
}

.navbar-search-category-popup-content .search-category__option-selected {
  background-color: rgba(13, 13, 13, 0.5);
}

.search-container--left {
  /* padding-left: 3.4%;
  padding-right: 3.4%; */
  /* height: 41%; */
  margin: 0 1.5rem;
  color: #444;
  cursor: pointer;
  width: 1.5rem;
  aspect-ratio: 1;
  cursor: pointer;
}
.search-container--right {
  /* padding-right: 2.3%; */
  /* width: 33%;
  min-width: 30%;
  max-width: 25%;
  margin-left: auto; */
  width: 11rem;

  position: relative;
  cursor: pointer;
  background-color: #403f3f;
  border: none;
  border-left: 2px solid rgb(205, 204, 204, 0.45);
  font-family: "Poppins", sans-serif;
  font-size: 1.4rem;
  font-weight: 300;
  color: #cbcbcb;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.navbar-search-error {
  position: absolute;
  left: -3rem;
  width: 2rem;
  aspect-ratio: 1;
  /* margin-right: 1.5%; */
  cursor: pointer;
}

.searchArrowDown {
  height: 8px;
}
/* 
.login {
  display: flex;
  width: 43.3%;
  margin-right: 6.7%;
} */
.navbar_cta-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3.6rem;
  padding-left: 2rem;
  border-left: 1px solid rgba(205, 203, 203, 0.3);
}

.navbar_cta-btn-img {
  height: 100%;
}

.links_and_cta {
  display: flex;
  justify-content: center;
}
.opened {
  display: flex;
}

/* .navbar-popup {
  right: 1rem;
} */

.navbar-popup-content {
  background-color: #161616;
  margin-top: 2rem;
  flex-direction: column;
  color: white;
  font-family: "Poppins", sans-serif;
  border-radius: 11px;
  top: 5rem !important;
  position: fixed !important;
  margin-left: 1%;
}

.navbar-popup-arrow {
  margin-top: -1.3rem;
  color: #161616;
  width: 3rem;
  height: 3rem;
}

.shopping-popup-content,
.notification-popup-content {
  width: 34%;
}

.shopping-popup-arrow,
.notification-popup-arrow {
  left: -2rem;
}

.shopping-cart {
  padding: 4.3% 5.6%;
  padding-bottom: 6.43%;
}

.shopping-cart h2 {
  margin-bottom: 3.1rem;
  font-size: 2rem;
  font-weight: 500;
  letter-spacing: 0.69px;
  font-family: "Poppins", sans-serif;
}

.cart-items {
  max-height: 35rem;
  overflow-y: auto;
  scrollbar-width: 5px;
  scrollbar-color: white #161616;
  margin: 1% 0;
}

.cart-items::-webkit-scrollbar {
  width: 0.35rem;
  border-radius: 10px;
}

.cart-items::-webkit-scrollbar-thumb {
  background-color: #ffffff;
}

.cart-items::-webkit-scrollbar-track {
  background-color: #161616;
}

.cart--item {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 3.6rem;
}
.cart--item h3 {
  font-size: 1.4rem;
  font-weight: 400;
  letter-spacing: 0.5px;
  margin-left: 6%;
}
.cart--item button {
  border: none;
  background-color: transparent !important;
  cursor: pointer;
  margin: 0 2%;
}
.shopping {
  background-color: transparent;
  border: none;
  cursor: pointer;
}
.shopping__mobile {
  background-color: transparent;
  border: none;
  cursor: pointer;
}
.cart--item p {
  font-size: 1.35rem;
  width: 10rem;
  height: 4rem;
  background-color: #262626;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  letter-spacing: 0.45px;
  margin-left: auto;
}
.cart-buttons {
  display: flex;
  width: 100%;
}
.cart-buttons .view {
  margin-right: 1.35rem;
}

.cart-buttons button {
  width: 100%;
  aspect-ratio: 3.9;
  /* height: 6.3rem; */
  border: none;
  font-size: 2rem;
  color: #fff;
  letter-spacing: 0.66px;
  cursor: pointer;
}
.view {
  background-color: #707070;
}
.checkout {
  background-color: #f55446;
}
.beat-logo {
  width: 7rem;
  height: 6rem;
  aspect-ratio: 1;
  object-fit: cover;
  border-radius: 5px;
}
.cross {
  width: 2rem;
}
.nav__button {
  background-color: transparent;
  border: none;
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 31%;
  position: relative;
}
.nav__button__login {
  background-color: transparent;
  border: none;
  height: 28%;
  display: flex;
  align-items: center;
  /* margin-right: 6%; */
  cursor: pointer;
  position: relative;
}
.bell-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.inbox {
  width: 100%;
  padding: 2.58rem 2.9rem 2rem 1.42rem;
  border-bottom: 1px solid #707070;
}
.inbox-button {
  width: 100%;
  height: 30%;
  background-color: #333232;
  border-radius: 12px;
  border: none;
  color: #fff;
  display: flex;
  align-items: center;
  padding: 1.9rem;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  cursor: pointer;
}
.inbox-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 2.65rem;
  text-align: left;
}
.inbox-text h3 {
  font-size: 2.2rem;
  margin-bottom: 0.5rem;
}
.inbox-text p {
  color: #cbcbcb;
  font-size: 1.5rem;
}
.msg-icon {
  width: 7.4rem;
  height: 7.4rem;
  background-color: #0d0d0d;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}
.inbox-button img {
  width: 3.85rem;
}
.notifications {
  /* margin-top: 2.5rem; */
  padding: 2.25rem 2.9rem 4.19rem 4.05rem;
  max-height: 30rem;
  overflow-y: auto;
  scrollbar-width: 5px;
  scrollbar-color: white #161616;
  display: flex;
  flex-direction: column;
  /* justify-content: flex-start;
  align-items: flex-start; */
  text-align: left;
  width: 100%;
}
.notifications h3 {
  font-size: 1.8rem;
  font-weight: 400;
  color: #cbcbcb;
}
.notification--item {
  margin-top: 4rem;
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  align-self: flex-start;
  cursor: pointer;
}

.notification--item:first-of-type {
  margin-top: 3.5rem;
}

.notification--item h4 {
  font-size: 1.5rem;
  font-weight: 400;
  margin-left: 2rem;
}
.notification--item p {
  font-size: 1.3rem;
  color: #707070;
  margin-left: auto;
}

.user-popup-content {
  width: 19%;
  /* margin-left: 7rem; */
  left: auto !important;
  right: 2rem;
}

.user-popup-arrow {
  left: -9.5rem;
}

.user-div {
  max-height: 87vh;
  overflow-y: auto;
}

.user-information-div {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid #707070;
  padding: 2rem 1.8rem;
  padding-top: 1rem;
}
.user-information-div img {
  width: 22%;
  aspect-ratio: 1;
}
.user-image-div {
  display: flex;
  width: 100%;
  justify-content: left;
  align-items: center;
  margin-bottom: 1.5rem;
  margin-top: 0;
  margin-left: 10%;
  margin-right: 10%;
  cursor: pointer;
}
.user-image-div h3 {
  text-align: left;
  font-size: 1.7rem;
  font-weight: 500;
  margin-left: 1.6rem;
}
.wallet {
  cursor: pointer;
  display: flex;
  width: 100%;
  aspect-ratio: 5.5;
  display: flex;
  align-items: center;
  /* justify-content: center; */
  justify-content: space-between;
  background-color: #262626;
  border-radius: 4px;
  padding: 0 1.75rem;
}

.wallet h4 {
  font-size: 1.4rem;
  font-weight: 400;
}
.user-buttons {
  display: flex;
  flex-direction: column;
  padding: 2.3rem 3.5rem;
  border-bottom: 1px solid #707070;
  font-size: 1.5rem;
}

.user-system {
  display: flex;
  flex-direction: column;
  padding: 2.3rem 3.5rem;
}
.user-div button {
  margin-bottom: 3rem;
  background-color: transparent;
  border: none;
  color: white;
  font-size: 1.7rem;
  width: 100%;
  text-align: left;
  cursor: pointer;
  display: flex;
}

.user-buttons button:last-of-type {
  margin-bottom: 0;
}

.user-system button:last-of-type {
  margin-bottom: 0;
}

.user-div button img {
  width: 9.5%;
  aspect-ratio: 1;

  /* margin-left: 4rem; */
  margin-right: 3rem;
}

.nav__login {
  background-color: transparent;
  border: none;
  color: #fff;
  font-size: 1.8rem;
  cursor: pointer;
  white-space: nowrap;
}

.nav__register {
  background-color: #fe2b0d;
  border: none;
  border-radius: 4px;
  padding: 1.5% 2rem;
  color: #fff;
  font-size: 1.8rem;
  cursor: pointer;
}

.sidebar-toggle {
  display: none;
  cursor: pointer;
  aspect-ratio: 1;
  width: 5%;
  aspect-ratio: 1;
}

.ps-sidebar-root,
.ps-sidebar-container {
  z-index: 1000 !important;
  border: none !important;
}
.ps-sidebar-root {
  width: 13% !important;
  color: white;
  font-size: 1.5rem;
}
.ps-menu-root {
  background-color: #fe2b0d;
  width: 100%;
  border-radius: 10px;
  border-color: transparent;
  margin-bottom: 2rem;
}
.ps-open {
  border: none;
}
.first__item {
  margin-top: 2rem;
}
.ps-menu-label {
  font-size: 2rem;
}
.ps-submenu-content {
  background-color: #0a0a0a !important;
  width: 100%;
  overflow: hidden !important;
  border-color: #0a0a0a !important;
}
.ps-menuitem-root,
.ps-menu-button {
  background-color: transparent !important;
}

.ps-sidebar-container {
  padding: 7%;
  background: rgb(13, 13, 13);
  background: linear-gradient(
    0deg,
    rgba(13, 13, 13, 0.8) 0%,
    rgba(13, 13, 13, 1) 50%
  );
}

.sidebar-close-button {
  /* margin-left: auto; */
  border-radius: 10px;
  width: 20%;
  padding: 1%;
  aspect-ratio: 1;
  transition: 0.3s ease-in;
  cursor: pointer;
  object-fit: contain;
}

.sidebar-button-container {
  margin-top: 20%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.sidebar-button {
  margin: 2% 0;
  font-size: 1.8rem;
  /* font-weight: 500; */
  letter-spacing: 0.4px;
  width: 100%;
  margin-top: auto;
  padding: 4%;
  border-radius: 10px;
  cursor: pointer;
  transition: 0.3s ease-in;
  display: flex;
  align-items: center;
  /* justify-content: center; */
}

.sidebar-button {
  position: relative;
  /* background-color: white;   */
  /* content:'111' */
}

.sidebar-button .mobile {
  left: 11%;
  padding: 2.5%;
  top: 21%;
}

.sidebar-button span {
  width: 100%;
  text-align: left;
  margin-left: 2rem;
}

.sidebar-button img {
  width: 3rem;
  aspect-ratio: 1;
  object-fit: contain;
}
.mobile-cart__button {
  font-size: 1.8rem;
  /* font-weight: 500; */
  letter-spacing: 0.4px;
  padding: 4%;
  border-radius: 10px;
  cursor: pointer;
  transition: 0.3s ease-in;
  display: flex;
  align-items: center;
  margin-left: 0 !important;
}

.mobile-cart__button span {
  width: 100%;
  text-align: center;
}

.mobile-cart__button img {
  width: 3rem;
  aspect-ratio: 1;
  object-fit: contain;
}
.sidebar-button:hover,
.sidebar-close-button:hover {
  background-color: rgb(50, 50, 50);
  /* background-color: rebeccapurple; */
}

.sidebar-user-button-container {
  position: absolute;
  bottom: 0;
  width: 85%;
}

.sidebar-register-button {
  background-color: #fe2b0d;
  text-align: center;
  margin: 3% 0;
  font-size: 2rem;
  /* font-weight: 500; */
  letter-spacing: 0.4px;
  width: 100%;
  margin-top: auto;
  padding: 5%;
  border-radius: 10px;
  cursor: pointer;
  display: block;
}

.search-toggle {
  display: none;
  cursor: pointer;
  aspect-ratio: 1;
  width: 10%;
  aspect-ratio: 1;
}

.nav-mobile-search {
  background-color: #0d0d0d;
  width: 100%;
  top: 8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1% 2.5%;
  padding-bottom: 2%;
  z-index: 998;
  color: var(--color-white-1);
  position: fixed;
}

.nav-mobile-search-container {
  width: 100%;
  display: flex;
  align-items: center;
  background-color: hwb(0 25% 75%);
  border-radius: 15px;
  overflow: hidden;
  border: 1px solid #cdcccc;
}

.nav-mobile-search-container > img {
  /* width: 2%;
  margin: 2%;
  cursor: pointer; */
  margin: 1.5rem;
  color: #444;
  cursor: pointer;
  width: 1.5rem;
  aspect-ratio: 1;
  cursor: pointer;
}

.nav-mobile-search-container > input {
  /* width: 58%;
  outline: none;
  border: none;
  background-color: #403f3f;
  font-size: 1.3rem;
  color: #cbcbcb; */
  width: calc(100% - 4.5rem - 15rem);
  height: 100%;
  outline: none;
  border: none;
  background-color: #403f3f;
  font-size: 1.4rem;
  color: #cbcbcb;
}

.nav-mobile-search-container > button {
  width: 15rem;
  position: relative;
  cursor: pointer;
  background-color: #403f3f;
  border: none;
  border-left: 2px solid rgb(205, 204, 204, 0.45);
  font-family: "Poppins", sans-serif;
  font-size: 1.4rem;
  font-weight: 300;
  color: #cbcbcb;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.navbar-mobile-search-error {
  position: absolute;
  left: -5rem;
  height: 2.5rem;
  width: 2.5rem;
}

.navbar-mobile-search-category-popup-arrow {
  color: #403f3f;
  left: 2.5rem;
  width: 3rem;
  height: 2rem;
  margin-top: -0.6rem;
}

.navbar-mobile-search-category-popup-content {
  margin-top: 2rem !important;
  position: fixed !important;
  background-color: #403f3f;
  box-shadow: 0px 3px 6px #00000029;
  padding: 1% 2%;
  border-radius: 10px;
  font-size: 1.5rem;
  color: #cbcbcb;
  width: 25rem;
  right: 2rem;
  left: auto !important;
}

.navbar-mobile-search-category-popup-content .search-category__option {
  margin: 5% 0;
  text-align: center;
  padding: 10%;
  border-radius: 7px;
  transition: 0.2s ease-in-out;
}

.navbar-mobile-search-category-popup-content .search-category__option:hover {
  background-color: rgba(13, 13, 13, 0.5);
  cursor: pointer;
}

.navbar-mobile-search-category-popup-content .search-category__option-selected {
  background-color: rgba(13, 13, 13, 0.5);
}

.mobile-notification-popup-content {
  background-color: #161616;
  padding: 3%;
  border-radius: 10px;
  width: 90%;
  max-height: 70%;
  color: white;
}

.mobile-notification-popup-content .bell-div .notifications {
  max-height: 45rem !important;
}

.mobile-notification-close-icon {
  margin-left: auto;
}

.notification-unread-dot {
  background-color: #fe2b0d;
  padding: 11%;
  border-radius: 50%;
  position: absolute;
  left: 27%;
  top: 0%;
}

.cart-items-dot {
  background-color: #fe2b0d;
  border-radius: 50%;
  position: absolute;
  left: 23%;
  top: -28%;
  font-size: 1.1rem;
  width: 37%;
  aspect-ratio: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}
.cart-items-dot__mobile {
  background-color: #fe2b0d;
  border-radius: 50%;
  position: absolute;
  left: 70%;
  top: -21%;
  font-size: 1.1rem;
  width: 80%;
  aspect-ratio: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}
.nav-sell-beat-popup-content {
  margin-left: auto;
  margin-top: 3rem;
  width: 20rem;
  background-color: #161616;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
}

.sell__options {
  width: 100%;
  /* height: 5rem; */
  padding: 3rem 2rem 2.2rem 2rem;
  text-align: left;
  outline: none;
  border: none;
  background-color: #161616;
  color: white;
  border-bottom: 1px solid #b4b4b4;
  cursor: pointer;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.sell__options:last-of-type {
  border-radius: 0;
  border: none;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.country__selector__mobile {
  display: none;
}
.mobile-cart__button {
  display: none;
}
.nav_right a {
  position: relative;
}

.navbar-page-underline {
  position: absolute;
  bottom: -0.3rem;
  left: -0.6rem;
  width: calc(100% + 1.2rem);
  height: 1.5px;
  background-color: white;
}

@media only screen and (max-width: 82.5em) {
  .navbar {
    padding: 0 4%;
  }

  .nav_right {
    gap: 1.25rem;
  }

  .vertical-divider {
    margin: 0 1.5rem;
  }

  .user-popup-arrow {
    left: -4rem;
  }
}

@media only screen and (max-width: 59.375em) {
  .nav-search--container {
    display: none;
  }

  .search-toggle {
    display: initial;
    width: 2.2rem;
    order: 1;
  }

  .user-popup-content {
    width: 25%;
  }
}

@media only screen and (max-width: 50em) {
  .shopping,
  .bell,
  .user-options-button {
    display: none;
  }

  .sidebar-toggle {
    display: initial;
    width: 2.2rem;
    order: initial;
  }

  .search-toggle {
    order: initial;
  }

  .country-popup-content {
    left: auto !important;
    right: 2rem;
  }

  .country-popup-arrow {
    left: 6rem;
  }
}

@media only screen and (max-width: 37.5em) {
  .nav_right {
    display: none;
  }

  .nav_left {
    width: 100%;
  }

  .nav_left > a {
    margin-left: auto;
  }

  .country__selector__mobile {
    display: initial;
  }
  .mobile-cart__button{
    display: initial;
  }
  .country-popup-content {
    left: 2rem !important;
    right: auto;
  }

  .country-popup-arrow {
    left: 1rem;
  }
}

@media only screen and (max-width: 62.5em) {
  /* .nav-search {
    width: 40.7%;
  }
  .search-container--right {
    width: 43%;
  }
  .navbar {
    padding: 0;
    justify-content: center;
  }
  .nav_left {
    justify-content: center;
  }
  .nav__logout {
    width: 60%;
  }
  .navbar_upload-btn {
    aspect-ratio: 2.2;
  }

  .user-popup-content img {
    display: none;
  } */
}
@media only screen and (max-height: 700px) {
  .sidebar-user-button-container {
    position: absolute;
    bottom: 0;
    width: 85%;
    height: 2%;
  }
}
@media only screen and (max-width: 50em) {
  /* .navbar-down-arrow {
    display: none;
  }
  .nav-search--container {
    display: none;
  }
  .navbar {
    padding: 0 6%;
    justify-content: space-between;
  }
  .search-toggle {
    display: initial;
  }
  .navbar_upload-btn {
    aspect-ratio: 3.2;
  }
  .nav__dropdowns {
    display: none;
  }
  .nav__logout {
    width: 60%;
  }
  .nav_right {
    margin-right: 0;
    justify-content: flex-end;
    width: 50%;
  }

  .nav__list__login {
    border: none;
  }

  .nav_left {
    width: 35%;
    margin-left: 0;
  }
  .sidebar-toggle {
    width: 10%;
    display: initial;
    margin-right: 10%;
  } */
}

@media only screen and (max-width: 37.5em) {
  /* .navbar {
    padding: 0 4%;
  }

  .search-toggle {
    width: 5%;
    margin-right: 6%;
  }

  .country__selector__mobile {
    display: flex;
    margin-left: 0;
    margin-right: 6%;
    height: 30%;
    max-height: 30%;
  }

  .country__selector__mobile img {
    width: auto;
  }

  .sidebar-toggle {
    width: 5%;
    margin-right: 6%;
  }

  .nav_left {
    width: 100%;
  }

  .nav_left a {
    margin-left: auto;
  }

  .nav_right {
    display: none;
  } */
}

@media only screen and (max-width: 31.25em) {
  /* .navbar-logo {
    width: 100%;
  } */
}
