/* All playlists page */

.playlists {
  margin-left: 7.9%;
  margin-right: 8.6%;
  min-height: 60vh;
}

.playlists-heading {
  padding: 2.15% 0;
  border-bottom: 1px solid #d4d4d4;
}

.playlists-heading h2 {
  font-size: 2.5rem;
  font-weight: 500;
  margin-bottom: 2%;
}
.hidden-image {
  display: none;
}
.create-playlist-button {
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
  background-color: #fe2b0d;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 1.75rem;
  font-weight: 500;
  width: 20%;
  white-space: nowrap;
  aspect-ratio: 4;
  padding: 0.9%;
  border-radius: 6px;
}

.create-playlist-button img {
  background-color: white;
  border-radius: 50%;
  aspect-ratio: 1;
  width: 12%;
  /* height: 80%; */
  margin-right: 6%;
}

.create-playlist-button:disabled {
  opacity: 0.6;
}
.deposit__button:disabled {
  opacity: 0.6;
cursor: not-allowed;
}
.playlists-cards-container {
  display: grid;
  gap: 1rem;
  /* justify-content: flex-start; */
  width: 100%;
  margin-top: 6%;
  margin-bottom: 14%;
}

@media (min-width: 600px) {
  .playlists-cards-container {
    grid-template-columns: repeat(3, 1fr) !important;
  }
}

@media (min-width: 900px) {
  .playlists-cards-container {
    grid-template-columns: repeat(4, 1fr) !important;
  }
}
@media (min-width: 300px) {
  .playlists-cards-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

.playlist-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  /* width: 21%; */
  margin-right: 5.3%;
  margin-bottom: 6%;
}

.playlist-card:nth-child(4n) {
  margin-right: 0;
}

.playlist-card img {
  cursor: pointer;
  width: 100%;
  aspect-ratio: 1;
  object-fit: cover;
  border-radius: 7px;
}

.playlist-card h3 {
  font-size: 2rem;
  font-weight: 500;
  color: #333333;
  letter-spacing: 0.5px;
  margin-top: 4.6%;
}

.playlist-card h4 {
  font-size: 1.75rem;
  font-weight: 400;
  color: #333333;
  opacity: 0.65;
  letter-spacing: 0.25px;
}

.create-playlist-popup-overlay {
  background-color: rgba(0, 0, 0, 0.8);
  /* background-color: #000000;
  opacity: 0.8; */
}

.create-playlist-popup-content {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  /* padding: 10rem; */
  border: 1px solid #707070;
  border-radius: 6px;
  width: 37%;
}

.create-playlist-popup-heading {
  display: flex;
  align-items: center;
  /* width: 100%; */
  padding: 5% 5% 2.5% 6%;
  border-bottom: 1px solid #d4d4d4;
}

.create-playlist-popup-heading h3 {
  font-size: 2rem;
  font-weight: 500;
}

.create-playlist-popup-heading img {
  margin-left: auto;
  width: 5%;
  aspect-ratio: 1;
  object-fit: contain;
  cursor: pointer;
}

.create-playlist-popup-container {
  display: flex;
  flex-direction: column;
  padding: 6.4% 6.4%;
}
.create-playlist-popup-container p {
  font-size: 1.5rem;
  font-weight: 400;
  color: #636466;
  margin-bottom: 1.6%;
  display: flex;
  align-items: center;
}

.create-playlist-popup-container p img {
  margin-left: auto;
  width: 4.5%;
  cursor: pointer;
}

.create-playlist-popup-container input[type="text"] {
  width: 100%;
  border: 1px solid #636466 !important;
  margin-bottom: 8.5%;
  padding: 2.2% 2%;
  font-size: 1.8rem;
  color: #636466;
  outline: none;
}

.create-playlist-popup__upload-image {
  border: 1px solid #f6e6e7;
  width: 33%;
  color: #f55446;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  padding: 1.9% 3.7% 1.9% 1%;
  border-radius: 5px;
  cursor: pointer;
}

.create-playlist-popup__upload-image img {
  width: 19%;
  aspect-ratio: 1;
  margin-right: 6.5%;
}

#playlist-image-upload {
  display: none;
}

.create-playlist-popup-container button {
  margin-top: 13.5%;
  margin-left: auto;
  width: 39%;
  font-weight: 400;
}

.create-playlist-popup-playlist-container {
  display: flex;
  flex-direction: column;
  padding: 5% 6.5% 2.5% 6%;
  border-bottom: 1px solid #d4d4d4;
  max-height: 40rem;
  overflow-y: scroll;
}

.create-playlist-popup-playlist-container-row {
  display: flex;
  align-items: center;
  margin-bottom: 5%;
}

.create-playlist-popup-playlist-container-row:last-of-type {
  margin-bottom: 0;
}

.create-playlist-popup-playlist-container-row__img {
  width: 15%;
  aspect-ratio: 1;
  object-fit: contain;
  border-radius: 7px;
  margin-right: 5.5%;
}

.create-playlist-popup-playlist-container-row__img .react-loading-skeleton {
  height: 100%;
}

.create-playlist-popup-playlist-container-row__title {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.create-playlist-popup-playlist-container h3 {
  color: #333333;
  font-size: 1.9rem;
  font-weight: 500;
  letter-spacing: 0.6px;
}

.create-playlist-popup-playlist-container h4 {
  color: #333333;
  opacity: 0.65;
  font-size: 1.7rem;
  font-weight: 400;
  letter-spacing: 0.4px;
  margin-top: -1%;
}

.create-playlist-popup-playlist-container-row button {
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
  outline: none;

  margin-left: auto;
  font-size: 1.5rem;
  background-color: #333333;
  color: white;
  border-radius: 15px;
  /* padding: 1%; */
  width: 15%;
  aspect-ratio: 2.1;
}

.create-playlist-popup-playlist__create-button {
  margin: 5% 6%;
  width: 40.5%;
  white-space: nowrap;
}

.create-playlist-popup-playlist__create-button img {
  width: 10%;
  aspect-ratio: 1;
  height: auto;
  object-fit: contain;
}

/* Playlist landing page */
.playlist-landing-topbar {
  background-repeat: no-repeat;
  width: 100%;
  background-size: 100%;
  background-position: center center;
  color: white;
}

.playlist-landing-overlay {
  background: #0d0d0d 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  opacity: 0.8;
  backdrop-filter: blur(15px);
  -webkit-backdrop-filter: blur(15px);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 3.3% 0 3% 10%;
}

.playlist-landing__cover-image {
  margin-right: 5.3%;
  width: 17%;
  aspect-ratio: 1;
  object-fit: contain;
  border-radius: 8px;
  position: relative;
}

.playlist-landing__cover-image .play-button {
  position: absolute;
  top: 0;
  width: 100%;
  aspect-ratio: 1;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: 0.3s ease-in-out;
  border-radius: 8px;
}

.playlist-landing__cover-image .play-button button {
  background-color: #fe2b0d;
  border-radius: 50%;
  width: 40%;
  padding: 4%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  cursor: pointer;
}

.playlist-landing__cover-image .play-button:hover {
  opacity: 1;
}

.playlist-landing-detail-container {
  width: 100%;
}

.playlist-landing-detail-container h2 {
  font-size: 3.6rem;
  font-weight: 500;
}

.playlist-landing-detail-container h3 {
  display: flex;
  align-items: center;
  font-size: 2rem;
  font-weight: 400;
  margin-top: 0.5%;
  margin-bottom: 0.6%;
}

.playlist-landing-detail-container h3 img {
  margin-right: 0.7%;
  width: 1.4%;
}

.playlist-landing-detail-container p {
  font-size: 1.6rem;
  font-weight: 400;
}

.playlist-landing-detail-container__buttons {
  display: flex;
  align-items: center;
  margin-top: 2.5%;
}

.playlist-landing-detail-container__buttons button {
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #707070;
  padding: 0.8% 2.4%;
  border-radius: 3px;
  font-size: 1.8rem;
  font-weight: 500;
  color: white;
}

.playlist-landing-detail-container__buttons button:first-of-type {
  margin-right: 1.9%;
}

.playlist-landing-detail-container__buttons button img {
  width: 100%;
  aspect-ratio: 1;
  object-fit: contain;
  margin-right: 1.8rem;
}

.playlist-landing-search {
  padding: 2.5% 8%;
  padding-bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-bottom: 1px solid #d4d4d4;
}

.playlist-landing-search h2 {
  font-size: 1.75rem;
  font-weight: 400;
}

.playlist-landing-search h3 {
  font-size: 1.6rem;
  font-weight: 400;
  /* text-align: center; */
}

.playlist-landing-search__input-container {
  display: flex;
  align-items: center;
  width: 50%;
  border: 1px solid #131313;
  border-radius: 30px;
  padding: 1% 1.5%;
  margin-top: 1.7%;
  margin-bottom: 0.5%;
}

.playlist-landing-search__input-container img {
  width: 3.3%;
  aspect-ratio: 1;
  object-fit: contain;
  cursor: pointer;
}

.clear-search {
  margin-left: 1%;
}

.playlist-landing-search__input-container img:first-of-type {
  margin-right: 2%;
}

.playlist-landing-search__input-container input {
  width: 100%;
  font-size: 1.5rem;
  outline: none;
  border: none;
}

.playlist-download-beat,
.playlist-add-beat {
  cursor: pointer;
  width: 10%;
  background-color: #131313;
  margin-right: 1%;
  border: none;
  border-radius: 4px;
  font-size: 1.5rem;
  letter-spacing: 0.5px;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  color: white;
  /* margin: 0 0.6%; */
  padding: 0.8%;
}

.search-beat-rows .beat-rows--item button:first-of-type {
  margin-right: 1.2%;
  margin-left: -1%;
}

.playlist-add-beat {
  border-radius: 13px;
}

.playlist-download-beat img {
  width: 17%;
  aspect-ratio: 1;
  object-fit: contain;
  margin-right: 9%;
}

.playlist-landing-search .beats-rows {
  margin-top: 3%;
}

.playlist-landing-search .beat-rows--item {
  margin: 1.3% 0;
}

.search-beat-rows .beat-rows--item {
  cursor: default;
  margin: 0;
}

.search-beat-rows .beat-rows--item:hover {
  background-color: transparent;
}

.playlist-landing__cover-image img {
  width: 100%;
  aspect-ratio: 1;
  object-fit: cover;
  border-radius: 8px;
}

.playlist-search-null-error {
  margin-bottom: 4%;
}
@media only screen and (max-width: 68.75em) {
  /* .create-playlist-popup-content{
    width: 65%;
  } */
  .create-playlist-popup-playlist__create-button {
    width: 45.5%;
  }
  .create-playlist-popup-playlist-container-row button {
    width: 22%;
  }
  .create-playlist-button {
    width: 28%;
  }
  .playlist-landing__cover-image {
    width: 28%;
  }
  .playlist-landing-detail-container h3 img {
    width: 1.6%;
    margin-right: 0.8%;
  }
}
@media only screen and (max-width: 56.25em) {
  .create-playlist-popup-content {
    width: 65%;
  }
  .create-playlist-popup-playlist__create-button {
    width: 45.5%;
  }
  .create-playlist-popup-playlist-container-row button {
    width: 22%;
  }
  .playlist-landing-detail-container h3 img {
    width: 1.9%;
    margin-right: 0.9%;
  }
}
@media only screen and (max-width: 43.75em) {
  .playlist-landing-detail-container h3 img {
    width: 2.1%;
    margin-right: 1%;
  }
}
@media only screen and (max-width: 37.5em) {
  .create-playlist-popup-content {
    width: 95%;
  }
  .create-playlist-popup-playlist__create-button {
    width: 45.5%;
  }
  .create-playlist-popup-playlist-container-row button {
    width: 20%;
  }
  .create-playlist-button {
    width: 45%;
  }
  .playlist-landing-detail-container h3 img {
    width: 2.4%;
    margin-right: 1.1%;
  }
  .playlist-landing__cover-image {
    width: 35%;
  }
}
@media only screen and (max-width: 31.25em) {
  .playlist-landing-overlay {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 3.3% 0% 3% 0%;
  }
  .playlist-landing__cover-image {
    margin-right: 0;
    width: 50%;
  }
  .playlist-landing-detail-container h2,
  .playlist-landing-detail-container p {
    text-align: center;
  }
  .playlist-landing-detail-container h3 {
    justify-content: center;
  }
  .create-playlist-button {
    width: 60%;
  }
  .playlist-landing-detail-container__buttons {
    width: 70%;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 3%;
  }
  .playlist-landing-detail-container__buttons button {
    width: 50%;
    aspect-ratio: 3;
  }
  .playlist-landing-detail-container__buttons button img {
    width: 15%;
  }
  .playlist-landing-search__input-container {
    width: 100%;
    padding: 2% 2.5%;
  }
  .playlist-add-beat{
    max-width: 35%;
    min-width: 25%;
  white-space: wrap;

  }
}
@media only screen and (max-width: 21.875em) {
  .playlist-landing-topbar {
    background-size: 115%;
  }
  .playlist-landing-detail-container h3 img {
    width: 2.7%;
    margin-right: 1.1%;
  }
}
.warning__message{
  color: #f55446;

}