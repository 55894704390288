.tracks {
  /* margin-top: 89vh; */
  padding: 9.4% 7.2%;
  background-color: #fff;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  /* height: 85%; */
}
.songs {
  padding: 0% 7.2% 9.4% 7.2%;
  background-color: #fff;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: auto;
}
/* .latest{
    overflow: hidden;
    padding: 0 14rem 15rem 14rem;
    background-color: #fff;

} */

.trending-tracks {
  font-family: "Poppins";
  font-weight: 500;
  font-size: 2.7rem;
  color: black;
  /* margin-bottom: 1.4%; */
  width: 50%;
  white-space: nowrap;
}

.music-cards {
  color: black;
  font-family: "Poppins";
  display: flex;
  width: 100%;
  overflow: hidden;
  height: 85%;
}

.react-multi-carousel-list {
  width: 100%;
}

.music-card {
  position: relative;
  margin: 0 3.25%;
  cursor: pointer;
}

.music-card span {
  color: #f55446;
}
.price {
  font-size: 1.65rem;
  display: flex;
  align-items: center;
}
.music-card--img {
  width: 100%;
  aspect-ratio: 1;
  border-radius: 5px;
  object-fit: cover;
  /* width: 100%; */
}

.music-card--music-name {
  font-weight: 400;
  color: black;
  margin-top: 2%;
  margin-bottom: 0.6%;
  font-size: 1.8rem;
}
.music-card--artist-price {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.5rem;
  opacity: 0.65;
  color: black;
}

.music-card-artist-name {
  font-size: 1.7rem;
  font-weight: 400;
}

.play_button {
  position: absolute;
  width: 100%;
  aspect-ratio: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  background-image: linear-gradient(359deg, #0d0d0d 0%, #13121200 100%);
  opacity: 0;
  transition: 0.5s ease-out;
  overflow: hidden;
  border-radius: 5px;
}
.play_button:hover {
  opacity: 1;
}
.play {
  width: 33.5%;
  height: 34%;
  border-radius: 50%;
  background-color: #f55446;
  border: none;
  opacity: 0.8;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.extras {
  height: 12%;
  display: flex;
  width: 80%;
  justify-content: space-between;
  position: absolute;
  bottom: 0;
}
.play-div {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.play img {
  width: 95%;
  height: 70%;
}
.extras button {
  width: 11%;
  height: 75%;
  background-color: transparent;
  border: none;
  cursor: pointer;
}
.heading-home {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  flex-wrap: wrap;
  width: 100%;
  height: 18%;
  margin-bottom: 1.5%;
}
.arrow-keys {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  /* margin-bottom: 2.6%; */
  /* margin-bottom: 1.4%; */
  width: 50%;
  gap: 1.5rem;
}
.arrow-keys button {
  width: 6%;
  /* height: 90%; */
  aspect-ratio: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 1px solid #333333;
  background-color: transparent;
  cursor: pointer;
}

.arrow-keys img {
  width: 100%;
  height: 59%;
  /* aspect-ratio: 1; */
}
.arrow-keys a {
  font-size: 1.9rem;
  text-decoration: none;
  color: #333333;
}
/* .keys {
  display: flex;
  height: 100%;
  width: 23%;
  align-items: center;
  justify-content: center;
} */
.dot {
  width: 6px;
  height: 6px;
  background-color: #666666;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
  margin-left: 5px;
}
.see__all {
  font-size: 1.8rem;
  font-weight: normal;
  cursor: pointer;
}
.skeleton-loading {
  width: 100%;
}

@media only screen and (max-width: 100em) {
  /* .music-card:nth-child(5) {
        display: none;
    } */
  /* .tracks {
    padding: 11.5rem 7rem;
  } */

  /* .music-card--img {
    width: 24.5rem;
    height: 24.5rem;
  }
  .play_button {
    width: 24.5rem;
    height: 24.5rem;
  } */

  .music-card--music-name {
    font-size: 1.6rem;
  }

  .music-card-artist-name {
    font-size: 1.4rem;
  }

  /* .latest {
    padding: 0 7rem 12rem 7rem;
  } */
}
@media only screen and (max-width: 56.25em) {
  /* .music-cards {
    gap: 3.6rem;
    width: 90%;
  }
  .music-card {
    width: 23rem;
  }
  .music-card--img {
    height: 23rem;
    width: 23rem;
    object-fit: cover;
  }
  .play_button {
    width: 23rem;
    height: 23rem;
  } */
  .tracks,
  .songs {
    padding: 7% 5%;
    /* margin-top: 83rem; */
  }
  .music-card--music-name {
    font-size: 1.6rem;
  }

  .music-card-artist-name {
    font-size: 1.4rem;
  }
}
@media only screen and (max-width: 40.5em) {
  /* .music-card {
    width: 30rem;
  }
  .music-card--img {
    height: 30rem;
    width: 30rem;
    object-fit: cover;
  }
  .play_button {
    width: 30rem;
    height: 30rem;
  } */
  .music-card--music-name {
    font-size: 1.8rem;
  }
  .music-card--artist-price {
    font-size: 1.5rem;
  }
  .music-card-artist-name {
    font-size: 1.7rem;
  }
}

@media only screen and (max-width: 31.25em) {
  .tracks {
    padding: 6% 4%;
    margin-top: 5%;
  }
  .songs {
    padding: 6% 4%;
  }
  .arrow-keys button {
    width: 10%;
  }
}

@media only screen and (max-width: 26.5em) {
  .tracks,
  .songs {
    padding: 5% 3%;
    /* margin-top: 100rem; */
  }
  .heading-home {
    width: 94%;
  }
  .arrow-keys button {
    width: 16%;
  }
}

@media only screen and (max-width: 37.5em) {
  .arrow-keys button {
    width: 12%;
  }
}
@media only screen and (max-width: 21.875em) {
  /* .arrow-keys {
    flex-direction: column;
  } */
}
