@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");

:root {
  --color-primary: #fe2b0d;
  --color-secondary-1: #eb4831;
  --color-secondary-2: #f55446;
  --color-secondary-3: #ea6d62;
  --color-secondary-4: #d35644;
  --color-secondary-5: #fc704e;
  --color-black-1: #131313;
  --color-black-2: #262626;
  --color-grey-1: #333333;
  --color-grey-2: #666666;
  --color-grey-3: #636363;
  --color-grey-4: #636466;
  --color-grey-5: #707070;
  --color-grey-6: #c6c6c6;
  --color-white-1: #ffffff;
  --color-white-2: #f6e6e7;
  --color-white-3: #fee6e0;
  --color-white-4: #d4d4d4;
  --color-green-1: #2c7919;
  --color-green-2: #229e04;
  --color-blue-1: #5349ce;
  --color-blue-2: #0064fe;
}

* {
  margin: 0;
  padding: 0;
  font-family: Poppins, sans-serif;
}

*,
:before,
:after {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  font-size: 62.5%;
}

@media only screen and (max-width: 68.75em) {
  html {
    font-size: 53%;
  }
}

@media only screen and (max-width: 31.25em) {
  html {
    font-size: 45%;
  }
}

body {
  color: #111;
  /* min-height: calc(100vh - 8vw); */
  font-family: Poppins, sans-serif;
  font-weight: 400;
  line-height: 1.6;
  overflow-x: hidden;
}

.loading{
margin: 0 auto 3% auto;
}
