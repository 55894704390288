.logo-div {
  margin-left: 4.5%;
  margin-top: 2.25%;
  background-color: transparent;
  border: none;
  cursor: pointer;
}
.logo-div img {
  cursor: pointer;
}

.message-div-1 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 80vh;
}

.message-div-1 img {
  height: 24.1%;
}

.message-div-1 h4 {
  text-align: center;
  font-size: 3.7rem;
  font-weight: 500;
  margin-top: 0.8rem;
  margin-bottom: 2.2rem;
}

.message-div-1 p {
  width: 41.3%;
  text-align: center;
  font-size: 1.8rem;
  font-weight: 300;
}

.message-div-1 p:last-of-type {
  margin: 3% 0;
}

.finish-redirect {
  appearance: none;
  outline: none;
  border: none;
  color: #fe2b0d;
  background-color: transparent;
  cursor: pointer;
  font-size: 1.6rem;
  margin-top: -2%;
}
