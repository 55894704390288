.wallet-section {
  background-color: #f6f7f9;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  min-height: 70vh;
}
.wallet-heading {
  width: 60%;
  margin-top: 3%;
  margin-bottom: 3%;
}
.wallet-heading-tag {
  font-weight: 600;
  font-size: 3rem;
  color: #333333;
}
.wallet-balance {
  width: 60%;
  display: flex;
  flex-direction: column;
}
.wallet-balance-heading {
  background-color: #ffffff;
  display: flex;
  /* flex-direction: column; */
  border: 1px solid #d4d4d4;
  border-radius: 12px;
  padding: 3% 5%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2%;
}
.wallet-balance-heading-div {
  display: flex;
  flex-direction: column;
  width: 30%;
}
.wallet-balance-heading-div p {
  color: #707070;
  font-size: 1.9rem;
  white-space: nowrap;
}
.wallet-balance-heading-div h3 {
  color: #262626;
  font-size: 2.7rem;
  font-weight: 600;
}
.wallet-balance-withdraw {
  display: flex;
  width: 70%;
}
.wallet-balance-withdraw-button {
  border: none;
  background-color: #131313;
  border-radius: 8px;
  color: #ffffff;
  font-size: 1.7rem;
  padding: 2% 4%;
  white-space: nowrap;
  cursor: pointer;
}

.wallet-balance-withdraw-button:first-of-type {
  margin-left: auto;
  margin-right: 5rem;
}

.wallet-transactions {
  display: flex;
  background-color: #ffffff;
  border: 1px solid #d4d4d4;
  border-radius: 12px;
  flex-direction: column;
  padding: 2% 0%;
  margin-bottom: 8%;
}
.wallet-transactions-top {
  display: flex;
  align-items: center;
  gap: 1%;
  padding: 5%;
  padding-bottom: 3%;
  padding-top: 1%;
  border-bottom: 1px solid #d4d4d4;
}
.wallet-transactions-top p {
  color: #262626;
  font-size: 2.1rem;
}
.wallet-transactions-buttons {
  background-color: #f5f5f5;
  border-radius: 4px;
  white-space: nowrap;
  padding: 1%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3%;
  width: 47%;
}
/* .wallet-transactions-buttons button:last-of-type {
  margin-right: 0 !important;
} */
.wallet-transactions-1-year,
.wallet-transactions-all,
.wallet-transactions-30-days {
  /* margin-right: 3%; */
  border: none;
  background-color: transparent;
  cursor: pointer;
  color: #707070;
  font-size: 1.5rem;
  padding: 1% 2%;
}
.wallet-transactions-30-days {
  width: 36%;
}
.wallet-transactions-1-year {
  width: 39%;
}
.wallet-transactions-all {
  width: 24%;
}
.wallet-transactions-30-days {
  border-radius: 4px;
}
.selected-transaction-type {
  color: #333333;
  background-color: #ffffff;
}
.wallet-transactions-30-days {
  border-radius: 4px;
}
.wallet-transactions-history {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 2% auto;
  width: 92%;
}
.wallet-transactions-history-head {
  display: flex;
  padding-bottom: 1%;
  padding: 2% 1%;
  border-bottom: 1px solid #d4d4d4;
}
.wallet-transactions-history-head h3 {
  color: #333333;
  font-size: 1.75rem;
  font-weight: 500;
  /* text-align: center; */
}
.wallet-transactions-history-items {
  display: flex;
  flex-direction: column;
}
.wallet-transactions-history-item {
  display: flex;
  align-items: center;
  padding: 2% 1%;
  border-bottom: 1px solid #d4d4d4;
}

.wallet-transactions-history-item h3 {
  font-size: 1.5rem;
  font-weight: 400;
  color: #333333;
  /* text-align: center; */
}

.wallet-transactions-history .item-date {
  width: 24%;
}

.wallet-transactions-history .item-name {
  width: 31%;
}

.wallet-transactions-history .item-amount-type {
  width: 25%;
  white-space: nowrap;
}

.wallet-transactions-history .item-amount {
  width: 20%;
}

.wallet-transactions-history .item-balance {
  width: 9.38%;
}

.wallet-transactions-history .item-method {
  width: 27.75%;
}

.wallet-transactions-history .item-status {
  width: 34.29%;
}

.wallet-popup-heading h3 {
  font-size: 1.75rem;
  font-weight: 400;
  padding: 5% 5% 2.5% 6%;
  border-bottom: 1px solid #d4d4d4;
}

.wallet-popup-content {
  padding-bottom: 3%;
}

.wallet-popup-container > button {
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  color: inherit;
  cursor: pointer;
  outline: none;

  border: 1px solid #d4d4d4;
  border-radius: 4px;
  display: flex;
  align-items: center;
  /* width: 100%; */
  margin: 6% 0;
  margin-bottom: 0;
  padding: 0 6.5%;
  font-size: 1.75rem;
  font-weight: 500;
  aspect-ratio: 6.91;
}

.wallet-popup-content button img {
  width: 15%;
  margin-left: auto;
}

.wallet-popup-container {
  display: flex;
  flex-direction: column;
  padding: 0 6.5%;
}

.wallet-popup-container label {
  font-size: 1.5rem;
  margin-top: 10%;
  margin-bottom: 1%;
  display: flex;
  align-items: center;
}

.deposit-error {
  margin-left: auto;
  cursor: pointer;
}

.wallet-popup-container input {
  border: 1px solid #d4d4d4 !important;
  padding: 3.7%;
  border-radius: 8px;
  font-size: 2.17rem;
  color: #636466;
  outline: none;
}

.wallet-popup-container .button-container {
  display: flex;
  justify-content: flex-end;
  gap: 3rem;
}

.wallet-popup-container .button-container button {
  margin-top: 8%;
  /* margin-left: auto; */
}

.wallet-popup-container .button-container .button-container__paystack {
  background-color: transparent;
  color: black;
  border: 1px solid #aaaaaa;
}

@media only screen and (max-width: 62.5em) {
  .wallet-heading,
  .wallet-balance {
    width: 70%;
  }
}

@media only screen and (max-width: 50em) {
  .wallet-heading,
  .wallet-balance {
    width: 80%;
  }
  .wallet-transactions-buttons {
    width: 55%;
  }
  .wallet-transactions-history-head {
    display: none;
  }
  .wallet-transactions-history-item {
    flex-direction: column;
    justify-content: center;
  }
  .wallet-transactions-history-item h3 {
    width: 100% !important;
    white-space: nowrap;
    text-align: center;
    margin: 0.3% 0;
  }
}

@media only screen and (max-width: 37.5em) {
  .wallet-heading,
  .wallet-balance {
    width: 90%;
  }
  .wallet-transactions-top {
    flex-direction: column;
    align-items: start;
    justify-content: center;
  }
  .wallet-transactions-buttons {
    margin-top: 2%;
  }
  .wallet-transactions-buttons {
    width: 100%;
    gap: 0;
    padding: 1%;
  }
  .wallet-transactions-buttons button {
    width: 100%;
    margin: 0 auto;
  }
}

@media only screen and (max-width: 31.25em) {
  .wallet-balance-withdraw-button:first-of-type {
    margin-right: 3rem;
  }
}

@media only screen and (max-width: 21.875em) {
  .wallet-balance-heading {
    flex-direction: column;
    align-items: start;
  }
  .wallet-balance-withdraw {
    width: 100%;
    gap: 2rem;
    margin-top: 3%;
  }
  .wallet-balance-withdraw-button {
    width: 50%;
    padding: 3% 4%;
    margin: 0 !important;
    white-space: wrap;
  }
}
.product-not-found-description {
  font-size: 3rem;
  text-align: center;
  margin-bottom: 3rem;
}
