.findproducer {
  background-color: #ffffff;
  width: 100%;
  /* height: 99.5rem; */
  border-top: 1px solid #d4d4d4;
}

.sellBeat {
  background-color: #fff;
  /* height: 77.5rem; */
  width: 100%;
}

.freelancer {
  margin-bottom: 7rem;
  /* margin-top: 12.8rem; */
  background-color: #fff;
}

.findproducer-inner {
  display: flex;
  align-items: center;
  color: black;
  font-family: "Poppins";
  justify-content: center;
  height: 100%;
}

.findproducer-inner img {
  width: 95%;
  /* height: 78%; */
  object-fit: contain;
}

.inner-image {
  width: 50%;
  background-color: #0a0a0a;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.producer-image {
  background-color: black;
  width: 50%;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.findProducer-text {
  padding: 0 5%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 50%;
  height: 100%;
}
.findproducer-inner h2 {
  font-weight: 500;
  font-size: 4.8rem;
  margin-bottom: 4.1rem;
}

.findproducer-inner p {
  font-weight: 200;
  font-size: 2.3rem;
  margin-bottom: 5rem;
}

.findproducer-inner-btn {
  border-radius: 4px;
  width: 28rem;
  height: 6.9rem;
  color: #fff;
  font-family: "Poppins";
  font-size: 2.4rem;
  font-weight: 400;
  background-color: #fe2b0d;
  border: none;
  cursor: pointer;
}
.inner-image-sell {
  background-color: black;
  width: 50%;
  padding: 4%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.inner-image-sell img {
  /* height: 88%;
  width: auto; */
  width: 100%;
  object-fit: contain;
}
@media only screen and (max-width: 100em) {
  .findproducer-inner h2 {
    font-size: 4rem;
  }

  .findproducer-inner p {
    font-size: 1.8rem;
  }

  .findproducer-inner-btn {
    width: 22rem;
    height: 4.8rem;
    font-size: 1.6rem;
  }
}

.findproducer-inner > div {
  order: 0;
}

@media only screen and (max-width: 56.25em) {
  /* .findproducer-inner > div {
    order: 1;
  } */

  .findProducer-text {
    order: 2 !important;
    margin-top: 8rem;
  }

  .findproducer {
    border-bottom: 1px solid #d4d4d4;
    border-top: none;
    padding-bottom: 2rem;
    /* margin-bottom: 2rem; */
  }
  .findproducer-inner {
    flex-direction: column;
    height: 100%;
    width: 100%;
  }
  .inner-image {
    width: 100% !important;
    height: 40%;
    /* align-items: flex-start; */
  }
  .findproducer-inner img {
    width: 40%;
    /* height: 100%; */
  }
  .findproducer-inner div {
    width: 90%;
    text-align: center;
    padding-bottom: 3%;
  }

  .findproducer-inner .inner-image {
    margin-top: 6rem;
  }

  .findProducer-text {
    margin-top: 6rem;
    height: auto;
    align-items: center;
  }
  .inner-image-sell {
    width: 100% !important;
  }

  .inner-image-sell img {
    width: 30%;
  }
}
@media only screen and (max-width: 31.25em) {
  .findproducer-inner img {
    width: 70%;
    height: 80%;
  }
}
.disabled-sell-beat {
  cursor: not-allowed;
}

.sell-beat-error-popup-content {
  font-size: 1.6rem !important;
  margin-top: -1rem !important;
  box-shadow: 3px 3px #0a0a0a29 !important;
}
