.upload-beats {
  background-color: #f6f7f9;
  font-family: "Poppins";
  font-weight: 400;
  color: #333333;
  padding: 4.4rem 14.8rem 13.7rem 11.6rem;
  /* padding: 2.35% 7.9% 7.3% 6.12%; */
}

.upload-beats__heading-primary {
  font-size: 3.2rem;
  font-weight: 600;
  margin-bottom: 4.8rem;
}

.upload-beats__heading-secondary {
  font-size: 2.5rem;
  margin-bottom: 2rem;
  font-weight: 400;
}

.upload-form__form-input {
  display: flex;
  gap: 10px;
}
.track__remove {
  margin-left: 2rem;
  cursor: pointer;
}
.form-imput_beats {
  padding: 7.7rem 6.4rem 7.9rem 7rem;
  /* padding: 4.8% 4% 4.9% 4%; */
  background-color: #fff;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  width: 64.75%;
}

.form-input_song {
  background-color: #fff;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  width: 64.75%;
}

.form-input_song > div {
  border-bottom: 1px solid #ccc;
  width: 100%;
  padding: 7.7rem 6.4rem 7.9rem 7rem;
}

.upload-song-dark {
  background-color: #f9f9f9;
  border-radius: 10px;
  width: 100%;
  padding: 4rem 2rem;
  display: flex;
  flex-direction: column;
}

.upload-song-dark > div {
  margin-bottom: 5rem;
}

.track-price {
  margin-bottom: 5.6rem;
}

.upload-track-button-container {
  display: flex;
  align-items: center;
  gap: 2rem;
  margin-bottom: 0 !important;
}

.upload-track-button-container > label {
  padding: 1rem 2rem;
  outline: none;
  border: none;
  cursor: pointer;
  font-size: 1.5rem;
  border-radius: 3px;
  border: 1px solid black;
  background-color: #eee;
}

.upload-track-button-container .music-label-name {
  display: flex;
  align-items: center;
  font-size: 1.5rem;
  color: #327f80;
  white-space: nowrap;
  overflow: hidden;
  max-width: 20%;
  text-overflow: ellipsis;
}

.upload-track-button-container .music-label-delete {
  display: flex;
  align-items: center;
  font-size: 1.5rem;
  color: red;
  cursor: pointer;
  border-bottom: 1px dotted red;
  cursor: pointer;
}

.add-collaborator {
  color: #fe2b0d;
  border: none;
  outline: none;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  font-size: 1.5rem;
  font-weight: 400;
  margin-top: 2rem;
  cursor: pointer;
}

.collaborators-list {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-top: 2rem;
  width: 100%;
}

.collaborator-row {
  font-size: 1.8rem;
  width: 100%;
  display: flex;
}

.collaborator-split {
  font-weight: 500;
}

.collaborator-role {
  font-weight: 500;
  margin-left: auto;
}

.reach-more-container {
  display: flex;
  width: 100%;
  gap: 2rem;
  flex-wrap: wrap;
}

.reach-more-category-container {
  width: calc(100% / 3 - 4rem / 3);
}

.reach-more {
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid #aaa;
  border-radius: 10px;
  padding: 2rem;
  gap: 2rem;
}

.reach-more-row .container {
  font-size: 1.5rem;
  width: 100% !important;
}

.reach-more-heading {
  width: 100%;
  margin-bottom: 1rem;
  font-size: 1.5rem;
  white-space: nowrap;
}

.form-beats_label {
  font-size: 1.9rem;
  font-weight: 400;
  color: #636466;
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 1.3rem;
}
.form-beats_heading {
  font-size: 2.5rem;
  margin-bottom: 2rem;
  font-weight: 400;
  width: 100%;
  display: flex;
}
.form-beats_label img {
  margin-left: auto;
  width: 3%;
  cursor: pointer;
}
.form-beats_heading img {
  margin-left: auto;
  width: 3%;
  cursor: pointer;
}
.form-beats-input {
  border-radius: 4px;
  outline: 1px solid #707070;
  border: none;
  height: 6rem;
  width: 100%;
  padding: 1.3rem 3rem;
  font-size: 1.9rem;
  color: #333333;
}

.form-beats-input:first-of-type,
.input-artist-name {
  margin-bottom: 5.6rem;
}

.genre-remove-button {
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  font: inherit;
  font-size: 1.4rem;
  cursor: pointer;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1%;
  background-color: #dfdfdf;

  white-space: nowrap;
  border-radius: 5px;
  margin-right: 0.7%;
}

.genre-remove-button img {
  /* width: 6%; */
  height: 2rem;
  aspect-ratio: 1;
  margin-right: 1.3%;
}

.BPM {
  margin-bottom: 8.5rem;
}

.custom-textarea {
  height: 14rem; /* Adjust the height to fit three lines */
  /* overflow-y: scroll; */
  padding: 1.8rem 2.3rem 2.1rem 1.5rem; /* Add 10px padding */
  font-size: 1.8rem;
  width: 100%;
  resize: none;
  line-height: 2.15;
  box-sizing: border-box;
  font-family: "Poppins";
  font-weight: 400;
  margin-bottom: 6.8rem;
}

.form-option {
  padding-right: 2.95rem;
  appearance: none;
  -webkit-appearance: none;
  /* background: url(/src/images/caret-down.svg) no-repeat right center / 20px auto; */
  /* background-position-x: calc(100% - 20px); */
  color: #333333;
  font-size: 1.9rem;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.form-option > img {
  height: 100%;
  aspect-ratio: 1.3;
  margin-left: auto;
}

.Checkmark-questions {
  margin-bottom: 2.9rem;
  font-size: 2rem;
  color: #636466;
  font-weight: 400;
}

.genre-dropdown-content {
  margin: auto;
  background-color: #ffffff;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 7px;
  color: black;
  font-size: 1.9rem;
  margin-top: 1rem;
  height: fit-content;
  padding: 0.9rem 3rem;
  display: flex;
  flex-direction: column;
  width: calc(70.1% - 14.8rem - 11.6rem - 6.4rem - 7rem);
  z-index: 997 !important;
}

.genre-dropdown-dark-content {
  width: calc(70.1% - 14.8rem - 11.6rem - 6.4rem - 7rem - 4rem);
}

.service-item {
  font-size: 1.8rem;
  margin: 1rem 0;
  font-weight: 400;
}

.checkbox-container input {
  margin-right: 1rem;
}

/* Checkmark */
/* Start */
.container {
  position: relative;
  padding-left: 50px;
  /* cursor: pointer; */
  font-size: 2.2rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #636466;
  display: flex;
  align-items: center;
  justify-content: baseline;
}

.container:first-of-type {
  width: 30%;
}

.container img {
  margin-left: 1rem;
  width: 30%;
}

.warning-message-popup-content {
  background-color: #c6c4c5;
  font-size: 1.5rem;
  text-align: left;
  letter-spacing: 0.4px;
  border-radius: 7px;
  width: 22%;
  padding: 0.6%;
  font-weight: 500;
}

.file-dropdown-content {
  flex-direction: row;
  flex-wrap: wrap;
}

/* .file-picker-row {
  display: flex;
  align-items: center;
} */

.file-dropdown-content .service-item {
  flex-basis: 25%;
}

.file-picker-row div:last-of-type {
  margin-right: 0;
}

.hidden {
  display: none;
}

.radio-btn-flex {
  display: flex;
  margin-bottom: 6.3rem;
}

/* Hide the browser's default radio button */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.Checkmark {
  position: absolute;
  /* top: 50%; */
  left: 0%;
  /* transform: translate(-50%, -50%); */
  height: 3rem;
  width: 3rem;
  background-color: #fff;
  border-radius: 50%;
  border: 2px solid #636466;
  cursor: pointer;
}

.container:hover input ~ .Checkmark {
  background-color: #ccc;
}

.container input:checked ~ .Checkmark {
  border: 1px solid #fe2b0d;
  background-color: #fff;
}

.Checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.container input:checked ~ .Checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.container .Checkmark:after {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 2.2rem;
  height: 2.2rem;
  border-radius: 50%;
  background: #fe2b0d;
  border: 1px solid #fe2b0d;
  cursor: pointer;
}

/* ************************ */
/* ************************ */
/* ******    END      ***** */
/* ************************ */

.form-input_artwork-upload {
  display: flex;
  flex-direction: column;
  width: 34.25%;
  gap: 10px;
}

.artwork_img-box {
  background-color: #fff;
  border-top-right-radius: 15px;
  /* padding-left: 6.4rem; */
  padding: 8.1rem 6.4rem 2.3rem;
}

.artwork_img-box div {
  cursor: pointer !important;
}

.artwork_img-box h3 {
  font-size: 1.9rem;
  color: #333333;
  font-weight: 500;
  margin-bottom: 2rem;
  display: flex;
  align-items: center;
}

.artwork_img-box h3 img {
  margin-left: auto;
  cursor: pointer;
}

.artwork_img-box p {
  font-size: 1.7rem;
  /* color: #fff; */
}

.artwork_img-box p:last-of-type {
  margin-bottom: 4.7rem;
}

.artwork_img-box div:first-of-type {
  margin-bottom: 2.3rem;
}

.imageName-delete {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1.3rem;
}

.imageName-delete div:first-of-type {
  margin-bottom: 0rem;
  display: flex;
  align-items: center;
}

/* .tick {
  margin-right: 1.3rem;
} */

.img_file-name {
  color: #1e827e;
  font-size: 2.1rem;
  overflow: hidden;
  text-overflow: ellipsis;
}

.upload-files {
  background-color: #fff;
  padding: 8.8rem 6.4rem 15.3rem;
  border-bottom-right-radius: 15px;
}

.upload-files_heading {
  margin-bottom: 1.4rem;
  font-size: 1.9rem;
  color: #333333;
  font-weight: 400;
  display: flex;
  align-items: center;
}

.upload-files_heading img {
  margin-left: auto;
  cursor: pointer;
}

.upload-files_secondary {
  font-size: 1.7rem;
  color: #666666;
  font-weight: 400;
  margin-bottom: 2.7rem;
}

.selected-file .file-upload-icon::after {
  content: "";
  margin-right: 5px;
}

.file-upload-icon {
  font-size: 20px;
  margin-right: 5px;
  opacity: 1;
}

.file-upload-text {
  font-size: 0;
  line-height: 1;
}

#file-upload-streaming,
#file-upload-download,
#file-upload-artwork {
  display: none;
}

.file-upload-label {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  background-color: #ededed;
  font-size: 1.9rem;
  font-weight: 200;
  height: 11.9rem;
  border-radius: 15px;
  border: 2px dashed #404040;
  border-spacing: 7px;
  cursor: pointer;
}

.upload-files div:first-of-type {
  margin-bottom: 10.3rem;
}
.save-changes:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}
.form-option {
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
}
.selected-file {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  background-color: #ededed;
  font-size: 1.9rem;
  font-weight: 200;
  height: 11.9rem;
  border-radius: 15px;
  border: 2px dashed #404040;
  border-spacing: 7px;
}

.selected-file p {
  max-width: 24rem;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 80%;
}

.tax {
  margin-top: 4.7rem;
  font-weight: 500;
  font-size: 2rem;
  color: #fe2b0d;
  opacity: 0.6;
  margin-bottom: 3.4rem;
}

.upload-container {
  width: 64.75%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 5.7rem;
}

.save-changes {
  /* margin-top: 5.7rem; */
  font-size: 2.5rem;
  background-color: #fe2b0d;
  border: none;
  cursor: pointer;
  color: #fff;
  padding: 1.7rem 6.3rem;
  border-radius: 4px;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
}

.artwork-thumbnail {
  width: 100%;
  height: auto;
}

.artwork-thumbnail img {
  width: inherit;
  height: inherit;
  object-fit: cover;
}

.form-imput_beats h4 {
  margin-bottom: 4rem;
  font-size: 2rem;
  font-weight: 500;
}

.autocomplete-container {
  position: relative;
  width: 100%;
  height: 6rem;
}

.autocomplete-container input {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 4px;
  outline: 1px solid #707070;
  border: none;
  height: 6rem;
  width: 100%;
  padding: 1.3rem 3rem;
  font-size: 1.9rem;
  color: #333333;
}

.autocomplete {
  position: absolute;
  width: 100%;
  background-color: red;
  left: 0;
  top: 6.5rem;
  margin: auto;
  background-color: #ffffff;
  box-shadow: 0px 0px 16px #00000029;

  border-radius: 7px;
  color: black;
  font-size: 1.9rem;
  margin-top: 1rem;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  transition: 0.1s all;
}

.autocomplete > div {
  font-size: 1.8rem;
  font-weight: 400;
  padding: 0.5rem 1.5rem;
  border-radius: 7px;
}
.autocomplete-loading {
  display: flex;
  align-items: center;
  justify-content: center;
}

.autocomplete-result-row {
  cursor: pointer;
  transition: 0.2s all;
}

.autocomplete-result-row:hover {
  background-color: #f0f0f0;
}

.input-revenue {
  margin-bottom: 0 !important;
}

.album-reach-more {
  border-top-right-radius: 0;
  border-bottom-right-radius: 15px;
}

.album-reach-more_button {
  border: 1px solid #636466;
  border-radius: 7px;
  padding: 1.7rem 2rem;
  font-size: 1.7rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.reach-more-album-popup-content {
  width: calc(((100% - 14.8rem - 11.6rem) * 0.3425) - 6.4rem - 6.4rem);

  margin: auto;
  background-color: #ffffff;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 7px;
  color: black;
  font-size: 1.9rem;
  margin-top: 0rem;
  height: fit-content;
  padding: 2rem 3rem;
  display: flex;
  flex-direction: column;
  /* width: calc(70.1% - 14.8rem - 11.6rem - 6.4rem - 7rem); */
  z-index: 997 !important;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

@media only screen and (max-width: 106.25em) {
  .upload-beats {
    padding: 3.8rem 8rem;
  }

  .upload-beats__heading-primary {
    font-size: 2.8rem;
    margin-bottom: 3.6rem;
  }

  .upload-beats__heading-secondary {
    font-size: 2.1rem;
  }

  .form-imput_beats {
    padding: 5.5rem 5.2rem 6.5rem 5.5rem;
  }

  .form-beats_label {
    font-size: 1.7rem;
  }

  .form-beats-input {
    font-size: 1.5rem;
  }

  .form-beats-input:first-of-type {
    margin-bottom: 4.4rem;
  }
  .custom-textarea {
    padding: 0.5rem 2rem !important;
    height: 6rem !important;
  }
  .BPM {
    margin-bottom: 7.2rem;
  }

  .Checkmark-questions {
    font-size: 1.6rem;
  }

  .container {
    font-size: 1.8rem;
  }

  .Checkmark {
    width: 2.4rem;
    height: 2.4rem;
  }

  .container .Checkmark:after {
    width: 1.8rem;
    height: 1.8rem;
  }

  .tax {
    font-size: 1.6rem;
  }

  .radio-btn-flex {
    margin-bottom: 5.2rem;
  }

  .artwork_img-box h3 {
    font-size: 1.7rem;
  }

  .artwork_img-box p {
    font-size: 1.4rem;
  }

  .artwork_img-box {
    padding: 7.2rem 5.2rem 2rem;
  }

  .img_file-name {
    font-size: 1.8rem;
  }

  .upload-files {
    padding: 7.2rem 5.2rem 10rem;
    height: 100%;
  }

  .upload-files_heading {
    font-size: 1.7rem;
  }

  .upload-files_secondary {
    font-size: 1.5rem;
  }

  .file-upload-label {
    height: 10rem;
    font-size: 1.5rem;
  }

  .file-upload-label img {
    height: 2.8rem;
    width: auto;
  }

  .save-changes {
    font-size: 1.8rem;
    padding: 1.5rem 4.4rem;
  }

  .upload-container {
    margin-top: 4.4rem;
  }

  .genre-dropdown-content {
    width: calc(63.3% - 8rem - 5.2rem - 5.5rem);
  }

  .genre-dropdown-dark-content {
    width: calc(63.3% - 8rem - 5.2rem - 5.5rem - 7rem);
  }

  .reach-more-album-popup-content {
    width: calc(((100% - 14.8rem - 11.6rem) * 0.3425) - 3.4rem - 3.4rem);
  }
}

@media only screen and (max-width: 84.375em) {
  .form-imput_beats {
    width: 100%;
    border-top-right-radius: 15px;
    border-bottom-left-radius: 0;
  }

  .upload-form__form-input {
    flex-flow: row wrap;
  }

  .artwork_img-box {
    border-bottom-left-radius: 15px;
    border-top-right-radius: 0;
  }

  .form-input_artwork-upload {
    flex-direction: row;
    width: 100%;
  }

  .upload-files {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: space-between;
    padding: 7.2rem 5.2rem;
  }

  .upload-files div:first-of-type {
    margin-bottom: 4rem;
  }

  .file-upload-label {
    justify-content: center;
    gap: 7%;
  }
  .upload-container {
    width: 25%;
  }

  .genre-dropdown-content {
    width: calc(100% - 8rem - 8rem - 5.2rem - 5.5rem);
  }

  .genre-dropdown-dark-content {
    width: calc(100% - 8rem - 8rem - 5.2rem - 5.5rem - 7rem);
  }

  .form-input_song {
    width: 100%;
  }

  .artwork_img-box_song {
    width: 100%;
  }

  .artwork_img-box_song .user-file-upload-label {
    width: 100%;
    max-width: none;
  }

  .artwork_img-box_album {
    width: 35%;
  }

  .album-reach-more-container {
    width: 65%;
  }

  .reach-more-album-popup-content {
    width: calc(((100% - 16rem - 10px) * 0.65) - 5.2rem - 5.2rem);
  }
}

@media only screen and (max-width: 56.25em) {
  .upload-beats__heading-primary {
    font-size: 2.3rem;
  }

  .upload-beats {
    padding: 3.8rem 5.4rem;
  }

  .upload-beats__heading-secondary {
    margin-bottom: 3.2rem;
  }

  .upload-files {
    padding: 3.6rem 2.8rem;
  }

  .file-upload-label {
    gap: 0;
    justify-content: space-evenly;
    flex-direction: column;
  }

  .artwork_img-box {
    padding: 3.6rem 3.4rem;
  }

  .form-beats-input {
    padding: 3.6rem 3.4rem;
  }

  .genre-dropdown-content {
    width: calc(100% - 5.4rem - 5.4rem - 5.2rem - 5.5rem);
  }

  .genre-dropdown-dark-content {
    width: calc(100% - 8rem - 8rem - 5.2rem - 5.5rem - 2rem);
  }

  .upload-container {
    width: 30%;
  }

  .reach-more-album-popup-content {
    width: calc(((100% - 5.4rem - 5.4rem - 10px) * 0.65) - 3.4rem - 3.4rem);
  }
}

@media only screen and (max-width: 43.75em) {
  .upload-beats {
    padding: 3.2rem;
  }

  .save-changes {
    font-size: 1.6rem;
  }

  .upload-container {
    margin-top: 3.2rem;
  }

  .form-input_artwork-upload {
    flex-direction: column;
  }

  .artwork-thumbnail {
    height: 35rem;
  }

  .artwork-thumbnail {
    width: inherit;
    display: flex;
    justify-content: center;
  }

  .imageName-delete {
    justify-content: space-around;
  }

  .form-imput_beats {
    padding: 3.6rem;
  }

  .genre-dropdown-content {
    width: calc(100% - 3.2rem - 3.2rem - 3.6rem - 3.6rem);
  }

  .genre-dropdown-dark-content {
    width: calc(100% - 3.2rem - 3.2rem - 3.6rem - 3.6rem - 10rem);
  }

  .container:first-of-type {
    width: 50%;
  }

  .reach-more-category-container {
    width: calc(100% / 2 - 4rem / 2);
  }

  .file-dropdown-content .service-item {
    flex-basis: 50%;
  }

  .artwork_img-box_album,
  .album-reach-more-container {
    width: 100%;
  }

  .artwork_img-box_album .user-file-upload-label {
    width: 100%;
    max-width: none;
  }

  .reach-more-album-popup-content {
    width: calc((100% - 3.2rem - 3.2rem) - 3.4rem - 3.4rem);
  }
}

@media only screen and (max-width: 31.25em) {
  .form-beats-input {
    height: 2rem;
    padding: 2.5rem;
  }

  .form-imput_beats {
    padding: 2rem;
  }

  .upload-beats {
    padding: 4rem 1.8rem;
  }

  .genre-dropdown-content {
    width: calc(100% - 1.8rem - 1.8rem - 2rem - 2rem);
  }

  .genre-dropdown-dark-content {
    width: calc(100% - 1.8rem - 1.8rem - 2rem - 2rem - 14rem);
  }

  .reach-more-category-container {
    /* width: calc(100% / 1 - 4rem / 1); */
    width: 100%;
  }

  .upload-container {
    width: 45%;
  }

  .reach-more-album-popup-content {
    width: calc((100% - 1.8rem - 1.8rem) - 3.4rem - 3.4rem);
  }
}
