.user-profile {
  background-color: white;
  height: 100%;
  z-index: 1;
  width: 100%;
}
.Purchases {
  margin-top: 2%;
  margin-left: 7%;
  margin-right: 8%;
  margin-bottom: 11.5%;
  min-height: 40vh;
}
.profile {
  /* background-image: url("./../../images/user-background.png"); */
  background-repeat: no-repeat;
  width: 100%;
  height: 90%;
  background-size: 100%;
  background-position: center center;
}
.accepting__comissions__button {
  display: flex;
  align-items: center;
  margin-right: 14%;
}
.accepting__comissions__button h4 {
  margin-right: 1%;
  font-family: "Poppins", sans-serif;
  font-size: 1.5rem;
  font-weight: 500;
}
/* Hide the checkbox input */
.accept-commission {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}

/* Style for the toggle button container */
.toggle-button {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 30px;
}

/* Style for the slider */
.slider {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 15px;
  background-color: #ccc;
  cursor: pointer;
}

/* Style for the checked state of the slider (active state) */
.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 2px;
  bottom: 2px;
  border-radius: 50%;
  background-color: white;
  transition: 0.2s;
}

/* Style for the checked state of the checkbox */
.accept-commission:checked + .slider {
  background-color: #fe2b0d;
}

/* Style for the checked state of the slider (active state) */
.accept-commission:checked + .slider:before {
  transform: translateX(30px);
}
.overlay {
  background: #0d0d0d 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  opacity: 0.8;
  backdrop-filter: blur(15px);
  -webkit-backdrop-filter: blur(15px);
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.user-image {
  display: flex;
  justify-content: center;
  width: 19%;
  margin-right: 3.5%;
  align-self: center;
  margin-bottom: 5%;
}
.no-url {
  opacity: 0.4;
  pointer-events: none;
}
.profile-picture {
  margin-bottom: 0;
  width: 100%;
  aspect-ratio: 1;
  border-radius: 50%;
  object-fit: cover;
}
.user-information {
  display: flex;
  flex-direction: row;
  /* justify-content: center; */
  align-items: center;
  width: 100%;
  height: 95%;
  color: white;
  padding-top: 4.3%;
  padding-left: 8.5%;
}
.information {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 81%;
  /* align-items: center; */
}

.name h2 {
  font-family: "Gilroy Semi Bold";
  font-size: 4rem;
  font-weight: 800;
  text-align: left;
}
.tagline {
  font-family: "Poppins", sans-serif;
  font-size: 1.5rem;
  /* width: 77%; */
  display: flex;
  align-items: center;
}
.tagline p {
  overflow-x: hidden;
  white-space: nowrap;
  width: 52%;
}
.follow-information {
  display: flex;
  flex-direction: row;
  width: 26%;
  justify-content: space-between;
  margin-bottom: 3%;
  margin-top: 1.3%;
}
.follow-information h2 {
  font-family: "Poppins", sans-serif;
  font-size: 1.9rem;
}
.buttons-list {
  display: flex;
  flex-direction: row;
  margin-bottom: 3.5%;
  margin-top: 4%;
  /* justify-content: space-between; */
}
.hire {
  background-color: #fe2b0d;
  border-radius: 7px;
  border: none;
  font-family: "Poppins", sans-serif;
  font-size: 1.9rem;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
  margin-right: 9.5%;
  cursor: pointer;
  padding: 1% 3%;
  /* width: 25.5%; */
  aspect-ratio: 3.82;
  white-space: nowrap;
}

.hire-disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.hire-disabled-popup-content {
  margin-top: 1rem;
  font-size: 1.6rem !important;
}

.hire img {
  margin-right: 2rem;
  width: 3.1rem;
}
.follow {
  background-color: transparent;
  border-radius: 7px;
  border: 1px solid #ffffff;
  font-family: "Poppins", sans-serif;
  font-size: 1.9rem;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-right: 5.5%;
  padding: 1% 5.2%;
}
.social-link-container {
  display: flex;
  align-items: center;
  /* justify-content: center; */
  /* gap: 1.5rem; */
  width: 40%;
}

.social-link {
  aspect-ratio: 1;
  text-decoration: none;
  /* width: 6.55%; */
  width: 8%;
  border-radius: 50%;
  border: 2px solid #fe2b0d;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 2%;
  padding: 0.6%;
}
.social-link img {
  width: 100%;
}
.navigation {
  width: 100%;
  display: flex;
  background-color: #e6e6e6;
  padding-left: 10%;
  height: 7rem;
}
.navigation button {
  /* text-decoration: none; */
  color: #262626;
  font-family: inherit;
  font-size: 2.1rem;
  margin-right: 4%;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.active {
  position: relative;
}

.active::after {
  content: "";
  position: absolute;
  bottom: -3px;
  left: 0;
  width: 100%;
  height: 5px;
  background-color: #fe2b0d;
  border-radius: 7px;
}
.userBeats {
  display: flex;
  flex-direction: column;
  margin-top: 2%;
  margin-left: 7%;
  margin-right: 12%;
  margin-bottom: -17.5%;
  min-height: 30vh;
}

.user .beatsRows {
  margin-top: 0;
}

.heading {
  margin: 2.7% 0;
  width: 100%;
  font-family: "Poppins", sans-serif;
}
.heading-albums {
  margin: 2.7% 0 -5% 7%;
  width: 100%;
  font-family: "Poppins", sans-serif;
}
.heading-albums h3 {
  font-size: 2.4rem;
  font-weight: 500;
  color: #131313;
}
.heading h3 {
  font-size: 2.4rem;
  font-weight: 500;
  color: #131313;
}
.beat--item:hover {
  background-color: #f6f7f9;
}
.beats {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  width: 100%;
}
.beat--item {
  display: flex;
  justify-content: space-between;
  /* padding-top: 2.1%;
  padding-bottom: 2.1%; */
  white-space: nowrap;
  align-items: center;
  /* padding-right: 2%;
  padding-left: 2%; */
  padding: 2.1% 2%;
}
.number {
  width: 6%;
  font-family: "Poppins", sans-serif;
  font-size: 1.9rem;
  margin-right: 1%;
  /* padding-left: 1.5%; */
}
.beat--item img {
  width: 12.5%;
  aspect-ratio: 1;
  object-fit: cover;
  border-radius: 5px;
}
.beat-name {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 6.2%;
  /* align-items: center; */
}
.beat-name p {
  font-family: "Poppins", sans-serif;
  font-size: 1.75rem;
  color: #131313;
}
.beat-name h4 {
  font-size: 1.9rem;
  font-weight: 500;
  white-space: nowrap;
  margin-bottom: 1%;
}
.tag {
  padding: 1.6%;
  border: 1px solid #666666;
  border-radius: 15px;
  font-size: 1.5rem;
  color: #262626;
  margin: 0 0.5%;
}
.bpm {
  border-color: #fe2b0d;
  color: #fe2b0d;
  margin-right: 1.9%;
}
.edit-beat {
  background-color: #0064fe !important;
  /* border: none;
  border-radius: 5px;
  color: #fff;
  font-size: 1.5rem;
  margin-right: 1.3%;
  margin-left: 2%;
  cursor: pointer;
  padding: 1.7% 2.5%; */
}
.remove-beat {
  background-color: #262626 !important;
  width: 9% !important;
  /* border: none;
  border-radius: 5px;
  color: #fff;
  font-size: 1.5rem;
  cursor: pointer;
  padding: 1.7% 2.5%; */
}
.Purchases .beatsRows {
  margin-left: 0;
  margin-right: 0;
  /* margin-top: 0; */
}
.Purchases .download-purchased-beat {
  background-color: #262626 !important;
  width: 15% !important;
}
.download-purchased-beat {
  background-color: #262626 !important;
  width: 13% !important;
  /* border: none;
  border-radius: 5px;
  color: #fff;
  font-size: 1.7rem;
  cursor: pointer;
  padding: 2.7% 5.5%;
  display: flex;
  justify-content: center;
  align-items: center; */
}
.download-purchased-beat img {
  margin-right: 10%;
  width: 2.5rem;
}
.beat--item-div {
  display: flex;
  width: 50%;
  align-items: center;
  justify-content: flex-start;
}
.beat--item-div-tags {
  display: flex;
  width: 50%;
  /* width: 60%; */
  align-items: center;
  justify-content: flex-end;
}
.edit-pack {
  background-color: #0064fe;
  border: none;
  border-radius: 5px;
  color: #fff;
  font-size: 1.8rem;
  margin-right: 1.3%;
  margin-left: 2%;
  cursor: pointer;
  padding: 1.7% 2.5%;
}
.remove-pack {
  background-color: #262626;
  border: none;
  border-radius: 5px;
  color: #fff;
  font-size: 1.8rem;
  cursor: pointer;
  padding: 1.7% 2.5%;
}
.pack-name {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 4.5%;
  /* align-items: center; */
}
.pack-name p {
  font-family: "Poppins", sans-serif;
  font-size: 1.9rem;
  color: #131313;
}
.pack-name h4 {
  font-size: 1.9rem;
}

.read-more {
  color: #fe2b0d;
  margin-left: 1rem;
  cursor: pointer;
}

.read-more-popup-overlay {
  background-color: rgba(0, 0, 0, 0.8);
}

@media only screen and (max-width: 68.75em) {
  .hire {
    margin-right: 16%;
  }
}
@media only screen and (max-width: 56.25em) {
  .social-link {
    width: 11%;
  }
}
@media only screen and (max-width: 43.75em) {
  .social-link {
    width: 13%;
  }
  .user-image {
    width: 23%;
    margin-bottom: 3%;
  }
  .information {
    width: 77%;
  }
  .profile-picture {
    margin-bottom: 0 !important;
  }
  .Purchases .beat-rows--item {
    justify-content: space-between;
  }
  .Purchases .beat-rows--item .tags {
    display: none;
  }
  .Purchases .download-purchased-beat {
    width: 20% !important;
  }
  .userBeats {
    min-height: 13vh;
  }
}
@media only screen and (max-width: 37.5em) {
  .profile {
    background-size: 105%;
  }
  .user-information {
    flex-direction: column;
    padding-left: 8%;
    padding-right: 8%;
  }
  .user-image {
    width: 50%;
  }
  .user-image {
    margin-bottom: 2%;
    margin-right: 0;
  }
  .name h2 {
    text-align: center;
  }
  .tagline {
    justify-content: center;
    text-align: center;
  }
  .buttons-list {
    flex-direction: column;
  }
  .hire {
    margin-right: 0;
    aspect-ratio: 7;
    padding: 4% 0;
  }
  .accepting__comissions__button {
    margin-top: 10%;
    align-self: center;
    margin-right: 0;
  }
  .social-link-container {
    width: 80%;
    margin: 7% auto;
    margin-bottom: 2%;
    justify-content: space-between;
  }
  .social-link {
    width: 17%;
  }
  .navigation {
    width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
  }
  .navigation button {
    min-width: 40%;
    text-align: center;
    margin-right: 0;
  }
}
@media only screen and (max-width: 31.25em) {
  .profile {
    background-size: 125%;
  }
  .Purchases .download-purchased-beat {
    width: 25% !important;
  }
}
@media only screen and (max-width: 21.875em) {
  .profile {
    background-size: 150%;
  }
}
