.scroll-up__btn {
  position: fixed;
  /* bottom: 70px;
  right: 50px; */
  bottom: 9%;
  right: 4%;
  background-color: #f55446;
  border: none;
  border-radius: 50%;
  color: white;
  cursor: pointer;
  font-size: 16px;
  line-height: 48px;
  width: 6.4rem;
  height: 6.4rem;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.19);
  transition: all 0.2s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.scroll-up__btn:hover {
  transform: scale(1.1);
}
.scroll-up__btn:before {
  content: "3";
  font-size: 1.2em;
  position: absolute;
  color: black;
  background-color: #d4d4d4;
  top: -10%;
  left: 90%;
  margin-left: -0.7em;
  border-radius: 50%;
  /* border: solid 0.13em white; */
  /* border-radius: 10em; */
  width: 1.4em;
  height: 1.4em;
  line-height: 1.3em;
  border-color: inherit;
  transition: transform 0.5s ease-in;
}

@media only screen and (max-width: 68.75em) {
  .scroll-up__btn {
    bottom: 7%;
    right: 4%;
  }
}
@media only screen and (max-width: 56.25em) {
  .scroll-up__btn {
    bottom: 6%;
    right: 4%;
  }
}
@media only screen and (max-width: 43.75em) {
  .scroll-up__btn {
    bottom: 5%;
    right: 4%;
  }
}
