.register-logo-div {
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: 4.5%;
  padding-top: 2.25%;
  padding-right: 9%;
  background-color: transparent;
  border: none;
}

.register-logo-div a {
  margin-left: auto;
  text-decoration: none;
  color: #333333;
  font-size: 1.85rem;
}

.reset-container {
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 80vh;
}

.reset-container h4 {
  width: 27%;
  color: #333333;
  font-size: 2.2rem;
  font-weight: 500;
  margin-bottom: 5rem;
}

.reset__group {
  width: 27%;
}

.reset__group label {
  font-size: 1.6rem;
  margin-bottom: 0.5rem;
}

.reset-input-container {
  width: 100%;
  margin-bottom: 2.5rem;
  position: relative;
}

.reset-input-container input {
  width: 100%;
  aspect-ratio: 8.4;
}

.reset__group button {
  width: 100%;
  aspect-ratio: 7;
  font-size: 1.75rem;
  font-weight: 500;
  margin-top: 3.1rem;
}

.reset__group a {
  display: flex;
  justify-content: center;
  width: 100%;
  text-decoration: none;
  color: #eb4831;
  text-align: center;
  font-size: 1.5rem;
  margin-top: 3.75rem;
}

.reset-input-container img {
  padding: 1.3rem;
  width: 5.25rem;
  position: absolute;
  right: 0;
  cursor: pointer;
}
