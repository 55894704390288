.cart-checkout {
  background-color: #f6f7f9;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 5%;
  min-height: 70rem;
}
.cart-checkout-div {
  width: 60%;
  display: flex;
  flex-direction: column;
  margin-bottom: 10%;
}
.cart-checkout-heading {
  font-size: 3rem;
  font-weight: 600;
  color: #333333;
  margin-bottom: 3%;
}
.cart-checkout-heading h3 {
  font-size: 3rem;
  font-weight: 600;
  color: #333333;
}
.empty-cart-tag {
  margin-bottom: 4rem;
  font-size: 2.5rem;
  font-weight: normal;
}
.cart-checkout-items {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  padding-top: 3%;
  padding-left: 5%;
  padding-right: 5%;
}
.cart-checkout-topRow {
  width: 100%;
  display: flex;
  color: #131313;
  padding-bottom: 3%;
  border-bottom: 1px solid #d4d4d4;
}
.cart-checkout-item-detail-container {
  display: flex;
  align-items: center;
  width: 90%;
  justify-content: space-between;
}
.cart-checkout-topRow h3 {
  color: #131313;
  font-size: 2.3rem;
  font-weight: normal;
}
.cart-checkout-item {
  display: flex;
}
.cart-checkout-item {
  display: flex;
  align-items: center;
  padding-top: 5%;
  padding-bottom: 5%;
  border-bottom: 1px solid #d4d4d4;
}
.cart-checkout-item-detail__total {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.service-fee-right-contribute {
  position: relative;
  display: flex;
  align-items: center;
}
.service-fee-right-contribute-fee {
  width: 10%;
  margin-left: 1%;
  color: #131313;
  font-size: 2.3rem;
  font-weight: normal;
}
.cart-checkout-service-fee img {
  width: 3rem;
  aspect-ratio: 1;
  margin-right: 1rem;
}

.cart-checkout-service-fee img:last-of-type {
  cursor: pointer;
}
/* .cart-checkout-item-detail-container {
  display: flex;
  align-items: center;
  width: 100%;
} */
.cart-checkout-item-div {
  display: flex;
  width: 75%;
  align-items: center;
}
.cart-checkout-item-image {
  width: 15%;
  margin-right: 5%;
  aspect-ratio: 1;
  object-fit: cover;
}
.cart-checkout-topbar__beat {
  width: 65%;
}
.cart-checkout-topbar__price {
  width: 20%;
}
.cart-checkout-item-name {
  color: #131313;
  font-size: 2.3rem;
  font-weight: normal;
}
.cart-checkout-item-artist {
  color: #131313;
  font-size: 2.1rem;
  font-weight: normal;
}
.cart-checkout-item-price {
  width: 15%;
  /* margin-left: 3%; */
  color: #131313;
  font-size: 2.3rem;
  font-weight: normal;
  text-align: end;
}
.cart-checkout-item-remove-button {
  /* margin-left: 7%; */
  background-color: transparent;
  border: none;
  width: 10%;
}
.cart-checkout-item-remove-image {
  width: 25%;
  cursor: pointer;
}
.cart-checkout-item-details {
  white-space: nowrap;
  text-overflow: ellipsis;
  display: flex;
  justify-content: center;
  flex-direction: column;
  max-width: 76%;
  width: 76%;
}
.cart-checkout-item-details h3 {
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 80%;
}
.cart-checkout-total-price {
  display: flex;
  margin-top: 4%;
  margin-bottom: 3%;
  padding-bottom: 4%;
  border-bottom: 1px solid #d4d4d4;
}
.cart-checkout-total-price__div {
  width: 90%;
  display: flex;
  flex-direction: column;
}
.cart-checkout-total-price__main {
  width: 100%;
}
.cart-checkout-service-fee {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  color: #131313;
  font-size: 2.3rem;
  font-weight: normal;
}
.cart-checkout-service-fee h3 {

  color: #131313;
  font-size: 2.3rem;
  font-weight: normal;
}
.cart-checkout-total__amount{
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  color: #131313;
  font-size: 2.3rem;
  font-weight: normal;
}
.cart-checkout-total__amount h3{
  color: #131313;
  font-size: 2.3rem;
  font-weight: normal;
}
.cart-checkout-total-heading {
  color: #131313;
  font-size: 2.5rem;
  font-weight: normal;
  text-align: right;
  justify-content: end;
}
.cart-checkout-total-heading__amount {
  color: #131313;
  font-size: 2.5rem;
  font-weight: normal;
  width: 15%;
  text-align: left;
}
.cart-checkout-button-div {
  width: 100%;
  margin-bottom: 5%;
}
.cart-checkout-button {
  margin-left: auto;
  cursor: pointer;
  border: none;
  background-color: #fe2b0d;
  font-size: 1.9rem;
  color: #ffffff;
  align-items: center;
  display: flex;
  justify-content: center;
  width: 35%;

  padding-top: 2%;
  padding-bottom: 2%;
}
@media only screen and (max-width: 68.75em) {
  .cart-checkout-div {
    width: 80%;
  }
  .cart-checkout-item-detail-container {
    width: 88%;
  }
  .cart-checkout-item-remove-button {
    margin-left: 5%;
    width: 7%;
  }
}
@media only screen and (max-width: 56.25em) {
  .cart-checkout-div {
    width: 85%;
  }
}
@media only screen and (max-width: 43.75em) {
  .cart-checkout-div {
    width: 90%;
  }
  .cart-checkout-item-detail-container {
    flex-direction: column;
    align-items: start;
  }
  .cart-checkout-item-div {
    width: 85%;
  }
  .cart-checkout-item-image {
    width: 25%;
  }
  .cart-checkout-item-price {
    display: none;
  }
  .cart-checkout-item-div__price {
    display: initial;
  }
  .cart-checkout-item-detail-container {
    width: 85%;
  }
  .cart-checkout-item-remove-button {
    width: 10%;
  }
  .cart-checkout-total-heading {
    justify-content: start;
    width: auto;
  }
  .cart-checkout-item-detail-container:last-of-type {
    flex-direction: row;
    justify-content: end;
    width: 100%;
  }
  .cart-checkout-total-price .cart-checkout-item-price {
    display: initial;
    width: auto;
  }
  .cart-checkout-topRow {
    display: none;
  }
  .service-fee-text-content{
    width: 60%;
  }
}
@media only screen and (max-width: 31.25em) {
  .cart-checkout-item-remove-button {
    width: 15%;
    margin-left: 3%;
  }
  .cart-checkout-item-image {
    width: 30%;
  }
}
@media only screen and (max-width: 21.875em) {
  .cart-checkout-item-image {
    width: 35%;
  }
}
