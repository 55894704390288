.player {
  position: fixed;
  background-color: #ffffff;
  box-shadow: 21px 3px 30px #0a0a0a29;
  width: 100%;
  height: 12.3%;
  min-height: 11rem;
  bottom: 0;
  /* display: none; */
  align-items: center;
  justify-content: center;
  z-index: 999;
  transition: 0.5s ease-in;
}

.player-div {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 100%;
  justify-content: flex-start;
  /* align-items: center; */
  /* align-items: flex-start; */
  padding: 0 3%;
}
.currently_playing_song {
  background-color: #f2f2f2;
}
.player-image {
  margin-right: 1.2%;
  /* margin-left: 3%; */
  width: 4.45%;

  aspect-ratio: 1;
}
.player-image img {
  width: 100%;
  object-fit: cover;
  height: 100%;
  border-radius: 5px;
  /* aspect-ratio: 1; */
}

.beat-detail {
  width: 10%;
  display: flex;
  flex-direction: column;
  margin-right: 2%;
}
.beat-detail h3 {
  font-size: 1.5rem;
  color: #333333;
  margin-bottom: 0.8%;
  font-style: normal;
  font-weight: 600;
  cursor: pointer;
  overflow: hidden;
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.beat-detail p {
  font-size: 1.5rem;
  color: #333333;
  cursor: pointer;
}
.buy-beat-player {
  width: 3%;
}
.player-controls {
  display: flex;
  align-items: center;
  width: 7%;
  height: 50%;
}

.player-controls button {
  cursor: pointer;
  background-color: transparent;

  border: none;
  align-items: center;
  justify-content: center;
  width: 33%;
  height: 100%;
}

.player-controls img {
  width: 100%;
  height: 100%;
}

.player-controls svg {
  width: 100%;
  height: 100%;
}

.play-player img {
  width: 100%;
  height: 100%;
}

.play-player svg {
  width: 100%;
  height: 100%;
}

.player-duration {
  display: flex;
  align-items: center;
  margin-right: 1.5%;
  margin-left: 4%;
  /* width: 32.5%; */
  width: 47.5%;
}
.player-duration p {
  font-size: 1.7rem;
  color: #707070;
  width: 10%;
  text-align: center;
}
.player-duration p:last-child {
  margin-left: 2%;
}
.player-duration div {
  height: 3px;
  width: 80%;
}

.popup_player {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 11%;
  border: 1px solid black;
  /* height: 55%; */
  aspect-ratio: 1;
  border-radius: 50%;
  margin-right: 4%;
  /* margin-left: 3%; */
  cursor: pointer;
}

.buy-popup-player p {
  width: 15%;
  text-align: center;
}

.duration-bar::-moz-range-thumb {
  visibility: hidden;
}

.player_current_time {
  margin: 0 !important;
  margin-right: 2% !important;
  width: 10%;
}
.player_total_duration {
  margin: 0 !important;
  margin-left: 2% !important;
}
.popup_player-duration {
  width: 51%;
  height: 3px;
  background-color: #0a0a0a29;
}
.player-bar {
  width: 0%;
  background-color: #fe2b0d;
  height: 3px;
  display: flex;
}

.player-duration div span {
  width: 0%;
  height: 3px;
  background-color: #fe2b0d;
  display: flex;
}
.volume-slider {
  width: 100%;
  height: 100%;
}
.volume-control {
  display: inline-block;
  width: 2%;
  margin-right: 2%;
}

.volume {
  display: flex;
  width: 100%;
  cursor: pointer;
  background-color: transparent;
  border: none;
}
.player-volume-popup-content {
  transform-origin: top left;
  z-index: 2005 !important;
  /* transform: rotate(-90deg); */
  padding: 1.5rem 1.5rem;
  background-color: #fff;
  border: 1px solid #d4d4d4;
  border-radius: 6px;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  font-weight: 500;
  position: fixed !important;
  top: auto !important;
  bottom: 8.5% !important;
}
.volume svg {
  width: 100%;
  height: 100%;
}
.buy-beat-button {
  /* width: 6%; */
  width: 8.85%;
  aspect-ratio: 3;
  /* height: 37.6%; */
  margin-right: 2%;
  background-color: #fe2b0d;
  border: none;
  border-radius: 3px;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  cursor: pointer;
}

.buy-beat-button img {
  margin-right: 14%;
  width: 16%;
  height: 33%;
}
.queue-handler {
  display: flex;
  align-items: center;
  width: 10.5%;
  height: 33%;
}
.queue-handler div {
  width: 33%;
  height: 100%;
  /* margin-right: 21%; */
}
.queue-handler div {
  width: 33%;
  height: 100%;
}
.queue-handler button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  width: 100%;
  height: 100%;
}

.queue-handler svg {
  height: 100%;
  width: 100%;
}
.pause-player {
  width: 17.1%;
  height: 100%;
}
.pause-player img {
  width: 100%;
  height: 100%;
}

.player-like-popup-content {
  padding: 1rem 1.6rem;
  background-color: #fff;
  border: 1px solid #d4d4d4;
  border-radius: 6px;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  font-weight: 500;
  position: absolute;
}

.player-like-popup-content,
.player-queue-popup-content {
  position: fixed !important;
  top: auto !important;
  bottom: 9.5% !important;
  z-index: 2000 !important;
}

.player-queue-popup-content {
  background-color: #fff;
  border: 1px solid #d4d4d4;
  padding: 2.5rem 1.5rem;
  border-radius: 6px;
  color: black;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  /* justify-content: center; */
  display: flex;
  font-size: 1.5rem;
  /* margin-left: -19%; */
  left: auto !important;
  right: 2% !important;
  width: 28.2%;
  max-height: 60%;
  overflow-y: auto;
}

.queue-popup-row {
  display: flex;
  align-items: center;
  padding: 1.05rem 0;
  border-radius: 8px;
  cursor: pointer;
}

.queue-popup-row-playing {
  background-color: #f2f2f2;
}

.queue-popup-header {
  font-size: 1.7rem;
  font-weight: 500;
  margin-left: 1rem;
  margin-bottom: 3.45rem;
}

.track-number {
  font-size: 1.3rem;
  font-weight: 400;
  margin-right: 1%;
  width: 4%;
  text-align: center;
}

.queue-popup-row img {
  width: 15%;
  aspect-ratio: 1;
  object-fit: cover;
  margin-right: 7%;
  margin-left: 3%;
  border-radius: 2px;
}

.track-details h4 {
  letter-spacing: 0.5px;
  font-weight: 500;
  font-size: 1.5rem;
}

.track-details p {
  letter-spacing: 0.5px;
  font-size: 1.3rem;
}

.player-buy-popup-content {
  width: 70%;

  background-color: #ffffff;
  border-radius: 8px;
  z-index: 4000;
}

.buy-popup-container {
  display: flex;
  padding: 4.5rem 9rem;
  width: 100%;
  height: 100%;
}

.buy-popup-left-container {
  margin-right: 2.1rem;

  border: 1px solid #d4d4d4;

  border-radius: 4px;
}

.buy-popup-left-container,
.buy-popup-right-container {
  width: 50%;
  height: 100%;
}

.buy-popup-container {
  height: 81vh;
}

.buy-popup-left-container {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.buy-popup-left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2.2rem;
}

.buy-popup-album-art {
  margin: 0 auto;
  width: 38%;
  aspect-ratio: 1;
  object-fit: cover;
  border-radius: 15px;
}

.buy-popup-player {
  width: 100%;
  /* height: 5rem; */
  border: 1px solid #d4d4d4;
  border-radius: 8px;
  margin-top: 2.7rem;
  margin-bottom: 3.3rem;
  display: flex;
  align-items: center;
  padding: 2% 3%;
}

.buy-popup-left h4 {
  font-size: 1.6rem;
  font-weight: 500;
  letter-spacing: 0.5px;
  color: #333333;
}

.buy-popup-left p {
  margin-top: 0.8rem;
  font-size: 1.4rem;
  color: #666666;

  letter-spacing: 0.4px;
}

.buy-popup-left .tags-container {
  display: flex;
  align-items: center;
  margin: 2.4rem 0;

  max-width: 100%;
  flex-wrap: wrap;
}

.tags-container .tag {
  font-size: 1.2rem;
  padding: 0.6rem 1.1rem;
  margin-right: 0.6rem;
  border-radius: 10px;
  border: 1px solid black;
  margin-top: 1rem;
}

.buy-popup-price {
  width: 100%;
  height: 5.5rem;
  font-size: 1.8rem;
  font-weight: 500;
  letter-spacing: 0.6;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #efefef;
  margin-top: auto;
}

.buy-popup-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 2.2rem;
  height: 100%;
}

.buy-popup-right-bullets {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  overflow-y: auto;
}

.buy-popup-type {
  margin: 2.2rem 0;
  text-align: center;
  width: 100%;
  font-size: 2rem;
  color: #333333;
  padding-bottom: 1.7rem;
  border-bottom: 1px solid #d4d4d4;
}

.buy-popup-right-row {
  display: flex;
  align-items: center;
  /* height: 20%; */
  font-size: 1.5rem;
  color: #333333;
  /* margin-bottom: 3.3rem; */
}

.bullet {
  width: 1.7rem;
  aspect-ratio: 1;
  background-color: #333333;
  border-radius: 50%;
  margin-right: 1rem;
}

.buy-popup-right-row p {
  width: calc(100% - 2.7rem);
}

.buy-popup-right-buy {
  margin-top: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.buy-popup-right-buy div {
  height: 6rem;
  width: 60%;
  gap: auto;
  font-size: 1.5rem;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  cursor: pointer;
  white-space: nowrap;
}

.buy-popup-right-contribute {
  position: relative;
  width: 100%;
  height: 6rem;
  margin-top: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #131313;
  color: white;
  gap: 1.2rem;
  font-size: 1.5rem;
  cursor: pointer;
}

.buy-popup-right-contribute img {
  width: 3rem;
  aspect-ratio: 1;
}

.buy-popup-right-contribute img:last-of-type {
  position: absolute;
  right: -3.5rem;
  cursor: pointer;
}

.support-input-popup .button-container {
  margin-bottom: 6% !important;
}

.support-input-popup label {
  margin: 4%;
  margin-top: 5%;
  font-size: 2rem;
  margin-bottom: -8%;
}

.buy img,
.bid img {
  width: 3rem;
  margin-right: 5%;
  aspect-ratio: 1;
  object-fit: cover;
}

.buy {
  background-color: #fe2b0d;
  margin-right: 1rem;
}

.bid {
  background-color: #0064fe;
}

.close-popup {
  position: absolute;
  top: 0;
  right: 0;
  margin-right: -4rem;
  margin-top: -0.5rem;
  height: 3rem;
  width: 3rem;
  cursor: pointer;
  background-color: transparent;
  border: none;
}
.player-mobile-duration-bar {
  /* touch-action: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  display: block;
  position: relative;
  width: 100%;
  height: 2px;
  background: #ddd;
  border-radius: 4px; */
  height: 2px !important;
}
.player-mobile-duration {
  width: 100vw;
  /* margin-bottom: 2%; */
}
.close-popup img {
  width: 100%;
  aspect-ratio: 1;
  object-fit: cover;
}
.player-mobile-version {
  padding-top: 10%;
  padding-bottom: 10%;
}
.player-mobile-div {
  box-shadow: 21px 3px 30px #0a0a0a29;
  background-color: #ffffff;
  padding-left: 1%;
  /* padding-top: 1%; */
  width: 100%;
  height: 100%;
  padding-bottom: 1%;
  padding-right: 1%;
  display: flex;
  align-items: center;
  overflow: hidden;
  flex-direction: column;
  /* transition: 0.5s ease-in; */
}
/* .player-mobile-image-div {
  margin-right: 2%;
} */
.player-mobile-image {
  width: 6rem;
  border-radius: 5px;
  aspect-ratio: 1;
  margin-right: 3%;
}
.beat-mobile-detail {
  display: flex;
  white-space: nowrap;
  align-items: center;
  width: 100%;
  /* max-width: 60%; */
  /* flex-grow: 1; */
  white-space: nowrap;
  overflow: hidden;
  position: relative;
  height: 4rem;
}
.dot-seperator {
  font-size: 1.5rem;
  color: #333333;
}
.beat-mobile-moving {
  gap: 2%;
  display: flex;
}
.move-start1 {
  animation: moveText1 5s linear infinite;
  position: absolute;
}
.move-start2 {
  animation: moveText2 5s linear infinite;
  position: absolute;
}

.player-mobile-div-expanded {
  box-shadow: 21px 3px 30px #0a0a0a29;
  padding: 5%;
  padding-top: 2%;
}

.player-mobile-collapse-button {
  width: 7%;
}

.player-mobile-div-expanded .player-mobile-details {
  flex-direction: column;
  height: 90%;
}

.player-mobile-more-options-exp {
  width: 7%;
  aspect-ratio: 1;
  outline: none;
  border: none;
  background-color: transparent;
}

.player-mobile-div-expanded .player-mobile-details .player-mobile-image-div {
  margin-right: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 3%;
  height: 67%;
}

.player-mobile-div-expanded
  .player-mobile-details
  .player-mobile-image-div
  img {
  /* width: 40%; */
  width: auto;
  height: 100%;
  aspect-ratio: 1;
  /* margin: 0 auto; */
  margin-right: 0;
}

.player-mobile-div-expanded .player-mobile-details .beat-mobile-detail {
  width: 100%;
  margin-bottom: 3%;
}

.player-mobile-div-expanded .player-mobile-details .player-mobile-duration-bar {
  /* width: 100%; */
  /* justify-content: center; */
  margin-bottom: 4%;
}

.player-mobile-div-expanded .player-mobile-details .player-mobile-controller {
  width: 100%;
  justify-content: flex-start;
  align-items: center;
}
.player-mobile-queue-popup-overlay {
  z-index: 1005 !important;
}
.player-mobile-queue-popup-content {
  position: fixed !important;
  top: auto !important;
  bottom: 0 !important;

  background-color: rgba(255, 255, 255, 0.9);
  border: 1px solid #d4d4d4;
  padding: 2.5rem 1.5rem;
  border-radius: 6px;
  color: black;
  display: flex;
  flex-direction: column;
  font-size: 1.5rem;
  width: 100%;
  /* height: 70%; */
  max-height: 80%;
  overflow-y: auto;
  padding: 1rem;
}
.expanded-options .player-mobile-more-options img {
  transform: rotate(90deg);
}
.player-mobile-queue-popup-content .queue-popup-header {
  display: flex;
  align-items: center;
  width: 95%;
}

.player-mobile-queue-popup-content .queue-popup-header img {
  margin-left: auto;
  width: 7%;
}

@keyframes moveText1 {
  0% {
    left: 0%;
  }
  100% {
    left: -100%;
  }
}

@keyframes moveText2 {
  0% {
    left: 100%;
  }
  100% {
    left: 0;
  }
}

.player-mobile-controls {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60%;
}
.expanded-player-controls {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* width: 70%; */
  width: 100%;
  position: relative;
  padding: 0 17%;
}
.expanded-player-controls button {
  cursor: pointer;
  border: none;
  background-color: transparent;
  width: 5rem;
  height: 5rem;
}

.expanded-player-controls button svg {
  width: 100%;
  height: 100%;
}

.player-mobile-controls button {
  cursor: pointer;
  border: none;
  background-color: transparent;
  width: 5rem;
  height: 3rem;
}
.player-mobile-details {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  gap: 2%;
}
.like-beat-mobile-player {
  cursor: pointer;
  border: none;
  background-color: transparent;
  width: 100%;
  height: 100%;
}
.like-beat-mobile-player svg {
  width: 100%;
  height: 100%;
}
.expanded-options {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
@keyframes moveText {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}
.like-beat-mobile-player {
  width: 5rem;
  height: 5rem;
}

.expanded-player-controls button:first-of-type {
  position: absolute;
  left: 0;
}

.player-mobile-duration-bar .range-slider__thumb[data-lower] {
  width: 0;
}

.player-mobile-duration-bar .range-slider__thumb[data-upper] {
  width: 0;
}

.player-mobile-duration-bar .range-slider__range {
  border-radius: 6px;
  background-color: #fe2b0d !important;
}
.player-mobile-controller {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 30%;
  gap: 8%;
  /* margin-left: auto; */
}
.beat-mobile-detail-name {
  font-size: 1.5rem;
  color: #333333;
  /* margin-bottom: 0.8%; */
  font-style: normal;
  font-weight: 600;
  cursor: pointer;
  max-width: 13rem;
  overflow: hidden;
  text-overflow: ellipsis;
}
.beat-mobile-detail-artist {
  font-size: 1.5rem;
  /* clip-path: inset(0 0 0 6rem); */
  color: #333333;
  cursor: pointer;
}
.player-duration-bar .range-slider__thumb[data-lower] {
  width: 0;
}

.player-duration-bar .range-slider__thumb[data-upper] {
  width: 0;
}

.player-duration-bar .range-slider__range {
  border-radius: 6px;
  background-color: #fe2b0d !important;
}
.player_play_image {
  width: 50%;

  aspect-ratio: 1;
  margin: 0;
  object-fit: contain;
}
.beat-player-more-content {
  margin-top: -1rem;
  /* position: fixed !important; */
  padding: 0 2rem;
  background-color: #fff;
  border: 1px solid #d4d4d4;
  border-radius: 6px;
  color: black;
  z-index: 1001 !important;
}

.player-loading {
  /* width: calc(33% - 20px); */
  width: 33%;
  /* margin: 0 10px; */
  aspect-ratio: 1;
}

.player-loading path {
  stroke-width: 3;
  /* margin: 1rem; */
  /* border: 1px solid; */
  /* height: 100%; */
  /* padding: 5px; */
}

@media only screen and (max-width: 68.75em) {
}
@media only screen and (max-width: 56.25em) {
  /* .player-image {
    height: 60%;
    margin-left: 1%;
  } */

  .player {
    height: 10.3%;
  }

  .beat-detail {
    width: auto;
    margin-left: 1%;
    white-space: nowrap;
    margin-right: 2%;
  }

  .player-controls {
    width: 11%;
  }
  .player-duration {
    margin-left: 2%;
    width: 31%;
  }
  .player-duration p {
    width: 19%;
  }
  .player-duration div {
    width: 65%;
  }
  .volume-control {
    width: 3%;
  }
  .buy-beat-button {
    width: 13%;
    height: 28.6%;
    margin-right: 2%;
  }
  .queue-handler {
    width: 16.5%;
    height: 25%;
    margin-right: 1%;
  }
  .player-buy-popup-content {
    width: 87%;
  }
  .buy-popup-container {
    padding: 4.5rem 2rem;
  }
  .buy-popup-left {
    padding: 1.2rem;
  }
  .buy-popup-player p {
    width: 15%;
  }
  .popup_player-duration {
    width: 45%;
  }
  .buy-popup-type {
    margin-top: 0.2rem;
  }
  .buy-popup-right {
    padding: 2.2rem;
  }
  .buy-popup-right-row {
    margin-bottom: 1.3rem;
  }
  .buy-popup-right-buy {
    margin-top: 15%;

    flex-direction: column;
  }
  .buy {
    margin-right: 0;
  }
  .buy-popup-right-buy div {
    width: 77%;
    margin-bottom: 2%;
  }
}
@media only screen and (max-width: 43.75em) {
  .player-image {
    display: none;
  }
  .beat-detail {
    width: 25%;
    margin-left: 1%;
    white-space: nowrap;
  }
  .player {
    height: 8.3%;
  }
  .beat-player-more-content {
    width: 35%;
  }
  .player-controls {
    height: auto;
    width: 11%;
  }
  .player-duration {
    margin-left: 2%;
    width: 31%;
  }
  .player-duration p {
    width: 25%;
  }
  .player-duration div {
    width: 52%;
  }
  .volume-control {
    width: 3%;
  }
  .buy-beat-button {
    width: 13%;
    height: 37.6%;
    margin-right: 2%;
  }
  .queue-handler {
    width: 15.5%;
    height: 25%;
  }
}
@media only screen and (max-width: 31.25em) {
  .player-controls {
    width: 10%;
  }
  .player-duration {
    width: 35%;
  }
  .player-duration div {
    width: 80%;
  }
  .player-image {
    margin-right: 1.2%;
    height: 50%;

    aspect-ratio: 1;
  }
  .beat-detail {
    width: 15%;
  }
}
@media only screen and (max-width: 21.875em) {
}
@media only screen and (max-width: 37.5em) {
  .player-buy-popup-content {
    width: 80%;
  }
  .buy-popup-container {
    flex-direction: column;
    padding: 1.5rem 2rem;
    height: 75rem;
    overflow-y: scroll;
  }
  .buy-popup-left-container {
    width: 100%;
    margin-right: 0;
  }
  .buy-popup-type {
    display: none;
  }
  .buy-popup-right-row {
    display: none;
  }
  .buy-popup-right-container {
    width: 100%;
  }
  .buy-popup-right-buy {
    flex-direction: column;
  }
  .buy {
    margin-right: 0;
  }
  .buy-popup-right-buy div {
    margin-bottom: 2%;
    width: 70%;
  }
  .popup_player-duration {
    width: 47%;
  }
  .buy-popup-player p {
    width: 14%;
  }
}
