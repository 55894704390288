.music-carousel-slide-container {
  width: 100%;
  height: 100%;
  aspect-ratio: 2.4;
  padding: 5%;
}
.music-carousel-slide-container-mobile {
  width: 45%;
  height: 100%;
  aspect-ratio: 2.4;
  padding: 5%;
}
.music-carousel-slide {
  background-color: white;
  width: 100%;
  height: 100%;
  border-radius: 6px;
  box-shadow: 0 0 5px 5px rgba(255, 255, 255, 0.1);
  position: relative;
  cursor: pointer;
}

.music-carousel-slide > img {
  object-fit: cover !important;
  width: 100%;
  height: 100%;
}

.music-carousel-filter {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.music-carousel-info {
  position: absolute;
  bottom: 3.5rem;
  left: 3.5rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.music-carousel-info > h2 {
  font-size: 3.2rem;
  color: white;
  line-height: 1.25;
  font-weight: 600;
  letter-spacing: 0.5px;
  cursor: default;
}

.music-carousel-info > h2 > span {
  cursor: pointer;
}

.music-carousel-info > button {
  background-color: #fe2b0d;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.3rem 4rem;
  border-radius: 4px;
  outline: none;
  border: none;
  cursor: pointer;
  gap: 1rem;
  font-size: 1.7rem;
  color: white;
  width: fit-content;
  font-weight: 400;
}

.music-carousel-info > button > img {
  aspect-ratio: 1;
  width: 2rem;
}
.carousel__button__landing {
  background-color: transparent;
  border: none;
  outline: none;
  width: 10%;
  cursor: pointer;
}
.carousel__button__landing img {
  width: 40%;
}
.mobile__carousel__nav {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
}
@media only screen and (max-width: 37.5em) {
  .music-carousel-info{
    align-items: center;
  }
}