.beats-topbar {
  background-color: #0c0c0c;
  padding-top: 3%;
  padding-left: 7.5%;
  padding-bottom: 2.6%;
  padding-right: 7.5%;
  display: flex;
  flex-direction: column;
  color: white;
}
.beats-topbar h3 {
  font-size: 2rem;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 2%;
}
.display-options {
  display: flex;
  padding-bottom: 2.6%;
  border-bottom: 1px solid #d4d4d4;
  gap: 2.5%;
  /* margin-bottom: 3.5%; */
}
.beats-button a {
  display: flex;
  align-items: center;
}
.display-options button {
  /* padding: 1% 2% 1% 1.5%; */
  padding: 1% 2%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.75rem;
  background-color: transparent;
  border: 1px solid #ffffff;
  /* margin-right: 2.5%; */
  border-radius: 11px;
  color: white;
  cursor: pointer;
  white-space: nowrap;
}
.display-options-buttons {
  display: flex;
  width: 45%;
}
.display-options button img {
  width: 2.8rem;
}

.beats-option img {
  margin-right: 1.6rem;
}
.producers-option {
  background-color: transparent;
  border: 1px solid #fff;
  padding: 1% 1% 1% 1.2%;
}
.producers-option img {
  margin-right: 0.4rem;
}
.beats-search {
  display: flex;
  align-items: flex-start;
}
.beats-search-box {
  border: none;
  background-color: #d4d4d426;
  /* margin-right: 1.5%; */
  padding: 1.1% 0 1.1% 1.6%;
  border-radius: 29px;
  display: flex;
  align-items: center;
  width: 100%;
}
.beats-search-icon {
  width: 2.7%;
  margin-right: 1.5%;
  cursor: pointer;
}
.beats-search-input {
  background-color: transparent;
  border: none;
  color: white;
  font-size: 1.5rem;
  width: 100%;
}
.beats-search-input:focus {
  border: none;
  outline: none;
}
.beats-search-options {
  display: flex;
  align-items: center;
  /* border-left: 1px solid #d4d4d4; */
  /* padding-left: 1.4%; */
  /* width: 72%; */
  width: 68.7%;
  /* flex-wrap: wrap; */
  overflow-x: auto;
  padding-bottom: 1%;
}

.search-option-button {
  border: 1px solid #d4d4d4;
  color: white;
  font-size: 1.5rem;
  border-radius: 29px;
  background-color: transparent;
  padding: 1.4% 1.9%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1.3%;
  /* margin-bottom: 1rem; */
  cursor: pointer;
}

.search-option-button:last-of-type {
  margin-right: 0;
}

.search-option-button p {
  white-space: nowrap;
}

.search-option-button img {
  margin-right: 9%;
}
.filters {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* margin-top: 3%; */
  margin-top: 2.6%;
}
.filters-dropdowns {
  display: flex;
  margin-left: 3%;
  width: 100%;
}
.filters-div {
  display: flex;
  flex-direction: row;
  /* width: 50%; */
  width: 25%;
  font-size: 1.8rem;
  min-width: 32rem;
  align-items: center;
}
.filters-div button {
  display: flex;
  align-items: center;
  background-color: transparent;
  border: none;
  color: white;
  margin-right: 5%;
  cursor: pointer;
  font-size: 1.8rem;
}
.filters-div img {
  width: 2.7rem;
}
.filters-div h4 {
  font-size: 1.8rem;
  font-weight: 500;
}
.filter-tiles {
  border-radius: 6px;
  border: 1px solid #d4d4d419;
  padding: 0.4% 0.5%;
  display: flex;
  align-items: center;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 6.3%;
  min-width: 8rem;
  margin-left: 2%;
}
.filter-tiles button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.row-image {
  opacity: 0.3;
}
.filter-tiles button:first-child {
  padding-right: 13%;
  border-right: 1px solid #d4d4d430;
  padding-left: 10%;
}
.filter-tiles button:last-child {
  padding-left: 10%;
  padding-right: 12%;
}

.filter-popup-content {
  margin: auto;
  background-color: #fff;
  border: 1px solid #d4d4d4;
  border-radius: 12px;
  color: black;
  font-size: 1.9rem;
}

/* Whole page overlay */
.popup-overlay {
  background: rgba(0, 0, 0, 0.5);
}
[data-popup="tooltip"].popup-overlay {
  background: transparent;
}

.genre-dropdown {
  width: 22rem;
  height: fit-content;
  padding: 1.5rem 2rem;
  display: flex;
  flex-direction: column;
}
.service-item {
  font-size: 1.8rem;
  margin: 0.5rem 0;
  font-weight: 400;
}

.bpm-dropdown {
  width: 27rem;
  display: flex;
  padding: 3rem 2.5rem;
}

.bpm-dropdown p {
  margin: 0 2rem;
  font-size: 1.5rem;
}

.bpm-dropdown input {
  border: none;
  width: 100%;
  border-bottom: 1px solid #707070;
  font-size: 1.75rem;
  padding-left: 1rem;
  color: #333333;
}
/* .bpm-dropdown input:first-child {
  margin-right: 2.3rem;
}
.bpm-dropdown input:last-child {
  margin-left: 2.1rem;
} */
.bpm-dropdown input:focus {
  outline: none;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* input[type="number"] {
  -webkit-appearance: none;
  -moz-appearance: textfield;
} */
/* input[type="number"] {
  -moz-appearance: textfield;
} */

.price-dropdown {
  display: flex;
  flex-direction: column;
  /* padding-top: 3.8rem;
  padding-left: 0.9rem;
  padding-right: 0.9rem;
  padding-bottom: 0.5rem; */
  padding: 3rem 2.5rem;
  margin: auto;
  background-color: #fff;
  border: 1px solid #d4d4d4;
  border-radius: 12px;
  color: black;
  font-size: 1.9rem;
}
.dropdown-selected {
  display: flex;
}
.price-range {
  display: flex;
  padding-bottom: 3.3rem;
  border-bottom: 1px solid #d4d4d4;
  align-items: center;
  justify-content: center;
  margin-bottom: 2.3rem;
}
.price-range input {
  border: none;
  width: 11rem;
  border-bottom: 1px solid #707070;
  font-size: 1.75rem;
  padding-left: 1rem;
  color: #333333;
}

.price-range p {
  margin: 0 2rem;
  font-size: 1.5rem;
}
/* .price-range input:first-child {
  margin-right: 2.3rem;
}
.price-range input:last-child {
  margin-left: 2.1rem;
} */
.price-range input:focus {
  outline: none;
}
.price-dropdown .service-item {
  /* padding-left: 2.6rem; */
  margin: 1rem 0;
}

.beatsTracks {
  margin-top: 7.5%;
  margin-left: 7%;
  margin-right: 7%;
  margin-bottom: 11.5%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.beats-music-card span {
  color: #f55446;
}
.beats-price {
  font-size: 1.5rem;
  margin: 0.3rem 0;
}

.beats-music-card--music-name {
  font-size: 1.8rem;
  font-weight: 400;
  color: black;
}
.beats-music-card--artist-price {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.5rem;
  opacity: 0.65;
  color: black;
}

.beats-music-card-artist-name {
  font-size: 1.5rem;
  font-weight: 400;
}

.beats-play-div {
  /* border: 1px solid white; */
  /* background-color: red; */
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.beats-extras button,
.beats-extras__placeholder {
  width: 11%;
  height: 75%;
  background-color: transparent;
  border: none;
}

.beats-cards {
  width: 100%;
  /* display: flex;
  justify-content: flex-start;
  flex-wrap: wrap; */

  display: grid;
  gap: 1rem;
  /* width: max-content; */
  /* height: 100%; */
  /* width: fit-content; */
  /* grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); */
}

.beats-music-card {
  position: relative;
  margin: 6% 0.75%;
  margin-top: 0;
  cursor: pointer;

  /* aspect-ratio: 0.775; */
  /* margin-bottom: 6%; */
  /* margin-right: 1.5%; */
  /* flex-grow: 0; */
  /* width: 18%; */
}

.temp {
  width: 100%;
}

/* .beats-music-card:nth-child(5n) {
  margin-right: 0;
} */

.beats-play_button {
  position: absolute;
  width: 100%;
  aspect-ratio: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  background-image: linear-gradient(359deg, #0d0d0d 0%, #13121200 100%);
  opacity: 0;
  transition: 0.5s ease-out;
  overflow: hidden;
  border-radius: 6px;
  /* cursor: pointer; */
}
.beats-play_button:hover,
.beats-play_button:focus {
  opacity: 1;
}

.beats-play_button-hovered {
  opacity: 1;
}

.beat-rows--item:hover .beats-rows-play_button {
  opacity: 1;
}

.beats-music-card--img {
  width: 100%;
  aspect-ratio: 1;
  object-fit: cover;
  border-radius: 6px;
}

.beats-play {
  width: 33.5%;
  height: 34%;
  border-radius: 50%;
  background-color: #f55446;
  border: none;
  opacity: 0.8;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.beats-play img {
  width: 95%;
  height: 70%;
  cursor: pointer;
}
.beats-extras {
  position: absolute;
  bottom: 0;
  height: 12%;
  display: flex;
  width: 80%;
  justify-content: space-between;
}

.beats-extras button {
  cursor: pointer;
}

.beatsRows {
  margin-top: 7.5%;
  margin-left: 7%;
  margin-right: 6.5%;
  margin-bottom: 11.5%;
  display: flex;
  flex-direction: column;
}
.beats-rows {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  width: 100%;
}

.beat-rows--item {
  display: flex;
  /* justify-content: space-between; */
  /* margin: 0.9% 0; */
  align-items: center;
  border-radius: 12px;
  cursor: pointer;
  width: 100%;
  padding: 2% 0;
}
.beat-rows-number {
  width: 6%;
  font-family: "Poppins", sans-serif;
  font-size: 1.9rem;
  margin-right: 5%;
  padding-left: 2.7%;
}
.beat-rows-details {
  display: flex;
  align-items: center;
  text-align: left;
  width: 30%;
}
.beat-rows-name p {
  width: 100%;
  text-align: left;
  font-family: "Poppins", sans-serif;
  font-size: 1.75rem;
  color: #131313;
}
.beat-rows-name h4 {
  font-size: 1.9rem;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  text-overflow: ellipsis;
}

.beat-rows-name {
  width: 64%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 6.5%;
  align-items: baseline !important;
}

.beat-rows-image {
  position: relative;
  width: 20%;
  aspect-ratio: 1;
  min-width: 6rem;
  margin-left: 1rem;
}

.beats-rows-play_button {
  top: 0;
  position: absolute;
  width: 100%;
  aspect-ratio: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  background-image: linear-gradient(359deg, #0d0d0d 0%, #13121200 100%);
  opacity: 0;
  transition: 0.5s ease-out;
  overflow: hidden;
  border-radius: 6px;
}

.beats-rows-play {
  cursor: pointer;
  width: 50%;
  aspect-ratio: 1;
  padding: 10%;
  border-radius: 50%;
  background-color: #f55446;
  border: none;
  opacity: 0.8;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.beat-rows-image img {
  width: 100%;
  aspect-ratio: 1;
  border-radius: 5px;
  object-fit: cover;
}
.tags {
  display: flex;
  width: 40%;
  justify-content: flex-end;
  margin-right: 2%;
  white-space: nowrap;
  align-items: center;
  flex-wrap: wrap;
  margin-left: auto;
}

.beat-rows--item button {
  cursor: pointer;
}

.beat-rows-tag {
  height: 30%;
  padding: 0.6rem 1rem;
  border: 1px solid #666666;
  border-radius: 15px;
  /* font-size: 1.8rem; */
  font-size: 1.5rem;
  color: #262626;
  margin-right: 0.9rem;
  cursor: default;
  display: flex;
  align-items: center;
  justify-content: center;
}

.beat-rows-tag img {
  /* width: 85%; */
  height: 2.4rem;
  aspect-ratio: 1;
  object-fit: contain;
  /* cursor: pointer; */
}

.extra-genres-popup-content {
  padding: 1rem 1.6rem;
  background-color: #fff;
  border: 1px solid #d4d4d4;
  border-radius: 6px;
  color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 1.4rem;
  font-weight: 400;
  margin-top: -0.4%;
  z-index: 100 !important;
}

.beat-rows-bpm {
  border-color: #fe2b0d;
  color: #fe2b0d;
  margin-right: 2.1%;
}
.buy-beat,
.download-beat {
  /* width: 14.3%; */
  height: 30%;
  background-color: #fe2b0d;
  border: none;
  border-radius: 4px;
  font-size: 1.5rem;
  letter-spacing: 0.5px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  margin: 0 2%;
  outline: none;
  min-width: 14rem;
}
.edit-music::after,
.remove-music::after {
  content: "\00a0 Track";
}
.edit-album::after,
.remove-album::after {
  content: "\00a0 Album";
}
.edit-music,
.edit-album {
  background-color: #0064fe !important;
}
.remove-music,
.remove-album {
  background-color: #262626 !important;
  width: 9% !important;
}
.buy-beat {
  width: 7.7%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.edit-beat::after,
.remove-beat::after {
  content: "\00a0 Beat";
}

.download-beat {
  width: 9.6%;
  background-color: #131313;
  margin-right: 1%;
}

.buy-beat,
.download-beat {
  padding: 0.7% 0.8%;
}

.buy-beat img {
  width: 20%;
  min-width: 2rem;
}

.download-beat img {
  width: 20%;
  min-width: 2rem;
}

.buy-beat img,
.download-beat img {
  aspect-ratio: 1;
  object-fit: contain;
  border: none;
  margin-right: 11%;
}
.more-options {
  background-color: transparent;
  border: none;
  width: 3%;
}
.more-options img {
  width: 3.1rem;
}

.beat-rows-popup-content {
  margin-top: -1rem;
  width: 14%;
  padding: 0 2rem;
  background-color: #fff;
  border: 1px solid #d4d4d4;
  border-radius: 6px;
  color: black;
  z-index: 997 !important;
}

.popup-option {
  margin: 2.6rem 0;
  font-size: 1.6rem;
  cursor: pointer;
}

.beat-rows--item:hover {
  background-color: #f6f7f9;
}
.producers {
  /* display: flex; */
  margin-top: 7.5%;
  margin-left: 7%;
  margin-right: 6.5%;
  margin-bottom: 11.5%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.producer-artist {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 1rem;
  /* display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center; */
  width: 95%;
}
.producer-artist div {
  display: flex;
  flex-direction: column;
  text-align: center;
}
.producer-artist span {
  margin-top: 9%;
  font-size: 1.9rem;
  color: #333333;
}
.producer-artist p {
  margin-top: 1.5%;
  font-size: 1.8rem;
  color: #666666;
  margin-bottom: 1%;
}
.producer--item {
  /* width: 15%; */
  margin-right: 4.2%;
  margin-bottom: 4%;
  cursor: pointer;
}
/* .producer--item:nth-child(5n) {
  width: 15%;
} */
.producer-artist-img-holder {
  width: 100%;
}
/* .beats-open {
  display: flex;
} */
.button-selected {
  border: none !important;
  background-color: #f55446 !important;
}

.checkbox-container input {
  margin-right: 2.4rem;
}

.search-input-container {
  position: relative;
  width: 88%;
}

.search-input-container img {
  /* padding: 0.9rem; */
  height: 100%;
  position: absolute;
  right: -6%;
  cursor: pointer;
}

.search-option-button-selected {
  background-color: #f55446;
  border: 1px solid #f55446;
}

.add-queue-popup-overlay {
  background-color: transparent;
}

.add-queue-popup-content {
  margin-bottom: 1% !important;
  padding: 1.3rem 2rem;
  background-color: #fff;
  border: 1px solid #d4d4d4;
  box-shadow: 2px 2px #0a0a0a29;
  margin-top: 0.6rem;
  border-radius: 6px;
  color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 1.7rem;
  letter-spacing: 0.4px;
  font-weight: 400;
  /* opacity: 0; */
  /* animation: queuePopupAnimation 1.5s ease-in-out;
  animation-fill-mode: forwards; */
}

.add-queue-player-popup-content {
  margin-bottom: 7% !important;
}

@keyframes queuePopupAnimation {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.bpm-dropdown img {
  /* padding: 0.9rem; */
  width: 8%;
  aspect-ratio: 1;
  position: absolute;
  right: 9%;
  cursor: pointer;
}

.price-range img {
  width: 7%;
  aspect-ratio: 1;
  position: absolute;
  right: 8%;
  cursor: pointer;
}
@media only screen and (max-width: 68.75em) {
  .filter-tiles {
    width: 12%;
  }
  .beat-rows-number {
    margin-right: 3rem;
  }
  .beat-rows-name {
    margin-left: 2.3rem;
  }
}
@media only screen and (max-width: 56.25em) {
  .filter-tiles {
    width: 15%;
    min-width: 9rem;
  }
  .display-options button {
    font-size: 1.5rem;
  }
  .beats-search-icon {
    width: 3.4%;
  }
  .beats-option img {
    margin-right: 1.3rem;
  }
  .search-option-button {
    padding: 1% 1.9%;
  }
  .search-option-button img {
    margin-right: 5%;
    /* width: 100%; */
  }
  .beatsTracks {
    width: 85%;
  }
  .download-beat {
    display: none;
  }
  .buy-beat {
    min-width: 8rem;
  }
  .buy-beat::after {
    content: "";
  }
  .buy-beat img {
    display: none;
  }
  .beat-rows-name {
    margin-left: 2.5rem;
  }
  .beat-rows-tag {
    font-size: 1.2rem;
  }
  .beat-rows-tag img {
    height: 1.8rem;
  }
  .beat-rows-name p {
    white-space: nowrap;
  }
  .search-option-button img {
    width: 2.3rem;
  }
  .beat-rows-popup-content {
    width: 20%;
  }
}
@media only screen and (max-width: 43.75em) {
  .display-options {
    flex-wrap: wrap;
  }
  .beats-search-box {
    margin-top: 2%;
    padding: 2.1% 0 2.1% 2.6%;
  }
  .filters {
    flex-direction: column;
    align-items: flex-start;
    position: relative;
  }
  .filter-tiles {
    position: absolute;
    right: 0;
  }
  .filters-div {
    margin-bottom: 2%;
  }
  .beats-search-options {
    width: 100%;
    padding-bottom: 1%;
  }
  .filter-tiles img {
    width: 2.5rem;
  }
  /* .filter-tiles {
    display: none;
  } */
  .beat-rows-tag {
    display: none;
  }
  .beat-rows-details {
    width: 50%;
  }
  .beat-rows-popup-content {
    width: 27%;
  }

  .button-selected-music {
    margin-top: 2%;
    margin-left: auto;
    padding: 2% !important;
  }
}
@media only screen and (max-width: 31.25em) {
  .display-options button {
    font-size: 1.7rem;
    padding: 2% 3%;
  }
  .beats-search-box {
    padding: 2.1% 0 2.1% 2.6%;
  }
  .search-option-button img {
    width: 2.3rem;
  }
  .buy-beat {
    min-width: 9rem;
    padding: 2% 0.8%;
  }
  .edit-beat {
    min-width: 5rem;
  }
  .price-range {
    flex-direction: column;
    text-overflow: ellipsis;
  }
  .beat-rows-name h4 {
    white-space: nowrap;
    overflow: hidden;
    max-width: 85%;
    text-overflow: ellipsis;
  }
  .beat-rows-name p {
    white-space: nowrap;
    overflow: hidden;
    max-width: 85%;
    text-overflow: ellipsis;
  }
  .price-range p {
    margin: 1rem 0rem;
    margin-top: 2rem;
  }
}
@media only screen and (max-width: 21.875em) {
  .filters-div {
    flex-direction: column;
    align-items: flex-start;
  }
  .filters-dropdowns {
    margin-left: 0;
  }
  .search-option-button img {
    width: 2rem;
  }
  .checkbox-container input {
    width: 20px;
    height: 20px;
  }
  .buy-beat {
    min-width: 8rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 1% 0.8%;
  }
  .edit-beat {
    min-width: 5rem;
  }
  .beat-rows-popup-content {
    width: 30%;
  }
  .beat-rows-name {
    margin-left: 2.2rem;
  }
  .beat-rows-name h4 {
    max-width: 70%;
  }
  .beat-rows-name p {
    max-width: 70%;
  }
}
@media (min-width: 600px) {
  .beats-cards,
  .producer-artist {
    grid-template-columns: repeat(3, 1fr) !important;
  }
}

@media (min-width: 900px) {
  .beats-cards,
  .producer-artist {
    grid-template-columns: repeat(5, 1fr) !important;
  }
}
@media (min-width: 300px) {
  .beats-cards,
  .producer-artist {
    grid-template-columns: repeat(2, 1fr);
  }
}
