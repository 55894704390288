.editProfile {
  background-color: #f6f7f9;
  display: flex;
  flex-direction: column;
  padding-top: 3.5%;
  padding-left: 8%;
}
.edit-profile-main {
  display: flex;
}
.edit-profile-main {
  margin-bottom: 15%;
}
.options {
  margin-bottom: 33%;
  width: 18.5%;
}
.editProfile h3 {
  font-family: "Poppins", sans-serif;
  font-size: 3rem;
  font-weight: 600;
  margin-bottom: 2.5%;
}
.options-div {
  display: flex;
  flex-direction: column;
  border: 1px solid #d4d4d4;
  border-radius: 9px;
  padding-top: 7.5%;
  width: 100%;
  padding-bottom: 14%;
  background-color: #fff;
}
.options-div button {
  text-align: left;
  width: 100%;
  background-color: transparent;
  padding-left: 18.5%;
  font-size: 2.1rem;
  color: #333333;
  display: flex;
  align-items: center;
  border: none;
  padding-top: 5.5%;
  padding-bottom: 4.8%;
  cursor: pointer;
  /* justify-content: center; */
}
.selected-button {
  background-color: #e9e6e6 !important;
}
.profile-section {
  margin-left: 3.4%;
  /* display: none; */
  width: 60%;
}
.edit-profile {
  background-color: #fff;
  padding-top: 4%;
  padding-bottom: 8%;
  padding-left: 6.3rem;
  padding-right: 8rem;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 9px;
}
.profile-image {
  display: none;
}
.upload-profile-image {
  display: flex;
  flex-direction: row;
  /* justify-content: center; */
  align-items: center;
}
.upload-profile-image-div {
  display: inline-block;
}
.profile-image-div {
  width: 10%;
  margin-right: 4.5%;
  border-radius: 50%;
  position: relative;
  cursor: pointer;
}
/* .edit-icon{
  background-image: url("./../../images/alert.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom center;
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 2rem;
  height: 2rem;
} */
.edit-icon {
  position: absolute;
  padding: auto;
  z-index: 1;
  margin-top: 3%;
  margin-left: 3.5%;
  padding: 0.5%;
  cursor: pointer;
  background-color: black;
  border-radius: 50%;
}
.profile-image-label {
  padding: 1.8% 2.8%;
  background-color: #333333;
  border-radius: 3px;
  color: #f5f5f5;
  font-size: 1.7rem;
  cursor: pointer;
}
.input-div {
  display: flex;
  flex-direction: column;
  margin-top: 5.6%;
}
.input-div label {
  color: #636466;
  font-size: 1.9rem;
  margin-bottom: 1.3rem;
  display: flex;
  align-items: center;
}
.input-div label img {
  margin-left: auto;
  cursor: pointer;
}
.input-div input {
  width: 100%;
  border: 1px solid #707070;
  background-color: #ffffff;
  border-radius: 4px;
  height: 5rem;
  font-size: 1.9rem;
  padding-left: 2%;
}
.save-continue {
  background-color: #fe2b0d;
  border: 1px solid #fe2b0d;
  border-radius: 3px;
  height: 6rem;
  width: 23rem;
  color: #fff;
  font-size: 1.9rem;
  /* margin-top: 3.5%; */
  cursor: pointer;
  padding: 1.8% 2.6%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.active-section {
  display: block !important;
}
.about-section {
  margin-left: 3.4%;

  /* display: none; */
  width: 60%;
}
.services h3 {
  color: #333333;
  font-size: 1.9rem;
  margin-bottom: 2.8rem;
  display: flex;
  align-items: center;
}

.services h3 img {
  margin-left: auto;
  cursor: pointer;
}

.service-item {
  margin-bottom: 3.4rem;
}

.checkbox-container {
  position: relative;
  display: inline-block;
  font-size: 1.6rem;
  display: flex;
  align-items: center;

  /* padding-left: 3.9rem;  */
}

.checkbox-container input {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 27px;
  height: 27px;
  background-repeat: no-repeat;
  background-size: 90%;
  background-position: center;
  border: 1px solid #707070;
  border-radius: 4px;
  outline: none;
  margin-right: 1rem;
}

.checkbox-container input:checked {
  background-image: url("/src/images/tick.svg");
  background-color: #fe2b0d;
  border: none;
}

.about-you {
  margin-top: 8.2rem;
}
.about-you h3 {
  color: #646363;
  font-size: 1.9rem;
  margin-bottom: 1.4rem;
  display: flex;
  align-items: center;
}

.about-you h3 img {
  margin-left: auto;
  cursor: pointer;
}

.about-you textarea {
  border: 0.5px solid #636466;
  background-color: transparent;
  border-radius: 4px;
  padding-left: 2.5rem;
  padding-top: 1.6rem;
  resize: none;
  width: 100%;
  height: 14.5rem;
  line-height: 1.4;
  font-size: 1.8rem;
  color: #333333;
  font-family: "Poppins", sans-serif;
  font-style: italic;
  font-size: 1.8rem;
  font-weight: 300;
  padding-right: 2.5rem;
  padding-bottom: 1.6rem;
  overflow-y: hidden;
}
.about-you textarea::placeholder {
  font-family: "Poppins", sans-serif;
  font-style: italic;
  font-size: 1.8rem;
  font-weight: 300;
  color: #707070;
}
.about-you textarea:hover {
  overflow-y: scroll;
}
.previous {
  background-color: #333333;
  border: 1px solid #333333;
  border-radius: 3px;
  height: 6rem;
  width: 23rem;
  color: #fff;
  font-size: 1.9rem;
  margin-right: 3.7rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.social-section {
  /* display: none; */
  margin-left: 3.4%;

  width: 60%;
}
.freelance-section {
  display: none;
  margin-left: 3.4%;
}
.password-section {
  /* display: none; */
  margin-left: 3.4%;

  width: 60%;
}

.edit-input-container {
  position: relative;
}

.edit-input-container img {
  padding: 0.9rem;
  height: 100%;
  position: absolute;
  right: 0;
  cursor: pointer;
}

.edit-error-popup-content {
  padding: 0.8rem 1.4rem;
  background-color: #fff;
  border: 1px solid #d4d4d4;
  box-shadow: 2px 2px #0a0a0a29;
  margin-top: 0.6rem;
  border-radius: 6px;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.3rem;
  font-weight: 400;
}

.edit-profile-button-container {
  display: flex;
  align-items: center;
  margin-top: 4rem;
}
@media only screen and (max-width: 68.75em) {
  .profile-section {
    width: 68%;
  }
  .edit-profile-main {
    margin-bottom: 15%;
  }
}
@media only screen and (max-width: 56.25em) {
  .options {
    width: 25.5%;
  }
  .profile-section {
    width: 60%;
  }
  .edit-icon {
    margin-top: 4%;
    margin-left: 4%;
  }
  .profile-image-div {
    width: 16%;
  }
}
@media only screen and (max-width: 43.75em) {
  .edit-profile-main {
    flex-direction: column;
  }
  .options {
    margin-bottom: 3%;

    width: 90%;
  }
  .options-div {
    flex-direction: row;
    padding: 0;
    width: 100%;
  }
  .options-div button {
    width: 36%;
    justify-content: center;
    padding: 2%;
  }
  .profile-section {
    width: 90%;
    margin-left: 0;
  }
  .about-section {
    margin-left: 0;
    width: 90%;
  }
  .edit-icon {
    display: none !important;
  }
  .profile-image-label {
    white-space: nowrap;
  }
  .social-section {
    margin-left: 0;
    width: 90%;
  }
  .password-section {
    margin-left: 0;
    width: 90%;
  }
}
@media only screen and (max-width: 26.5em) {
  .edit-profile {
    padding-left: 4.3rem;
    padding-right: 5rem;
  }
  .save-continue {
    height: 7rem;
  }
  .previous {
    height: 7rem;
  }
}
