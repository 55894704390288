/* body {
  background-color: var(--color-white-1);
} */

.section__login,
.section__signup {
  background-color: var(--color-white-1);
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 13rem;
  min-height: 50vh;
}

.login__heading {
  font-size: 2.5rem;
  font-weight: 500;
  color: var(--color-grey-1);
  margin-bottom: 4rem;
}

.login__container {
  width: 30%;
  margin-top: 5rem;
}

.forgotpw__container {
  width: 30%;
  /* margin-top: 7rem; */
}

.forgotpw__container a {
  font-size: 1.8rem;
}

.forgotpw_return {
  color: #fe2b0d;
}

@media only screen and (max-width: 68.75em) {
  .login__container {
    width: 50%;
  }
}

@media only screen and (max-width: 56.25em) {
  .login__container {
    width: 50%;
  }
}

@media only screen and (max-width: 37.5em) {
  .login__container {
    width: 85%;
  }
}
.loading-bars {
  display: flex;
  justify-content: center;
}
.login__form {
  /* background-color: aquamarine; */
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1.9rem;
  margin-bottom: 2rem;
}

.buttons,
.forgot__password,
.login__form input,
.login__form button,
.signup__radio--container {
  width: 100%;
}

.login__group {
  width: 100%;
  margin-bottom: 1rem;
}

.login__group label {
  color: var(--color-grey-1);
  font-family: inherit;
  font-weight: 500;
}

.login__input {
  font-family: inherit;
  color: #a1a1a1;
  padding: 1rem 2rem;
  border-radius: 3px;
  border: 1px solid #a1a1a1;
}

.login__input:focus {
  color: var(--color-grey-1);
  border: 1px solid var(--color-grey-1);
  outline: none;
}

.forgot__password {
  color: var(--color-grey-1);
  font-size: 1.5rem;
  margin-top: 1.5rem;
  margin-bottom: 2.5rem;
}

.forgot__password a {
  color: #fe2b0d;
}

.buttons {
  display: flex;
  flex-direction: column;
}

.buttons::before {
  content: "or";
  text-transform: uppercase;
  font-size: 1.5rem;
  font-weight: 300;
  text-align: center;
  margin: 1.5rem auto;
}

.btn__signins {
  background-color: var(--color-white-1);
  border: 1px solid #d4d4d4;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  font-family: inherit;
  padding: 0.75rem 0;
  cursor: pointer;
  white-space: nowrap;
}

.btn__primary {
  background-color: #fe2b0d;
  border-radius: 4px;
  border: none;
  font-size: inherit;
  font-family: inherit;
  color: var(--color-white-1);
  cursor: pointer;
  padding: 1rem;
  height: 6rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn__signins img {
  height: 4rem;
  width: auto;
  /* margin-right: 1rem; */
  padding: 0;
}

.btn__signins p {
  width: 40%;
}

.signup__radio--container {
  padding-top: 2rem;
}

.signup__radio--heading {
  font-weight: 400;
  font-size: 2.1rem;
  margin-bottom: 0;
}

.signup__radio--label {
  border-radius: 8px;
  border: 1px solid var(--color-grey-1);
  display: flex;
  align-items: center;
  margin-top: 2.2rem;
  position: relative;
  height: 7.2rem;
  isolation: isolate;
  padding-left: 4%;
}

.signup__radio--label input[type="radio"] {
  display: none;
}

.signup__radio--label:has(input[type="radio"]:checked) {
  background-color: #333;
  color: var(--color-white-1);
}

.signup__radio--label:hover {
  background-color: #333;
  color: var(--color-white-1);
  cursor: pointer;
}

.signup__radio--label span img {
  height: 65%;
  width: 65%;
  filter: invert(100%);
  display: none;
}

.signup__radio--label span {
  height: 10px;
  width: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 4rem;
  /* top: 50%;
  bottom: 50%; */
  width: 22px;
  height: 22px;
  border: 2px solid #333;
  border-radius: 50%;
  background-color: #fff;
  margin-right: 1rem;
}

.signup__radio--label > input[type="radio"]:checked + span img {
  display: block;
}

.checked {
  background-color: #333;
  color: var(--color-white-1);
}

.signup__checkbox--container {
  position: relative;
  display: flex;
  align-items: center;
  font-size: 1.4rem;
  /* padding-left: 4.9rem; */
  margin: 4rem 0;
  width: 100%;
}

/* .signup__checkbox--container input[type="checkbox"] {
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
}

.signup__checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 27px;
  width: 27px;
  background-color: transparent;
  border: 1px solid #707070;
  border-radius: 2px;
}

.signup__checkbox--container
  input[type="checkbox"]:checked
  + .signup__checkmark {
  background-color: #fe2b0d;
  border: none;
}

.signup__checkmark:after {
  content: "\2713";
  position: absolute;
  display: none;
  margin-top: -1rem;
  margin-left: 2px;
  font-size: 3rem;
  color: white;
}

.signup__checkbox--container
  input[type="checkbox"]:checked
  + .signup__checkmark:after {
  display: block;
  border: none;
} */

.signup__terms--link {
  color: var(--color-primary);
}

.section__forgotpw {
  background-color: var(--color-white-1);
  display: flex;
  flex-direction: column;
  padding: 15rem 0;
  align-items: center;
}

.forgotpw__heading {
  font-size: 2.5rem;
  font-weight: 500;
}

.forgotpw__explanation {
  font-size: 1.8rem;
  font-weight: 400;
  color: var(--color-grey-1);
  /* width: 100%; */
}

.forgotpw__input {
  padding-top: 6rem;
  padding-bottom: 4rem;
}

.btn__secondary {
  display: block;
  width: 100%;
  text-align: center;
  padding: 2rem;
  margin-top: 3rem;
  color: var(--color-primary);
  text-decoration: none;
}

.login__error {
  color: #fe2b0d;
  font-size: 1.7rem;
  width: 100%;
  text-align: center;
  margin: 2rem auto;
  display: flex;
  align-items: center;
  justify-content: center;
  /* height: 10%; */
}

.login__error img {
  /* height: 2rem; */
  width: 2.5rem;
  /* aspect-ratio: 1; */
  margin-right: 1rem;
}

.login-input-container {
  position: relative;
}

.login-input-container img {
  padding: 0.8rem;
  width: 4.25rem;
  position: absolute;
  right: 0;
  cursor: pointer;
}

.login-error-popup-content {
  padding: 0.8rem 1.4rem;
  background-color: #fff;
  border: 1px solid #d4d4d4;
  box-shadow: 2px 2px #0a0a0a29;
  margin-top: 0.6rem;
  border-radius: 6px;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.3rem;
  font-weight: 400;
}

.type-error {
  width: 10%;
  margin: 0 auto;
  margin-bottom: -1rem;
}

.type-error-popup-content {
  margin: auto;
}

.radio-input-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.agree-error {
  display: block;
  width: 8%;
  margin-top: 1rem;
  margin-bottom: -3rem;
}

.agree-error-popup-content {
  margin-top: 0;
}

.hidden {
  display: none;
}

.agree-checkbox-container {
  position: relative;
  display: flex;
  align-items: center;
  width: 5rem;
}

.agree-checkbox {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 2.2rem;
  aspect-ratio: 1;

  background-repeat: no-repeat;
  background-size: 90%;
  background-position: center;
  border: 1px solid #707070;
  border-radius: 4px;
  outline: none;
  margin-right: 1rem;
  cursor: pointer;
}

.section__login .signup__checkbox--container {
  margin-top: 0;
  margin-bottom: 3rem;
}

.section__login .agree-checkbox-container {
  width: 4rem;
}

.agree-checkbox:checked {
  background-image: url("/src/images/tick.svg");
  background-color: #fe2b0d;
  border: none;
}
@media only screen and (max-width: 37.5em) {
  .btn__signins {
    gap: 4%;
  }
  .agree-checkbox-container {
    width: 10%;
  }
}
@media only screen and (max-width: 21.875em) {
  .agree-checkbox-container {
    width: 16%;
  }
}
@media only screen and (max-width: 43.75em) {
  .agree-checkbox-container {
    width: 12%;
  }
}
@media only screen and (max-width: 56.25em) {
  .agree-checkbox-container {
    width: 12%;
  }
}
