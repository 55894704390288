.footer {
  padding: 8rem 20rem;
  padding-bottom: 4rem;
  color: #fff;
  background-color: black;
  border-top: 1px solid #707070;
}

.footer__links {
  display: flex;
  flex-wrap: wrap;
}
.footer__links_div {
  display: flex;
  /* align-items: center; */
  width: 33%;
  justify-content: center;
  text-align: center;
  margin: 0 auto;
}
.footer__links h3 {
  font-size: 2.2rem;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  margin-bottom: 2rem;
}

.footer__links li,
.footer__links a {
  list-style: none;
  text-decoration: none;
  font-family: "Poppins", sans-serif;
  font-weight: 200;
  font-size: 1.6rem;
  letter-spacing: 0.5px;
  color: #fff;
}

.footer__links a:visited {
  color: #fff;
}

.footer__links-flex {
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
}
.instagram img {
  height: 57%;
}
.footer-social-links {
  margin-top: 8.3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}
.footer-link-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 4.8rem;
  height: 4.8rem;
  background-color: #fe2b0d;
  border-radius: 50%;
  cursor: pointer;
}

.footer-copyright {
  margin-top: 2.4rem;
  font-size: 1.8rem;
  text-align: center;
  font-family: "Poppins";
  font-weight: 400;
  color: #807d7d;
}
.twitter img{
  width: 50%;
}
.hidden-box img {
  width: 75%;
}

@media only screen and (max-width: 68.75em) {
  .footer {
    padding: 8rem 10rem;
  }
}

@media only screen and (max-width: 56.25em) {
  .footer {
    padding: 8rem 6rem;
  }
  .footer__links {
    justify-items: center;
    text-align: center;
  }
  .footer__links_div {
    width: 50%;
  }

  .footer__links-flex {
    margin-bottom: 8rem;
    justify-content: center;
    align-items: center;
  }
  .hidden-box img {
    width: 50%;
  }
}
@media only screen and (max-width: 31.25em) {
  .footer__links {
    text-align: center;
  }
  .footer__links h3 {
    font-size: 2.7rem;
  }
  .footer__links li,
  .footer__links a {
    font-size: 2.2rem;
  }

  .footer__links_div {
    width: 100%;
  }
}
@media only screen and (max-width: 21.875em) {
  /* .arrow-keys {
    flex-direction: column;
  } */
  .footer-link-btn {
    
    width: 5.8rem;
    height: 5.8rem;
    
  }
}
